<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="config maincontainer">
    <div class="row">
      <div class="col-sm-12 breadcrumbSection">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/site/', this.clientID, this.sitename]">
                {{ sitename }}
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "sensorverification" | translate: "SensorVerification" }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div
          class="font-size16 font-weight700 text-uppercase"
          style="padding: 24px 0px 14px 0px"
        >
          {{ "sensorverification" | translate: "SensorVerification" }}
        </div>
        <div
          *ngIf="this.verficationSensorInProcessList.length === 0"
          class="noverficationprocess"
        >
          <span>
            <svg class="svg-icon" style="width: 33px">
              <use
                href="../../../assets/icons-svg/sprite-svg-icons.svg#sensorverification"
                xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#sensorverification"
              ></use>
            </svg>
            {{
              "noverificationSensorProcess" | translate: "SensorVerification"
            }}
          </span>
          <button
            data-toggle="modal"
            data-target="#sensorverificationlistmodal"
            class="btn btn-primary btncust"
          >
            {{ "newverificationProcess" | translate: "SensorVerification" }}
          </button>
        </div>
        <div
          *ngIf="this.verficationSensorInProcessList.length > 0"
          class="verifcation-inprogress"
        >
          <div
            class="flex aic text-uppercase font-14 font-weight700 verification-statusbar"
          >
            {{ summaryList.SiteVerificationStatus }}
          </div>
          <div class="verificaion-processcount">
            <div>
              <span class="font-size16 font-weight700">
                {{ summaryList.Passed }}
              </span>
              of
              <span class="font-size16 font-weight700">
                {{ summaryList.Total }}
              </span>
            </div>
            <div>{{ "completed" | translate: "TaskManagement" }}</div>
          </div>

          <div class="verfication-status-passed">
            <div>{{ summaryList.Passed }}</div>
            <div class="text-capitalize">
              {{ "passed" | translate: "TaskManagement" }}
            </div>
          </div>

          <div class="verfication-status-failed">
            <div>{{ summaryList.Failed }}</div>
            <div class="text-capitalize">
              {{ "failed" | translate: "TaskManagement" }}
            </div>
          </div>

          <div>
            <div class="font-size16 font-weight700">
              {{ summaryList.TotalRemainingInterval }}m
            </div>
            <div class="text-capitalize">
              {{
                "estimatedTimeForCompletion" | translate: "SensorVerification"
              }}
            </div>
          </div>
          <div>
            <span title="{{ 'refresh' | translate: 'Common' }}">
              <svg
                (click)="
                  getSensorVerificationStatus(); getSensorVerificationList()
                "
                class="svg-icon-refresh cp"
              >
                <use
                  href="../../../assets/icons-svg/sprite-svg-icons.svg#refresh"
                  xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#refresh"
                ></use>
              </svg>
            </span>
          </div>
          <button
            data-toggle="modal"
            data-target="#sensorverificationlistmodal"
            class="btn btn-primary btncust"
          >
            {{ "newverificationProcess" | translate: "SensorVerification" }}
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="this.verficationSensorInProcessList.length > 0" class="row">
      <div class="col-md-6" style="padding-top: 24px; padding-bottom: 24px">
        <input
          class="search-box"
          type="text"
          pInputText
          [(ngModel)]="searchText"
          placeholder="{{
            'SearchPlaceholderSensor' | translate: 'SensorVerification'
          }}"
          (keyup)="filterSensorList($event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div
          *ngFor="
            let rowitem of this.filteredList
              | paginateRow: { pageno: this.currentPage, pagesize: pageSize }
          "
          style="padding-bottom: 16px"
        >
          <div class="flex flex-col verification-processlist-main">
            <div class="verification-processlist">
              <div class="flex aic flex-space_between" style="width: 750px">
                <div>
                  <div
                    [ngClass]="{
                      passed: rowitem.VerificationStatus === status.Passed,
                      failed: rowitem.VerificationStatus === status.Failed,
                      inprogress:
                        rowitem.VerificationStatus === status.InProgress
                    }"
                    class="font-14 text-uppercase font-weight700 verfication-processlist-status"
                  >
                    {{ rowitem.VerificationStatus }}
                  </div>
                  <div
                    *ngIf="rowitem.VerificationStatus === status.InProgress"
                    class="font-size18 font-weight500 time-remaining"
                  >
                    {{ rowitem.ProcessRemainingInterval }}m
                    <span style="color: #868a8f; font-size: 12px">
                      Remaining
                    </span>
                  </div>
                </div>
                <div class="verification-processlist-cabinetname">
                  <div class="verification-processlist-cabinetname-div">
                    {{ rowitem.CabinetName }}
                  </div>
                  <div class="verification-processlist-cabinetname-div">
                    Verification ID : {{ rowitem.VerificationSensorID }}
                  </div>
                </div>
              </div>
              <div
                (click)="cancelRowData(rowitem)"
                *ngIf="rowitem.VerificationStatus === status.InProgress"
                data-toggle="modal"
                data-target="#cancelverificationstatus"
              >
                <svg class="svg-icon cp">
                  <use
                    href="../../../assets/icons-svg/sprite-svg-icons.svg#delete"
                    xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#delete"
                  ></use>
                </svg>
              </div>
              <div
                *ngIf="
                  rowitem.VerificationStatus === status.Passed ||
                  rowitem.VerificationStatus === status.Failed
                "
              >
                <svg class="svg-icon">
                  <use
                    href="../../../assets/icons-svg/sprite-svg-icons.svg#kebab_moreoption"
                    xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#kebab_moreoption"
                  ></use>
                </svg>
              </div>
            </div>

            <div
              class="error-msg-failed"
              *ngIf="rowitem.VerificationStatus === status.Failed"
            >
              {{ rowitem.ErrorMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="sensorverificationlistmodal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <div style="padding-bottom: 17px">
            {{ "chooseSensor" | translate: "SensorVerification" }}
          </div>
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="font-size16">
          {{ "pleaseChoose" | translate: "SensorVerification" }}
        </div>
        <div class="flex flex-space_between" style="padding: 16px 0px 0px 0px">
          <div class="form-group" style="width: 300px">
            <label for="{{ 'state' | translate: 'Common' }}">
              {{ "sitesensor" | translate: "SensorVerification" }}
              <span class="mandatory">*</span>
            </label>
            <p-dropdown
              class="dropdown"
              name="sensorList"
              [options]="sensorList"
              [(ngModel)]="selectedSiteSensor"
              optionLabel="CabinetName"
              placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                'sitesensor' | translate: 'SensorVerification'
              }}"
              filter="true"
              (onChange)="onSelectSiteSensor()"
            ></p-dropdown>
          </div>
          <div class="form-group" style="width: 300px">
            <label for="{{ 'state' | translate: 'Common' }}">
              {{ "verificationsensor" | translate: "SensorVerification" }}
              <span class="mandatory">*</span>
            </label>
            <p-dropdown
              class="dropdown"
              name="VerificationSensorID"
              [options]="verficationSensorList"
              [(ngModel)]="selectedVerificationSensor"
              placeholder="{{ 'select' | translate: 'moduleBuilder' }} {{
                'verificationsensor' | translate: 'SensorVerification'
              }}"
              optionLabel="VerificationSensorID"
              filter="true"
              (onChange)="onSelectVerificationSensor()"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #deletecloseBtn
          data-dismiss="modal"
          (click)="resetverificationsensorlist()"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="saveVerification()"
        >
          {{ "startverification" | translate: "SensorVerification" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="cancelverificationstatus"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <div style="padding-bottom: 17px">Cancel</div>
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="font-size16 font-weight500">
          {{ "cancelMessage" | translate: "SensorVerification" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #deletecloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="cancleSensorVerifcation()"
          data-dismiss="modal"
        >
          {{ "cancelSensorVerification" | translate: "SensorVerification" }}
        </button>
      </div>
    </div>
  </div>
</div>

<tc-pagination
  *ngIf="this.verficationSensorInProcessList.length > 0"
  [currentOffset]="currentOffset"
  [size]="pageSize"
  [records]="this.verficationSensorInProcessList.length - 1"
  (pageChange)="onPageChange($event)"
></tc-pagination>

<p-toast position="bottom-left"></p-toast>
