import { Injectable, Inject } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";

import { PennService } from "../penn.service";
import { SessionVariable } from "../class/storageLabel";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  roleName;
  tokenKey = "oidc.accessToken";
  constructor(
    public auth: AuthenticationService,
    public router: Router,
    public pennService: PennService,
    @Inject(LOCAL_STORAGE) private localstorage: StorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let userProfile;
    let tokenValid;
    const token = this.auth.getToken();
    if (token) {
      const tokens: any = JSON.parse(atob(token.split(".")[1]));
      tokenValid = tokens.exp * 1000 > new Date().getTime();
    } else {
      this.router.navigate(["/login"]);
      return false;
    }

    if (tokenValid) {
      //this.pennService.checkActiveStatus();
      userProfile = this.pennService.getStoredObj(SessionVariable.userProfile);
      this.roleName = userProfile.roleName;
      if (this.roleName == "Site As User") {
        this.router.navigate(["/noAccess"]);
        return false;
      } else {
        return true;
      }
    } else {
      this.auth.getNewToken(this.localstorage.get("refresh_token"));
      return true;
    }
  }
}
