import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { MessageService } from "primeng/api";
import { TranslateService } from "../../common/services/translate.service";
import { HttpService } from "../../common/services/http.service";
import { ToastMsg } from "../../common/constants/toastmsg.constant";
import { PennService } from "../../common/penn.service";

import { ClientArea, SiteArea } from "../../common/models/configuration.model";
import { RestApi } from "../../common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Table } from "primeng/table";

@Component({
  selector: "app-areas",
  templateUrl: "./areas.component.html",
  styleUrls: ["./areas.component.scss"],
})
export class AreasComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  @ViewChild("mapaddcloseBtn", { static: true }) mapaddcloseBtn: ElementRef;
  @ViewChild("mapviewcloseBtn", { static: true }) mapviewcloseBtn: ElementRef;

  areaList;
  arealistcols;
  clientArea: ClientArea;
  siteArea: SiteArea;
  AreaName = [];
  modalTitle;
  createdDate;
  addbsUFlag: boolean = false;
  updatebsUFlag: boolean = false;
  defaultselect;
  routeUrl;
  clientID: number;
  clientName: string;
  siteName: string;
  siteID: number;
  areaID: number;
  areaName: string;
  getSiteList;
  allSitesList = [];
  selectedsites;
  mappedSiteList;
  selectedMapSites;
  areaname: string;
  userProfile;
  oldAreaobj: ClientArea;
  regionUsers: [];
  selectLabel = "";
  @ViewChild("areatable", { static: false }) dataTable: Table;
  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.createdDate = this._date.transform(new Date(), "yyyy-MM-dd h:mm:ss");
    this.clientArea = <ClientArea>{};
    this.siteArea = <SiteArea>{};
    this.routeUrl = this.storage.get("routename");

    if (this.routeUrl === "client") {
      this.clientID = Number(this.route.snapshot.paramMap.get("id"));
      this.clientName = this.route.snapshot.paramMap.get("name");
    } else if (this.routeUrl === "site") {
      this.clientID = Number(this.route.snapshot.paramMap.get("clientid"));
      this.siteID = Number(this.route.snapshot.paramMap.get("id"));
      this.siteName = this.route.snapshot.paramMap.get("name");
    }
    this.getAreas();
    this.getAllSites();

    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.pennService.showSubNav();
    this.selectLabel = this.translate.data.moduleBuilder.select;
  }

  getAreas() {
    this.httpService
      .get(RestApi.area_list + "/" + this.clientID)
      .subscribe((res: any) => {
        this.areaList = res.Data;
        this.AreaName = [];

        this.arealistcols = [
          { field: "AreaName", header: this.translate.data.Area.areaName },
        ];
        let indArea: number = -1;
        if (this.areaList) {
          this.areaList.forEach((res, index) => {
            if (res.AreaName != "Default")
              this.AreaName.push({
                label: res.AreaName,
                value: res.AreaName,
              });
            else indArea = index;
          });
          this.areaList.splice(indArea, 1);
        }
      });
  }

  //Get all the regional managers present in the given client and area
  private async getAllRegionUsersAndEnableAlarmSubscription(added: boolean) {
    try {
      const queryParameters = [
        `ClientID=${this.clientID}`,
        `AreaID=${this.areaID}`,
        `RoleID=4`,
      ].join("&");
      const URL = `${RestApi.all_user_by_client_area_roleID}/?${queryParameters}`;
      await this.httpService
        .get(URL)
        .toPromise<any>()
        .then((res: any) => {
          this.regionUsers = res.Data;
        });
      if (this.regionUsers.length > 0) {
        await this.getUserAlarmSubscriptionDetails(
          this.regionUsers.map((s) => s["UserID"]).join(","),
          added
        );
      }
    } catch (err) {
      console.log(err);
    }
  }

  //Get the list of users for whom IsAlarmSubscriptionEnabled is set to true
  private async getUserAlarmSubscriptionDetails(
    userIds: string,
    added: boolean
  ) {
    try {
      await this.httpService
        .get(RestApi.get_users_alarm_subcription + "/?userIds=" + userIds)
        .toPromise<any>()
        .then(async (res: any) => {
          if (res.Data !== null) {
            let usersWithAlarmSubscriptionEnabled = res.Data.filter(
              (sel) => sel.IsAlarmSubscriptionEnabled === true
            );
            if (usersWithAlarmSubscriptionEnabled !== null) {
              usersWithAlarmSubscriptionEnabled.forEach(async (ele) => {
                await this.saveUserAlarmSubscription(ele["UserID"], added);
              });
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  //Set IsAlarmSubscriptionEnabled to either true/fase based on whether sites are assigned or removed
  private async saveUserAlarmSubscription(userId: number, added: boolean) {
    try {
      let siteLists = this.getUpdatedSites(added);
      const data = {
        UserID: userId,
        UserName: this.regionUsers.filter((ele) => {
          return ele["UserID"] === userId;
        })[0]["UserName"],
        IsAlarmSubscriptionEnabled: added,
        SubscribeSites: siteLists,
        CreatedBy: this.userProfile.userName,
        CreatedByID: this.userProfile.userID,
        CreatedOn: new Date().toISOString(),
      };

      if (added) {
        await this.httpService
          .post(RestApi.save_user_alarm_subcription, data)
          .toPromise<any>();
      } else {
        await this.httpService
          .post(RestApi.save_RF_alarm_subcription, data)
          .toPromise<any>();
      }
    } catch (err) {
      console.log(err);
    }
  }

  //Get list of added/remove sites
  private getUpdatedSites(added: boolean) {
    let siteLists = [];
    if (added) {
      this.selectedsites.filter((ele: any) => {
        siteLists.push({ SiteID: ele.SiteID, PremiseID: ele.PremiseID });
      });
    } else {
      let sites = this.getSiteList.filter((all) =>
        this.selectedMapSites.find((sel) => all.SiteID === sel.siteID)
      );
      sites.filter((ele: any) => {
        siteLists.push({ SiteID: ele.SiteID, PremiseID: ele.PremiseID });
      });
    }
    return siteLists;
  }

  showareaModal(val, data) {
    if (val == "Add") {
      this.modalTitle = this.translate.data.Common.add;
      this.areaID = 0;
      this.clientArea = <ClientArea>{};
    } else if (val == "Edit") {
      this.modalTitle = this.translate.data.Common.edit;
      this.clientArea = Object.assign({}, data);
      this.oldAreaobj = Object.assign({}, data);
      this.areaID = data.AreaID;
    } else if (val == "Delete") {
      this.areaID = data.AreaID;
      this.areaName = data.AreaName;
    }
  }

  saveArea() {
    this.clientArea.AreaID = this.areaID;
    this.clientArea.ClientID = this.clientID;
    this.clientArea.ClientName = this.clientName;
    this.clientArea.CreatedOn = this.createdDate;
    this.clientArea.CreatedBy = this.userProfile.userName;
    this.clientArea.CreatedByID = this.userProfile.userID;
    this.clientArea.ModifiedOn = this.createdDate;
    this.clientArea.ModifiedBy = this.userProfile.userName;
    this.clientArea.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.save_area, this.clientArea)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          let msg =
            this.areaID > 0
              ? this.translate.data.Popuop_Msg.regionupdatedsuccessfully
              : this.translate.data.Popuop_Msg.regionaddedsuccessfully;
          this.messageService.add({ severity: "success", summary: msg });
          this.addcloseBtn.nativeElement.click();
          this.getAreas();
        } else {
          this.messageService.add({
            severity: "error",
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteArea() {
    let obj = { AreaID: this.areaID };
    try {
      const queryParameters = [
        `ClientID=${this.clientID}`,
        `AreaID=${this.areaID}`,
        `RoleID=4`,
      ].join("&");
      const URL = `${RestApi.all_user_by_client_area_roleID}/?${queryParameters}`;
      this.httpService.get(URL).subscribe((res: any) => {
        this.regionUsers = res.Data;
        if (this.regionUsers.length == 0) {
          this.getMappedAreaSiteID(this.areaID)
            .then((res) => {
              let areaDetails = {
                areaID: this.areaID,
                clientID: this.clientID,
                siteList: [],
                CreatedOn: this.createdDate,
                CreatedBy: this.userProfile.userName,
                CreatedByID: this.userProfile.userID,
                ModifiedOn: this.createdDate,
                ModifiedBy: this.userProfile.userName,
                ModifiedByID: this.userProfile.userID,
              };

              if (res.IsSuccess && res.Data) {
                areaDetails["siteList"] = res.Data["siteList"];
              } else {
                areaDetails = null;
              }

              this.deleteClientArea(obj, areaDetails)
                .then((res) => {
                  if (res.IsSuccess) {
                    this.messageService.add({
                      severity: "success",
                      summary: this.translate.data.Popuop_Msg
                        .regiondeletedsuccessfully,
                    });
                    this.deletecloseBtn.nativeElement.click();
                    this.getAreas();
                  } else {
                    this.messageService.add({
                      severity: "error",
                      summary: res.ReturnMessage,
                    });
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          this.messageService.add({
            severity: "error",
            summary: this.translate.data.Popuop_Msg.deleteareadependency,
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  getAllSites() {
    this.httpService
      .get(RestApi.client_site_list + "/" + this.clientID)
      .subscribe((res: any) => {
        if (res.Data) {
          this.getSiteList = res.Data.filter((ele) => {
            return ele.AreaID == 0;
          });
        }
      });
  }

  showMapSiteModal(data) {
    this.areaname = data.AreaName;
    this.selectedsites = [];
    let mappedSites;
    this.areaID = data.AreaID;
    this.httpService
      .get(RestApi.site_area_list_area + "/" + this.areaID)
      .subscribe((res: any) => {
        if (res.Data) {
          mappedSites = res.Data.siteList;
          this.allSitesList = this.getSiteList.filter(
            (all) => !mappedSites.find((sel) => all.SiteID === sel.siteID)
          );
        } else {
          this.allSitesList = this.getSiteList;
        }
      });
  }

  viewMappedSiteModal(data) {
    this.selectedMapSites = [];
    this.areaname = data.AreaName;
    this.areaID = data.AreaID;
    this.httpService
      .get(RestApi.site_area_list_area + "/" + this.areaID)
      .subscribe((res: any) => {
        if (res.Data) {
          this.mappedSiteList = res.Data.siteList;
        } else {
          this.mappedSiteList = [];
        }
      });
  }

  mapSelSites() {
    let siteList = [];
    this.selectedsites.filter((ele: any) => {
      siteList.push({ siteID: ele.SiteID, siteName: ele.SiteName });
    });
    this.siteArea.areaID = this.areaID;
    this.siteArea.clientID = this.clientID;
    this.siteArea.siteList = siteList;
    this.siteArea.CreatedOn = this.createdDate;
    this.siteArea.CreatedBy = this.userProfile.userName;
    this.siteArea.CreatedByID = this.userProfile.userID;
    this.siteArea.ModifiedOn = this.createdDate;
    this.siteArea.ModifiedBy = this.userProfile.userName;
    this.siteArea.ModifiedByID = this.userProfile.userID;

    this.mapSelSitesRequest(this.siteArea)
      .then((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: "success",
            summary: this.translate.data.Popuop_Msg.siteaddedsuccessfully,
          });
          this.mapaddcloseBtn.nativeElement.click();
          this.getAllSites();
        } else {
          this.messageService.add({
            severity: "error",
            summary: res.ReturnMessage,
          });
        }
      })
      .catch((_e) => {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: this.translate.data.Popuop_Msg.somethingwentwrong,
        });
      });
  }

  unmapSelSites() {
    this.siteArea.areaID = this.areaID;
    this.siteArea.clientID = this.clientID;
    this.siteArea.siteList = this.selectedMapSites;
    this.siteArea.CreatedOn = this.createdDate;
    this.siteArea.CreatedBy = this.userProfile.userName;
    this.siteArea.CreatedByID = this.userProfile.userID;
    this.siteArea.ModifiedOn = this.createdDate;
    this.siteArea.ModifiedBy = this.userProfile.userName;
    this.siteArea.ModifiedByID = this.userProfile.userID;

    this.unmapSelSitesRequest(this.siteArea)
      .then((res) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: "success",
            summary: this.translate.data.Popuop_Msg.sitedeletedsuccessfully,
          });
          this.mapviewcloseBtn.nativeElement.click();
          this.getAllSites();
        } else {
          this.messageService.add({
            severity: "error",
            summary: res.ReturnMessage,
          });
        }
      })
      .catch((_e) => {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: this.translate.data.Popuop_Msg.somethingwentwrong,
        });
      });
  }

  Validate() {
    if (this.clientArea && this.oldAreaobj) {
      if (this.areaID == 0) return !this.clientArea.AreaName;
      else if (this.areaID > 0)
        return (
          !this.clientArea.AreaName ||
          this.clientArea.AreaName == this.oldAreaobj.AreaName
        );
    }
  }

  private async mapSelSitesRequest(siteArea: any) {
    const res = await this.httpService
      .post(RestApi.map_sites_area, siteArea)
      .toPromise<any>();

    try {
      const resRFPremisesAcess = await this.httpService
        .post(RestApi.give_region_users_rf_premise_access, siteArea)
        .toPromise<any>()
        .then((res) => {
          if (res.IsSuccess) {
            this.getAllRegionUsersAndEnableAlarmSubscription(true);
          }
        });
    } catch (err) {
      // ignore
    }

    return res;
  }

  private async unmapSelSitesRequest(siteArea: any) {
    const res = await this.httpService
      .post(RestApi.unmap_sites_area, siteArea)
      .toPromise<any>();

    try {
      const resRFPremisesAcess = await this.httpService
        .post(RestApi.remove_region_users_rf_premise_access, siteArea)
        .toPromise<any>()
        .then((res) => {
          if (res.IsSuccess) {
            this.getAllRegionUsersAndEnableAlarmSubscription(false);
          }
        });
    } catch (err) {
      // ignore
    }

    return res;
  }

  private async deleteClientArea(obj, areaDetails: any) {
    const res = await this.httpService
      .post(RestApi.delete_area, obj)
      .toPromise<any>();

    try {
      if (areaDetails !== null) {
        const resRFPremisesAcess = await this.httpService
          .post(RestApi.remove_region_users_rf_premise_access, areaDetails)
          .toPromise<any>();
      }
    } catch (err) {
      // ignore
    }

    return res;
  }

  private async getMappedAreaSiteID(areaID: any) {
    const res = this.httpService
      .get(RestApi.site_area_list_area + "/" + this.areaID)
      .toPromise<any>();

    return res;
  }

  ngOnDestroy() {
    this.pennService.hideSubNav();
  }
}

// helpers
