import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ClientComponent } from "./client/client.component";
import { ModuleBuilderComponent } from "./module-builder/module-builder.component";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { SiteComponent } from "./site/site.component";
import { AddClientComponent } from "./add-client/add-client.component";
import { AddSiteComponent } from "./add-site/add-site.component";

import { SuppliersComponent } from "./configuration/suppliers/suppliers.component";
import { ProductsComponent } from "./configuration/products/products.component";
import { AreasComponent } from "./configuration/areas/areas.component";
import { DeliveryTypesComponent } from "./configuration/delivery-types/delivery-types.component";
import { ConfigurationDetailsComponent } from "./configuration/configuration-details/configuration-details.component";
import { LoginComponent } from "./login/login.component";
import { LoginCallbackComponent } from "./login/login-callback/login-callback.component";
import { UserDashboardComponent } from "./user-dashboard/user-dashboard.component";
import { AuthGuardService as AuthGuard } from "./common/services/auth-guard.service";

import { ModuleLogListComponent } from "./module-log/module-log-list/module-log-list.component";
import { ModuleLogDetailsComponent } from "./module-log/module-log-details/module-log-details.component";
import { NoAccessPageComponent } from "./no-access-page/no-access-page.component";
import { RefrigerationDataComponent } from "./refrigeration/refrigeration-data/refrigeration-data.component";
import { RefrigerationListComponent } from "./refrigeration/refrigeration-list/refrigeration-list.component";

import { ReasonsComponent } from "./configuration/reasons/reasons.component";
import { CorrectiveActionsComponent } from "./configuration/corrective-actions/corrective-actions.component";
import { ManagerSignoffComponent } from "./manager-signoff/manager-signoff.component";
import { ReminderComponent } from "./configuration/reminder/reminder.component";
import { ChangeGuard } from "./common/Guard/pageChangeGuard";
import { NotificationComponent } from "./notifications/notification/notification.component";
import { ManagerSignOfflistComponent } from "./manager-sign-offlist/manager-sign-offlist.component";
import { TemplateComponent } from "./template/template.component";
import { TemplateModuleBuilderComponent } from "./template-module-builder/template-module-builder.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ReportsComponent } from "./reports/reports.component";
import { UserManagementComponent } from "./user-management/user-management.component";
import { UserComponent } from "./user/user.component";
import { DownloadReportComponent } from "./downloadReport/download-report.component";
import { TaskmgmtComponent } from "./task-management/taskmgmt/taskmgmt.component";
import { AllReportsComponent } from "./reports/temperature-reports/all-reports.component";
import { DocumentManagementComponent } from "./document-management/document-management/document-management.component";
import { DocumentDownloadComponent } from "./document-management/document-download/document-download.component";
import { CommManagementComponent } from "./communication-management/comm-management/comm-management.component";
import { ReportsMainComponent } from "./reports/reports-main/reports-main.component";
import { AuthProcessComponent } from "./common/auth-process/auth-process.component";
import { SensorTemplateComponent } from "./configuration/sensor-template/sensor-template.component";
import { CommissioningComponent } from "./commissioning/commissioning.component";
import { EnterSerialNumberComponent } from "./enter-serial-number/enter-serial-number.component";
import { CloudConnectorComponent } from "./cloud-connector/cloud-connector.component";
import { CommissioningMainComponent } from "./commissioning-main/commissioning-main.component";
import { AddSensorComponent } from "./add-sensor/add-sensor.component";
import { DeleteGatewayComponent } from "./delete-gateway/delete-gateway.component";
import { DeleteSensorComponent } from "./delete-sensor/delete-sensor.component";
import { MobileUiCO2ChartWithDateComponent } from "./common/components/charts/CO2Sensor/mobileui-co2humidity/mobileui-co2-withdate-humidity-chart.component";
import { SensorVerificationComponent } from "./sensorverification/sensorverification.component";

export const SiteRoutes: Routes = [
  {
    path: "TaskManagement/:clientid/:name",
    component: TaskmgmtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Documents",
    component: DocumentManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Communication",
    component: CommManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Communication/:clientId",
    component: CommManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Documents/:folderId",
    component: DocumentManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "TaskManagement/:siteId",
    component: TaskmgmtComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "Documents",
    component: DocumentManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Communication",
    component: CommManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Communication/:clientId",
    component: CommManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Documents/:folderId",
    component: DocumentManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user",
    component: UserComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: UserDashboardComponent,
      },
      {
        path: "userManagement",
        component: UserManagementComponent,
      },
    ],
  },
  { path: "client", component: ClientComponent, canActivate: [AuthGuard] },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "addClient/:id",
    component: AddClientComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "addClient/:id",
    component: AddClientComponent,
    canActivate: [AuthGuard],
    pathMatch: "full",
  },
  {
    path: "site/:clientid/:clientname",
    component: SiteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "site/:clientid/:clientname/:siteId/moduleLogDetails/:id",
    component: ModuleLogDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path:
      "site/:siteId/refrigeration/:tab/:sitename/:premiseid/:clientid/:statename/:city/:timestamp",
    component: RefrigerationListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "addSite/:clientid/:siteId/:name",
    component: AddSiteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "addSite",
    component: AddSiteComponent,
    canActivate: [AuthGuard],
    pathMatch: "full",
  },
  {
    path: "sensorverification/:clientid/:siteid/:sitename",
    component: SensorVerificationComponent,
    canActivate: [AuthGuard],
    pathMatch: "full",
  },
  {
    path: "moduleBuilder/:clientid/:name/:id",
    component: ModuleBuilderComponent,
    canActivate: [AuthGuard],
    canDeactivate: [ChangeGuard],
  },
  {
    path: "configuration",
    component: ConfigurationComponent,
    data: { page: "client" },
    children: [
      {
        path: "details/:id/:name",
        component: ConfigurationDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "details/:clientid/:id/:name",
        component: ConfigurationDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "suppliers/:id/:name",
        component: SuppliersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "suppliers/:clientid/:id/:name",
        component: SuppliersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "products/:id/:name",
        component: ProductsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "products/:clientid/:id/:name",
        component: ProductsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "areas/:id/:name",
        component: AreasComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "areas/:clientid/:id/:name",
        component: AreasComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "delivery-types/:id/:name",
        component: DeliveryTypesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "delivery-types/:clientid/:id/:name",
        component: DeliveryTypesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reason/:id/:name",
        component: ReasonsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "corrective-actions/:id/:name",
        component: CorrectiveActionsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reminder/:id/:name",
        component: ReminderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "sensorTemplate/:clientid/:clientname",
        component: SensorTemplateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "sensorTemplate/:clientid/:clientname/:sensortemplateid",
        component: SensorTemplateComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "moduleLogList/:id/:name",
    component: ModuleLogListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "moduleLogList/:clientid/:id/:name",
    component: ModuleLogListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "refrigeration/:clientid/:siteId/:name",
    component: RefrigerationListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports",
    component: ReportsMainComponent,
    children: [
      {
        path: "",
        component: ReportsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reportExport",
        component: AllReportsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "refrigerationDetails/:id",
    component: RefrigerationDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "managerSignOff",
    component: ManagerSignOfflistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "list",
    component: ManagerSignoffComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "notifications/:id",
    component: NotificationComponent,
    canActivate: [AuthGuard],
  },
  { path: "template", component: TemplateComponent, canActivate: [AuthGuard] },
  {
    path: "templatemodulebuilder/:id",
    component: TemplateModuleBuilderComponent,
    canActivate: [AuthGuard],
  },
  { path: "login", component: LoginComponent },
  { path: "downloadFile/:fName", component: DownloadReportComponent },
  {
    path: "downloadDocument/:docId/:notificationId",
    component: DocumentDownloadComponent,
  },
  {
    path: "refrigerationDetails/:id",
    component: RefrigerationDataComponent,
  },
  {
    path: "commonchart/:siteID/:unitID",
    component: MobileUiCO2ChartWithDateComponent,
  },
  { path: "login/callback", component: LoginCallbackComponent },
  { path: "noAccess", component: NoAccessPageComponent },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "auth-process", component: AuthProcessComponent },
  {
    path: "commissioning",
    component: CommissioningMainComponent,
    children: [
      {
        path: "listsensorgateway",
        component: CommissioningComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "listsensorgateway/:deviceType",
        component: CommissioningComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "addserialNo",
        component: EnterSerialNumberComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "addserialNo/:deviceType",
        component: EnterSerialNumberComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "saveGateway",
        component: CloudConnectorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "saveGateway/:deviceType",
        component: CloudConnectorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "saveSensor",
        component: AddSensorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "saveSensor/:deviceType",
        component: AddSensorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deleteGateway/:deviceType",
        component: DeleteGatewayComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deleteSensor/:deviceType",
        component: DeleteSensorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deleteGateway",
        component: DeleteGatewayComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deleteSensor",
        component: DeleteSensorComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(SiteRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
