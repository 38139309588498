import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { HttpService } from "src/app/common/services/http.service";
import * as Highcharts from "highcharts";
import { RestApi } from "src/app/common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { WeekdatesService } from "src/app/task-management/services/weekdates.service";
import * as FileSaver from "file-saver";
import { HttpClient } from "@angular/common/http";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { TranslateService } from "src/app/common/services/translate.service";

interface Regions {
  name: string;
  code: string;
}

@Component({
  selector: "app-overduemodules",
  templateUrl: "./overduemodules.component.html",
  styleUrls: ["./overduemodules.component.scss"],
})
export class OverduemodulesComponent implements OnInit {
  @Input() reload: boolean;
  currentDate: any = new Date();
  form = {
    startDate: "",
    endDate: "",
  };
  @ViewChild("closeModal") closebutton;
  Modurl: any;
  allTasks: any;
  regions: any = [];
  selectedRegion: Regions;
  sites: any = [];
  selectedSite: Regions;
  updateChart: boolean;
  chartData: number[] = [];
  moduleData: string[] = [];
  chart: any;
  @ViewChild("closeModal") closeModal;
  maxFromDate: Date;
  maxToDate: Date;
  selFromDate;
  postId: any;
  errorMessage: any;
  regionData: any;
  siteData: any;
  TopOverdue: any = [];
  minToDate: Date;
  selToDate;
  clientID: number;
  userProfile: any;
  startDate: any;
  endDate: any;
  weekDates: any[];
  reportName = "test.xls";
  roleID: any;
  areaID: any;
  dowloadURL: string = "";
  overdueModuleLength: any;
  sytemDate: Date;
  minEndDate: Date;
  loadData: any = [];
  TopOverdueValue: any;
  TopOverdueAll: any = [];
  noModuledata: boolean = false;
  openMenuFlag = false;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleID = this.userProfile.roleID;
    this.areaID = this.userProfile.areaID;
    this.clientID = this.pennservice.getStoredData("clientID");
    this.weekDates = this.WeekDatesService.getDatesToSend();
    this.loadModulesData();
    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.sytemDate = new Date();
    this.minEndDate = new Date(
      this.sytemDate.setMonth(this.sytemDate.getMonth() - 6)
    );
  }
  constructor(
    public apiService: HttpService,
    private _date: DatePipe,
    private pennservice: PennService,
    public WeekDatesService: WeekdatesService,
    public http: HttpClient,
    private translate: TranslateService
  ) {}
  loadModulesData() {
    if (
      this.roleID == Roles.SuperAdmin ||
      this.roleID == Roles.ClientAdmin ||
      this.roleID == Roles.AdminReseller
    ) {
      this.Modurl =
        RestApi.TaskManagementURL +
        "task-management/client-view/" +
        this.clientID +
        "/overdue-module/" +
        this._date.transform(this.weekDates[0], "yyyy-MM-dd") +
        "/" +
        this._date.transform(new Date(), "yyyy-MM-dd");
    } else if (this.roleID == Roles.AreaManager) {
      this.Modurl =
        RestApi.TaskManagementURL +
        "task-management/client-view/" +
        this.clientID +
        "/overdue-module/" +
        this._date.transform(this.weekDates[0], "yyyy-MM-dd") +
        "/" +
        this._date.transform(new Date(), "yyyy-MM-dd") +
        "?regionId=" +
        this.areaID;
    }
    this.apiService.get(this.Modurl).subscribe(
      (data: any) => {
        this.noModuledata = false;
        this.TopOverdue = [];
        this.TopOverdueAll = [];
        this.TopOverdueValue = data.overdueModule.sort(
          ({ overDueTasks: a }, { overDueTasks: b }) => b - a
        );
        this.TopOverdueValue.map((x) => {
          let itemLoad = {
            moduleID: x.moduleID,
            moduleName: x.moduleName,
            overDueTasks: x.overDueTasks,
            regionID: x.regionID,
            regionName: x.regionName,
            siteID: x.siteID,
            siteName: x.siteName,
          };
          if (x.regionID == 0 && x.siteID == 0) {
            this.TopOverdue.push(itemLoad);
          } else {
            this.TopOverdueAll.push(itemLoad);
          }
        });
        this.overdueModuleLength = data.overdueModule.length;
        if (data.overdueModule.length == 0) {
          this.noModuledata = true;
        } else {
          this.chartData = this.TopOverdue.map((x) => Number(x.overDueTasks));
          this.moduleData = this.TopOverdue.map((x) => x.moduleName);
          this.TopOverdueAll.map((x) => {
            let item = { name: x.regionName, code: x.regionID };
            if (
              !this.regions.find(
                (i) => i.name == item.name && i.code == item.code
              )
            )
              this.regions.push(item);
          });
          this.CreateColumnChart();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onFromSelect(date) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
  }
  onToSelect(date) {
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
  }
  UpdateChartData() {
    this.chart.series[0].setData(this.chartData);
    this.chart.xAxis[0].setCategories(this.moduleData);
    this.updateChart = true;
  }
  getChartData() {
    if (this.selectedRegion && this.selectedSite) {
      this.chartData = this.TopOverdueAll.filter(
        (i) =>
          i.regionID == this.selectedRegion.code &&
          i.siteID == this.selectedSite.code
      ).map((x) => Number(x.overDueTasks));
      this.moduleData = this.TopOverdueAll.filter(
        (i) =>
          i.regionID == this.selectedRegion.code &&
          i.siteID == this.selectedSite.code
      ).map((x) => x.moduleName);
    } else if (this.selectedRegion && this.selectedSite == null) {
      this.chartData = this.TopOverdueAll.filter(
        (i) => i.regionID == this.selectedRegion.code
      ).map((x) => Number(x.overDueTasks));
      this.moduleData = this.TopOverdueAll.filter(
        (i) => i.regionID == this.selectedRegion.code
      ).map((x) => x.moduleName);
    } else if (this.selectedSite && this.selectedRegion == null) {
      this.chartData = this.TopOverdueAll.filter(
        (i) => i.siteID == this.selectedSite.code
      ).map((x) => Number(x.overDueTasks));
      this.moduleData = this.TopOverdueAll.filter(
        (i) => i.siteID == this.selectedSite.code
      ).map((x) => x.moduleName);
    } else {
      this.chartData = this.TopOverdueAll.map((x) => Number(x.overDueTasks));
      this.moduleData = this.TopOverdueAll.map((x) => x.moduleName);
    }
    this.UpdateChartData();
  }
  onRegionChange(regionVal) {
    this.sites = [];
    if (this.selectedRegion) {
      this.siteData = this.TopOverdueAll.filter(
        (x) => x.regionID == this.selectedRegion.code
      );
      this.siteData.map((x) => {
        let item = { name: x.siteName, code: x.siteID };
        if (!this.sites.find((i) => i.name == item.name && i.code == item.code))
          this.sites.push(item);
        this.selectedSite = this.sites[0];
      });
    }
    this.getChartData();
  }
  CreateColumnChart() {
    let myOptions: any = {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        scrollbar: {
          enabled: false,
        },
        categories: this.moduleData,
      },
      yAxis: {
        title: {
          text: "",
        },
        allowDecimals: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      series: [
        {
          enableMouseTracking: false,
          name: this.translate.data.TaskManagement.overdueTasks,
          color: "#0081B6",
          type: "column",
          data: this.chartData,
        },
      ],
    };
    this.chart = Highcharts.chart("overduemodules", myOptions);
  }
  exportToExcel() {
    if (this.roleID == Roles.SuperAdmin || this.roleID == Roles.ClientAdmin) {
      this.dowloadURL =
        RestApi.TaskManagementURL +
        "client-excel/export/overdue-module?clientId=" +
        this.clientID +
        "&startDate=" +
        this._date.transform(this.selFromDate, "yyyy-MM-dd") +
        "&endDate=" +
        this._date.transform(this.selToDate, "yyyy-MM-dd");
    } else if (this.roleID == Roles.AreaManager) {
      this.dowloadURL =
        RestApi.TaskManagementURL +
        "client-excel/export/overdue-module?clientId=" +
        this.clientID +
        "&regionId=" +
        this.areaID +
        "&startDate=" +
        this._date.transform(this.selFromDate, "yyyy-MM-dd") +
        "&endDate=" +
        this._date.transform(this.selToDate, "yyyy-MM-dd");
    }
    this.http
      .get<Blob>(this.dowloadURL, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe(
        (data) => {
          var fileName = "TopOverDueTasksByModules.xlsx";
          FileSaver.saveAs(data.body, fileName);
          this.closeModal.nativeElement.click();
        },
        (err) => {
          console.error(err);
        }
      );
    this.selFromDate = "";
    this.selToDate = "";
  }
  clearInput() {
    this.selFromDate = "";
    this.selToDate = "";
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Overdue Task By Module": { modulename: "taskManagementModule" },
    });
  }

  resetValues() {
    this.openMenuFlag = false;
  }
}
