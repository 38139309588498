import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MessageService } from "primeng/api";
import { PennService } from "../common/penn.service";
import { UserService } from "../common/services/user-service/user.service";

import { HttpService } from "../common/services/http.service";
import { TranslateService } from "../common/services/translate.service";
import { RestApi } from "../common/constants/RestAPI";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { UserData } from "../common/models/user.model";
import { Constant, common, userlistColumn } from "../common/constants/constant";
import {
  PrimeDropdown,
  ApiListResponse,
  ProcessedRowError,
} from "../common/models/configuration.model";
import { Roles } from "../common/constants/enums/instructionEnums";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "../common/class/storageLabel";
import { ExcelService } from "../common/services/excel.service";
import { forkJoin, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-user-dashboard",
  templateUrl: "./user-dashboard.component.html",
  styleUrls: ["./user-dashboard.component.scss"],
})
export class UserDashboardComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("adduserrolecloseBtn", { static: true })
  adduserrolecloseBtn: ElementRef;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  @ViewChild("resetCloseBtn", { static: true }) resetCloseBtn: ElementRef;
  @ViewChild("selectusertemplatecloseBtn", { static: true })
  selectusertemplatecloseBtn: ElementRef;
  @ViewChild("inputFileUpload", { static: false }) inputFileUpload: ElementRef;
  @ViewChild("userTemplateModal", { static: false })
  userTemplateModal: ElementRef;
  Title: string;
  modalTitle: String;
  createdDate: String;
  userID: number;
  username: String;
  userEmailId: String;
  userPreferredLanguage: string;
  addUser: UserData;
  roleList = [];
  selectedRole = {
    id: 0,
    name: this.translate.data.moduleInstructionType.selectrole,
  };
  userList;
  userlistcols;
  userName: PrimeDropdown<string, string>[] = [];
  Email: PrimeDropdown<string, string>[] = [];
  userSelect;
  emailSelect;
  clientList = [];
  selectedClient;
  selectedClients = [];
  areaList = [];
  selectedArea;
  siteList = [];
  companyID = null;
  selectedSite;
  roleName: string;
  fieldFlags: boolean = false;
  userProfile;
  getClientAPIURL;
  oid;
  emailFlag: boolean = false;
  editUserName: boolean = false;

  oldRoleId?: number;
  oldSiteList?: any[];
  oldAreaID?: number;
  oldSiteID?: number;

  isAlarmSubcriptionVisible: boolean = false;
  isAlarmSubEnable: boolean = false;
  isAlarmSubEnableOld: boolean = false;

  changePassword: boolean = false;
  confirmPassword: string;
  clientMapping: any[] = [];
  clientSites: any[] = [];
  isSecurePasswordCreationEnable: boolean = true;
  wrongPassword: boolean = false;
  selectLabel = "";
  canImportUsers = false;
  importErrors: ProcessedRowError[] = [];
  showImportErrors: boolean = false;
  showErrorValue: boolean = false;
  allRolesList = [];
  existingUserName = "";
  emailTemplateBase64String = "";
  emailID = "";
  tenantId = "";
  roleID: number;
  exportData;
  excelHeader;
  showDownloadUserListBtn = false;

  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    private _date: DatePipe,
    private router: Router,
    private userService: UserService,
    public excelService: ExcelService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private activeRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.Title = this.translate.data.User.user;
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.addUser = <UserData>{};
    this.storage.remove("routename");

    let rolesArr = [
      { id: 1, name: "Super Admin" },
      { id: 2, name: "Admin Reseller" },
      { id: 3, name: "Client Admin" },
      { id: 4, name: "Regional Manager" },
      { id: 5, name: "Site Manager" },
      { id: 6, name: "Site As User" },
    ];

    let importRolesarr = [
      { id: 4, name: "Regional Manager" },
      { id: 5, name: "Site Manager" },
      { id: 6, name: "Site As User" },
    ];

    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.getClientListBasedOnRoles(rolesArr, importRolesarr);
    this.getUsers();
    this.pennService.hideNotificationPanel();
    this.selectLabel = this.translate.data.moduleBuilder.select;
    this.GetEmailTemplate("en-EN");
  }

  importExcel() {
    document.getElementById("inputFileUpload").click();
  }

  private GetEmailTemplate(language) {
    this.httpClient
      .get(`../assets/email-template/${language}_reset_password.html`, {
        responseType: "text",
      })
      .subscribe(
        (res: any) => {
          this.emailTemplateBase64String = btoa(res);
        },
        (err) => {}
      );
  }

  downloadTemplate() {
    switch (this.selectedRole.id) {
      case 4:
        this.pennService.downloadExcelTemplate(
          "Regional-Manager-User-import-template"
        );
        this.selectusertemplatecloseBtn.nativeElement.click();
        return;
      case 5:
        this.pennService.downloadExcelTemplate(
          "Site-Manager-User-import-template"
        );
        this.selectusertemplatecloseBtn.nativeElement.click();
        return;
      case 6:
        this.pennService.downloadExcelTemplate("Site-as-User-import-template");
        this.selectusertemplatecloseBtn.nativeElement.click();
        return;
      default:
        return "";
    }
  }
  async onImportFileChange(items: Blob[]) {
    let fileExtension = items[0]["name"].slice(
      items[0]["name"].lastIndexOf(".") + 1
    );
    let companyId = null;
    let clientId = 0;
    if (fileExtension !== "xlsx") {
      this.messageService.add({
        severity: ToastMsg.severity.error,
        summary: this.translate.data.Error_Msg.invalidFileType,
      });
      return;
    }
    let selClient = this.clientList.find((ele) => {
      return ele.ClientID == this.userProfile.clientID;
    });
    if (
      (this.userProfile && this.userProfile.roleID === 1) ||
      this.userProfile.roleID === 2
    ) {
      companyId = this.selectedClient.CompanyID;
      clientId = this.selectedClient.ClientID;
    } else {
      companyId = selClient["CompanyID"];
    }
    const clientCompanyId = {
      CompanyID: companyId,
      UserRole: this.selectedRole.name,
      UserClientId: clientId,
      UserRoleId: this.selectedRole.id,
    };
    this.adduserrolecloseBtn.nativeElement.click();
    return this.excelService
      .sendExcelFile(
        items[0],
        RestApi.import_user,
        this.translate.data.Popuop_Msg.usersimportedsuccessfully,
        clientCompanyId
      )
      .then(() => {
        this.getUsers();
      })
      .catch((result: ApiListResponse<ProcessedRowError> | Error) => {
        if (!(result instanceof Error)) {
          this.importErrors = result.Data;
          this.showErrorValue = this.importErrors[0].value !== null;
          this.showImportErrors = true;
        }
      })
      .finally(() => {
        this.adduserrolecloseBtn.nativeElement.click();
        this.inputFileUpload.nativeElement.value = null;
      });
  }

  checkClientSecurePasswordDetails(clientId: string | number) {
    this.httpService
      .get<any>(RestApi.client_preference + "/" + clientId)
      .subscribe((res: any) => {
        this.isSecurePasswordCreationEnable =
          res.Data.EnableSecurePasswordCreation === true ? true : false;
      });
  }

  getClientListBasedOnRoles(rolesArr, importRolesarr) {
    if (
      this.userProfile.roleName == Constant.Roles.adminReseller ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer
    ) {
      this.canImportUsers =
        this.userProfile.roleName == Constant.Roles.adminReseller
          ? true
          : false;
      this.getClientAPIURL =
        RestApi.get_client_mapping_by_userid + "/" + this.userProfile.userID;
      this.getAllClientList(0);
      this.addFieldEnggRole(rolesArr);
    } else if (this.userProfile.roleName == Constant.Roles.superAdmin) {
      this.canImportUsers = true;
      this.getClientAPIURL = RestApi.client_list;
      this.getAllClientList(0);
      this.addFieldEnggRole(rolesArr);
    } else if (this.userProfile.roleName == Constant.Roles.clientAdmin) {
      this.canImportUsers = true;
      this.showDownloadUserListBtn = true;
      this.getClientAPIURL =
        RestApi.client_details + "/" + this.userProfile.clientID;
      this.getAllClientList(this.userProfile.clientID);
      this.checkClientSecurePasswordDetails(this.userProfile.clientID);
    }

    this.allRolesList = importRolesarr.sort((first: any, next: any) =>
      first.name.toLowerCase() > next.name.toLowerCase() ? 1 : -1
    );
    this.roleList = rolesArr.filter((element) => {
      if (this.userProfile.roleID == Roles.SuperAdmin) {
        return true;
      } else if (this.userProfile.roleID == Roles.ClientAdmin) {
        return element.id >= this.userProfile.roleID;
      } else {
        return element.id > this.userProfile.roleID;
      }
    });
    this.roleList.splice(0, 0, {
      id: 0,
      name: this.translate.data.moduleInstructionType.selectrole,
    });
  }

  getAllClientList(param) {
    this.clientList = [];
    this.httpService.get(this.getClientAPIURL).subscribe((res: any) => {
      if (res.Data) {
        if (this.userProfile.roleName === Constant.Roles.clientAdmin) {
          this.clientList.push(res.Data);
        } else {
          this.clientList = this.sortedList(res.Data, "ClientName");
          this.selectedClient = res.Data.sort((first: any, next: any) =>
            first.UserName > next.UserName ? 1 : -1
          );
        }
        if (param && this.userProfile.roleID < 3) {
          let selClient = this.clientList.filter((ele) => {
            return ele.ClientID == param;
          });
          this.selectedClient = selClient[0];
        }
      } else {
        this.clientList = [];
      }
    });
  }

  addFieldEnggRole(role) {
    role.push({ id: 7, name: "Field Engineer" });
  }

  getValidationFlag(rolename: string): void {
    if (
      rolename == Constant.Roles.clientAdmin ||
      rolename == Constant.Roles.fieldEngineer
    ) {
      this.fieldFlags = this.selectedClient ? false : true;
    } else if (rolename == Constant.Roles.adminReseller) {
      this.fieldFlags = this.selectedClients.length !== 0 ? false : true;
    } else if (rolename == Constant.Roles.areaManager) {
      this.fieldFlags = this.selectedClient && this.selectedArea ? false : true;
    } else if (
      rolename == Constant.Roles.siteManager ||
      rolename == Constant.Roles.siteUser
    ) {
      this.fieldFlags =
        this.selectedClient && this.selectedArea && this.selectedSite
          ? false
          : true;
    } else {
      this.fieldFlags = false;
    }
  }

  getAreaListByClientID(val: string, clientId: number, areaID: number) {
    this.areaList = [];
    this.httpService
      .get(RestApi.area_list + "/" + clientId)
      .subscribe((res: any) => {
        if (res.Data) {
          this.areaList = this.sortedList(res.Data, "AreaName");
          if (val === Constant.CommandType.edit) {
            let selArea = this.areaList.filter((ele) => {
              return ele.AreaID === areaID;
            });
            setTimeout(() => {
              this.selectedArea = selArea[0];
            }, 0);
          }
        } else {
          this.areaList = [];
        }
      });
  }

  sortedList(response, key: string) {
    return [...response].sort((a, b) =>
      a[`${key}`].split(" ").join("").toLowerCase() >
      b[`${key}`].split(" ").join("").toLowerCase()
        ? 1
        : -1
    );
  }

  getSiteListByAreaID(val, siteid, id) {
    this.siteList = [];
    this.httpService
      .get(RestApi.site_area_list_area + "/" + id)
      .subscribe((res: any) => {
        if (res.Data) {
          this.siteList = res.Data.siteList;

          if (this.oldSiteList === undefined) {
            this.oldSiteList = [...res.Data.siteList];
          }

          if (val == Constant.CommandType.edit) {
            let selSite = this.siteList.filter((ele) => {
              return ele.siteID == siteid;
            });
            // selected value not updating in primeng
            // dropdown so using timeout to delay
            setTimeout(() => {
              this.selectedSite = selSite[0];
            }, 0);
          }
        } else {
          this.siteList = [];
          this.oldSiteList = [];
        }
      });
  }

  onSelectRole() {
    this.roleName = this.selectedRole.name;
    this.selectedClients = [];
    this.selectedClient = "";
    this.selectedArea = "";
    this.selectedSite = "";
    this.getValidationFlag(this.roleName);
  }

  onSelectClient() {
    if (this.selectedClient) {
      this.getAreaListByClientID("", this.selectedClient.ClientID, 0);
      this.companyID = this.selectedClient.CompanyID;
      this.getValidationFlag(this.roleName);
      this.getClientSites(this.selectedClient.ClientID);
    }
  }

  onSelectClients() {
    this.getValidationFlag(this.roleName);
  }

  onSelectArea() {
    if (this.selectedArea) {
      this.getSiteListByAreaID("", "", this.selectedArea.AreaID);
      this.getValidationFlag(this.roleName);
    }
  }

  onSelectSite() {
    if (this.selectedSite) {
      this.getValidationFlag(this.roleName);
    }
  }

  private removeClientColumn(): any[] {
    let column = [
      {
        field: userlistColumn.UserName,
        header: this.translate.data.Common.username,
      },
      {
        field: userlistColumn.EmailID,
        header: this.translate.data.Common.email,
      },
      {
        field: userlistColumn.PhoneNo,
        header: this.translate.data.Common.phoneNumber,
      },
      {
        field: userlistColumn.RoleName,
        header: this.translate.data.Common.role,
      },
      {
        field: userlistColumn.FullName,
        header: this.translate.data.Common.FullName,
      },
    ];
    let clientColumn = [
      {
        field: userlistColumn.UserName,
        header: this.translate.data.Common.username,
      },
      {
        field: userlistColumn.EmailID,
        header: this.translate.data.Common.email,
      },
      {
        field: userlistColumn.PhoneNo,
        header: this.translate.data.Common.phoneNumber,
      },
      {
        field: userlistColumn.RoleName,
        header: this.translate.data.Common.role,
      },
      {
        field: userlistColumn.FullName,
        header: this.translate.data.Common.FullName,
      },
      {
        field: userlistColumn.ClientName,
        header: this.translate.data.Common.ClientName,
      },
    ];
    if (this.userProfile.roleID == Roles.ClientAdmin) {
      return column;
    } else {
      return clientColumn;
    }
  }
  getUsers() {
    this.httpService
      .get(RestApi.all_user_list)
      .subscribe((res: ApiListResponse<UserData>) => {
        this.userList = [];
        this.userName = [];
        this.Email = [];
        this.userlistcols = this.removeClientColumn();
        if (res.IsSuccess) {
          this.userList = res.Data?.filter(
            (element: UserData, index: number) => {
              if (this.userProfile.roleID == Roles.SuperAdmin) {
                return true;
              } else if (this.userProfile.roleID == Roles.ClientAdmin) {
                return element.RoleID >= this.userProfile.roleID;
              } else return element.RoleID > this.userProfile.roleID;
            }
          );
          if (
            this.userList &&
            this.userList.length > 0 &&
            this.userProfile.roleID === Roles.AdminReseller
          ) {
            let fieldEngineers = this.userList?.filter((u) => u.RoleID === 7);
            this.requestDataFromMultipleSources(fieldEngineers).subscribe(
              (item: any) => {
                let clientUserMappingValidData = [];
                let clientUserMapping = item?.filter(
                  (t) => t.ReturnMessage != "No Data"
                );
                for (let i = 0; i < clientUserMapping?.length; i++) {
                  for (let j = 0; j < clientUserMapping[i].Data?.length; j++) {
                    clientUserMappingValidData.push(
                      clientUserMapping[i].Data[j]
                    );
                  }
                }
                if (clientUserMappingValidData.length > 0) {
                  let usersWithClientAccess = clientUserMappingValidData?.filter(
                    (el) => {
                      return this.clientList?.find((element) => {
                        return element.ClientID === el.ClientID;
                      });
                    }
                  );
                  let matchedUsers = res.Data?.filter((el) => {
                    return usersWithClientAccess?.find((element) => {
                      return element.UserID === el.UserID;
                    });
                  });
                  if (matchedUsers.length > 0) {
                    this.userList = this.userList?.concat(matchedUsers);
                  }
                  this.sortUserList();
                }
              }
            );
            this.userList = this.userList?.filter((el) => {
              return this.clientList?.find((element) => {
                return element.ClientID === el.ClientID;
              });
            });
          }
          this.sortUserList();
        }
        this.getClientList();
        if (this.selectedClients.length > 0) {
          this.mapManagedCompaniesToUser();
        }
        this.addcloseBtn.nativeElement.click();
      });
  }

  private sortUserList() {
    this.userList = this.userList?.sort((first: any, next: any) =>
      first.UserName.toLowerCase() > next.UserName.toLowerCase() ? 1 : -1
    );
    this.userList?.forEach((res) => {
      this.userName.push({ label: res.UserName, value: res.UserName });
      this.Email.push({ label: res.EmailID, value: res.EmailID });
    });
  }

  public requestDataFromMultipleSources(fieldEngineers): Observable<any[]> {
    let reqList = [];
    for (var i = 0; i < fieldEngineers.length; i++) {
      let response = this.httpService.get(
        RestApi.get_client_user_mapping_by_userid +
          "/" +
          fieldEngineers[i].UserID
      );
      reqList.push(response);
    }
    return forkJoin(reqList);
  }

  private getClientList(): void {
    this.httpService.get(this.getClientAPIURL).subscribe((res: any) => {
      if (res.Data) {
        this.updateUserList(res.Data);
      }
    });
  }

  private updateUserList(clientList: any = {}): void {
    this.userList?.forEach((element) => {
      if (clientList.length > 0 && element.ClientID != 0) {
        clientList.forEach((clientItem) => {
          if (clientItem.ClientID == element.ClientID) {
            element.ClientName = clientItem.ClientName;
          }
        });
      }
    });
  }

  private clearAlarmDetails() {
    this.isAlarmSubcriptionVisible = false;
    this.isAlarmSubEnable = false;
    this.oldSiteList = undefined;
    this.oldAreaID = undefined;
    this.oldSiteID = undefined;
    this.oldRoleId = undefined;
  }

  showUserModal(val, data?: UserData) {
    this.clearAlarmDetails();
    if (val == Constant.CommandType.delete) {
      this.userID = data.UserID;
      this.username = data.UserName;
      this.oid = data.Oid;
      this.roleID = data.RoleID;
      let selClient = this.clientList.filter((ele) => {
        return ele.ClientID == data.ClientID;
      });
      this.selectedClient = selClient[0];
      this.tenantId = this.selectedClient.TenantId;
    } else if (val === Constant.CommandType.resetPassword) {
      this.userID = data.UserID;
      this.username = data.UserName;
      this.userEmailId = data.EmailID;
      this.oid = data.Oid;
      this.userPreferredLanguage = data.PreferredLanguage;
      this.emailID = data.EmailID;
    } else if (val == "View") {
      this.username = data.UserName;
    }
  }

  getRoleField(data) {
    let selClient = this.clientList.filter((ele) => {
      return ele.ClientID == data.ClientID;
    });
    this.selectedClient = selClient[0];
    this.companyID = selClient[0]?.CompanyID;
    if (data.RoleName == Constant.Roles.clientAdmin) {
      this.addUser.ClientID = data.ClientID;
      //this.getAllClientList(data.ClientID);
    } else if (data.RoleName == Constant.Roles.areaManager) {
      this.addUser.ClientID = data.ClientID;
      this.addUser.AreaID = data.AreaID;
      //this.getAllClientList(data.ClientID);
      this.getAreaListByClientID(
        Constant.CommandType.edit,
        data.ClientID,
        data.AreaID
      );
    } else if (
      data.RoleName == Constant.Roles.siteManager ||
      this.roleName == Constant.Roles.siteUser
    ) {
      this.addUser.ClientID = data.ClientID;
      this.addUser.AreaID = data.AreaID;
      this.addUser.SiteID = data.SiteID;
      //this.getAllClientList(data.ClientID);
      this.getAreaListByClientID(
        Constant.CommandType.edit,
        data.ClientID,
        data.AreaID
      );
      this.getSiteListByAreaID(
        Constant.CommandType.edit,
        data.SiteID,
        data.AreaID
      );
    }
  }

  saveUser() {
    this.addUser.UserID = this.userID;
    this.addUser.RoleID = this.selectedRole.id;
    this.addUser.RoleName = this.selectedRole.name;
    this.addUser.CreatedOn = this.createdDate;
    this.addUser.CreatedBy = this.userProfile.userName;
    this.addUser.CreatedByID = this.userProfile.userID;
    this.addUser.ModifiedOn = this.createdDate;
    this.addUser.ModifiedBy = this.userProfile.userName;
    this.addUser.ModifiedByID = this.userProfile.userID;

    // if user is reseller admin or super user
    // then set it to penn company which is default company
    // for site user, set company id as null
    if (this.addUser.RoleID === 1 || this.addUser.RoleID === 2) {
      this.addUser.CompanyID = "7"; // PENN Company
    } else {
      this.addUser.CompanyID = this.companyID;
    }

    this.confirmPassword = "";

    if (
      this.roleName == Constant.Roles.clientAdmin ||
      this.roleName == Constant.Roles.fieldEngineer
    ) {
      this.addUser.ClientID = this.selectedClient.ClientID;
      this.addUser.AreaID = 0;
      this.addUser.SiteID = 0;
    } else if (this.roleName == Constant.Roles.areaManager) {
      this.addUser.ClientID = this.selectedClient.ClientID;
      this.addUser.AreaID = this.selectedArea.AreaID;
      this.addUser.SiteID = 0;
    } else if (
      this.roleName == Constant.Roles.siteManager ||
      this.roleName == Constant.Roles.siteUser
    ) {
      this.addUser.ClientID = this.selectedClient.ClientID;
      this.addUser.AreaID = this.selectedArea.AreaID;
      this.addUser.SiteID = this.selectedSite.siteID;
    } else {
      this.addUser.ClientID = 0;
      this.addUser.AreaID = 0;
      this.addUser.SiteID = 0;
    }

    // on new user create set send password link as pr client secure preference value
    let sentPasswordLink =
      this.addUser.UserID === 0 ? this.isSecurePasswordCreationEnable : false;

    this.isAlarmSubcriptionVisible =
      this.selectedRole.id === 4 ||
      this.selectedRole.id === 5 ||
      this.selectedRole.id === 6
        ? true
        : false;

    let alarmSubscribtionDetails = undefined;

    if (this.isAlarmSubcriptionVisible) {
      if (
        this.isAlarmSubEnable !== this.isAlarmSubEnableOld ||
        this.oldRoleId !== this.addUser.RoleID ||
        this.addUser.AreaID !== this.oldAreaID ||
        this.addUser.SiteID !== this.oldSiteID
      ) {
        let unSubscribeStores = undefined;
        if (this.isAlarmSubEnableOld) {
          if (
            (this.addUser.AreaID !== this.oldAreaID && this.oldRoleId === 4) ||
            (this.addUser.RoleID === 5 && this.oldRoleId === 4) ||
            (this.addUser.RoleID === 6 && this.oldRoleId === 4)
          ) {
            unSubscribeStores = this.clientSites.filter(
              (s) =>
                this.oldSiteList.filter(
                  (ss) => ss.siteID === s.SiteID && s.PremiseID > 0
                ).length > 0
            );
          } else if (this.addUser.SiteID !== this.oldSiteID) {
            unSubscribeStores = this.clientSites.filter(
              (s) => s.SiteID === this.oldSiteID && s.PremiseID > 0
            );
          }
        }

        const stores =
          this.addUser.RoleID === 5 || this.addUser.RoleID === 6
            ? [this.selectedSite]
            : this.siteList;

        const filterStores = this.clientSites.filter(
          (s) =>
            stores.filter((ss) => ss.siteID === s.SiteID && s.PremiseID > 0)
              .length > 0
        );

        alarmSubscribtionDetails = {
          IsAlarmSubscriptionEnabled: this.isAlarmSubEnable,
          UnSubscribeSites: unSubscribeStores,
          SubscribeSites: filterStores,
        };
      }
    } else if (this.isAlarmSubEnable) {
      // if subscription visiable flag is disable but previous notification is enable
      // in that case we need to explicitly need to remove alarm subscriptions
      const unSubStores = this.oldSiteList;
      alarmSubscribtionDetails = {
        IsAlarmSubscriptionEnabled: false,
      };

      const store = this.clientSites.filter(
        (s) =>
          unSubStores.filter((ss) => ss.siteID === s.SiteID && s.PremiseID > 0)
            .length > 0
      );

      alarmSubscribtionDetails["SubscribeSites"] = store;
    }
  }

  mapClientsToUser() {
    if (this.userID === 0) {
      this.httpService
        .get(
          RestApi.user_detail_by_username +
            `/?userName=${this.addUser.UserName}`
        )
        .subscribe((res: any) => {
          if (res.Data) {
            const data = {
              ClientID: this.selectedClients[0],
              UserID: res.Data["UserID"],
              UserName: res.Data["UserName"],
              CreatedOn: this.createdDate,
              CreatedBy: this.userProfile.userName,
              CreatedByID: this.userProfile.userID,
              ModifiedOn: this.createdDate,
              ModifiedBy: this.userProfile.userName,
              ModifiedByID: this.userProfile.userID,
              Clients: this.selectedClients,
            };
            this.addOrUpdateMapClientToUser(data);
          }
        });
    } else {
      const data = {
        ClientID: this.selectedClients[0],
        UserID: this.userID,
        UserName: this.existingUserName,
        CreatedOn: this.createdDate,
        CreatedBy: this.userProfile.userName,
        CreatedByID: this.userProfile.userID,
        ModifiedOn: this.createdDate,
        ModifiedBy: this.userProfile.userName,
        ModifiedByID: this.userProfile.userID,
        Clients: this.selectedClients,
      };
      this.addOrUpdateMapClientToUser(data);
    }
  }

  private addOrUpdateMapClientToUser(data: {
    ClientID: any;
    UserID: any;
    UserName: any;
    CreatedOn: String;
    CreatedBy: any;
    CreatedByID: any;
    ModifiedOn: String;
    ModifiedBy: any;
    ModifiedByID: any;
    Clients: any[];
  }) {
    this.httpService
      .post(RestApi.map_client_user, data)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  unMapClientsToUser() {
    const data = {
      ClientID: 0,
      UserID: this.userID,
      UserName: this.username,
      CreatedOn: this.createdDate,
      CreatedBy: this.userProfile.userName,
      CreatedByID: this.userProfile.userID,
      ModifiedOn: this.createdDate,
      ModifiedBy: this.userProfile.userName,
      ModifiedByID: this.userProfile.userID,
      Clients: this.selectedClients,
    };

    this.httpService
      .post(RestApi.unmap_client_user, data)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.deletecloseBtn.nativeElement.click();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  mapManagedCompaniesToUser() {
    let user: any;
    let companyIds = [];
    let res = [];
    res = this.clientList?.filter((el) => {
      return this.selectedClients?.find((element) => {
        return element === el.ClientID;
      });
    });
    user = this.userList?.find((element) => {
      return (
        element.UserName?.toLowerCase() === this.addUser.UserName?.toLowerCase()
      );
    });
    if (user === undefined) {
      return;
    }
    companyIds = res?.map(function (a) {
      return a.CompanyID;
    });
    let obj = {
      Oid: user["Oid"],
      CompanyIds: companyIds,
      IsAdded: this.userID === 0 ? true : false,
    };

    this.httpService
      .post(RestApi.map_managed_company_user, obj)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteUser() {
    this.httpService
      .post(RestApi.delete_user, {
        UserID: this.userID,
        Oid: this.oid,
        UserName: this.username,
        TenantId: this.tenantId,
        RoleID: this.roleID,
      })
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.userdeletedsuccessfully,
          });
          if (this.selectedClients.length > 0) {
            this.unMapClientsToUser();
          }
          this.deletecloseBtn.nativeElement.click();
          this.getUsers();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  private async getUserDetails(userId: number): Promise<null | any> {
    let response = await this.httpService
      .get(RestApi.user_details + "/" + userId)
      .toPromise<any>();

    if (response.IsSuccess === false || response.Data === null) {
      return null;
    }
    return response.Data;
  }

  public clearModal(): void {
    this.addUser = <UserData>{};
    this.selectedRole = {
      id: 0,
      name: this.translate.data.moduleInstructionType.selectrole,
    };
    this.confirmPassword = "";
    this.changePassword = false;
    this.companyID = null;
    this.selectedClient = null;
  }

  public isPasswordInvalid(): boolean {
    if (this.addUser.Password) {
      return !this.addUser.Password.match(
        "(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
      );
    }

    return false;
  }

  public isMobileInvalid(): boolean {
    if (this.addUser.Mobile) {
      let regexp = /^\+[0-9]{8,14}$/;
      return regexp.test(this.addUser.Mobile) === false;
    }

    return false;
  }

  public isEmailInvalid(): boolean {
    if (this.addUser.EmailID) {
      const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

      return regexp.test(this.addUser.EmailID) === false;
    }

    return false;
  }

  ValidateUser(): boolean {
    if (this.userID == 0) {
      let status =
        !this.addUser.FullName ||
        !this.addUser.UserName ||
        this.isEmailInvalid() ||
        this.isMobileInvalid() ||
        this.selectedRole.id == 0 ||
        this.fieldFlags;

      if (this.isSecurePasswordCreationEnable === false) {
        status =
          status ||
          this.isPasswordInvalid() ||
          !this.addUser.Password ||
          this.addUser.Password != this.confirmPassword;
      }

      return status;
    } else
      return (
        !this.addUser.FullName ||
        !this.addUser.UserName ||
        !this.addUser.EmailID ||
        this.selectedRole.id == 0 ||
        this.fieldFlags ||
        this.isMobileInvalid() ||
        (this.changePassword == true
          ? !this.addUser.Password ||
            this.isPasswordInvalid() ||
            this.addUser.Password != this.confirmPassword
          : false)
      );
  }

  validateUserRole() {
    if (
      (this.userProfile &&
        this.userProfile.roleName === Constant.Roles.adminReseller) ||
      this.userProfile.roleName == Constant.Roles.superAdmin
    ) {
      return (
        this.selectedRole?.id === 0 ||
        this.selectedClient === null ||
        this.selectedClient === ""
      );
    } else {
      return this.selectedRole?.id === 0;
    }
  }

  downloadUserList() {
    let userListurl =
      RestApi.GetAllUserListByClientID + "/" + this.userProfile.clientID;
    this.httpService.get(userListurl).subscribe((res: any) => {
      if (res.IsSuccess) {
        this.exportData = res.Data
          ? res.Data.map((userInfo) => [
              userInfo.Username,
              userInfo["Email"],
              userInfo["Role Name"],
              userInfo["Full Name"],
              userInfo["Site Name"],
              userInfo["Region Name"],
              userInfo["Email Notification"],
              userInfo["Sms Notification"],
              userInfo["Alarm Notification"],
              userInfo["Offline Notification"],
            ])
          : [];

        const headers: string[] = [
          "Username",
          "Email address",
          "Role",
          "Full Name",
          "Sites",
          "Regions",
          "Email Notification",
          "SMS Notifications",
          "Alarms notifications",
          "Offline notifications",
        ];
        let filename = "Users_" + new Date().toLocaleString();
        this.exportAsXLSX(filename, headers, this.exportData);
      } else {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: res.ReturnMessage,
        });
      }
    });
  }

  exportAsXLSX(fileName, headers, xlsxData): void {
    this.excelService.exportXLSXData(fileName, [headers, ...xlsxData]);
  }

  public checkPassword(): void {
    if (this.addUser.Password == this.confirmPassword) {
      this.wrongPassword = false;
    } else {
      this.wrongPassword = true;
    }
  }

  public togglePasswordChange(): void {
    if (!this.changePassword) {
      this.addUser.Password = this.confirmPassword = "";
    }
  }

  public resetUserPassword(): void {
    this.GetEmailTemplate(this.userPreferredLanguage);
    this.httpService
      .post(RestApi.reset_user_password, {
        UserID: this.userID,
        Oid: this.oid,
        EmailTemplate: this.emailTemplateBase64String,
        EmailID: this.emailID,
        UserName: this.username,
      })
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          let msg =
            this.userID > 0
              ? this.translate.data.Popuop_Msg
                  .successfullysendresetpasswordemail
              : this.translate.data.Popuop_Msg.useraddedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });
          this.resetCloseBtn.nativeElement.click();
          this.getUsers();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  private getClientSites(clientID) {
    this.httpService.get(`${RestApi.client_site_list}/${clientID}`).subscribe(
      (response: any) => {
        this.clientSites =
          response.IsSuccess && response.Data && response.Data != null
            ? filterSites(response.Data)
            : [];
      },
      (_error: any) => (this.clientSites = [])
    );
  }

  private getAllClientUserMappingByUserId(userID) {
    this.httpService
      .get(`${RestApi.get_client_user_mapping_by_userid}/${userID}`)
      .subscribe((response: any) => {
        if (response.IsSuccess) {
          this.selectedClients = [];
          let res = [];
          res = this.clientList?.filter((el) => {
            return response.Data?.find((element) => {
              return element.ClientID === el.ClientID;
            });
          });
          this.selectedClients = res?.map(function (a) {
            return a.ClientID;
          });
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: response.ReturnMessage,
          });
        }
      });
  }
}

const filterSites = (sites) => sites.filter((site) => site.Status === "Active");
