<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="row">
    <div class="col-sm-6 breadcrumbSection">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <!-- <li *ngIf="showClientname" class="breadcrumb-item"><a routerLink="/client">{{clientName}}</a></li> -->
          <li class="breadcrumb-item">
            <a (click)="redirectBack()">{{ "sites" | translate: "Site" }}</a>
          </li>
          <li class="breadcrumb-item">
            {{ "Notifications" | translate: "Notification" }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row">
    <div
      class="page-title col-md-12 col-xl-12 col-sm-12 col-xs-12 col-lg-12 m-24"
    >
      {{ "Notifications" | translate: "Notification" }}
    </div>
  </div>

  <div *ngIf="hideSiteFilter">
    <div class="row m-0">
      <div class="col-sm-12 filter_section">
        <div class="row aic">
          <div class="col-md-1">
            <div class="text-uppercase font-weight700 font-size12">
              {{ "filterBy" | translate: "Common" }}
            </div>
          </div>
          <div class="col-md-4 tblsearch listsearch">
            <div class="filtertitle pr-2">
              {{ "sites" | translate: "Reports" }}
            </div>
            <p-dropdown
              class="dropdown"
              [options]="siteList"
              filter="true"
              [(ngModel)]="selectedSite"
              (onChange)="onSelectSite()"
              optionLabel="label"
              [style]="{ width: '200px' }"
            ></p-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="row mt10">
      <div class="col-sm-12">
        <div class="content">
          <div class="content-body configTable p-0">
            <div
              *ngFor="
                let item of notificationList;
                let i = index;
                let last = last
              "
              class="row_list"
            >
              <div class="last_alarm_header" *ngIf="i === 0">
                <div class="c1 alarm_header_spacing">
                  {{ "siteName" | translate: "Site" }}
                  <span style="font-size: 18px">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-angle-up': siteNameSortFlag,
                        'fa-angle-down': !siteNameSortFlag
                      }"
                      (click)="
                        getNotificationSortedBy(
                          'name',
                          (siteNameSortFlag = !siteNameSortFlag)
                        )
                      "
                    ></i>
                  </span>
                </div>
                <div class="c1 alarm_header_spacing">
                  {{ "locationName" | translate: "Notification" }}
                </div>
                <div class="alarm_header_spacing c1">
                  {{ "sentDateTime" | translate: "Notification" }}
                  <span style="font-size: 18px">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-angle-up': occurredBySortFlag,
                        'fa-angle-down': !occurredBySortFlag
                      }"
                      (click)="
                        getNotificationSortedBy(
                          'Occurred',
                          (occurredBySortFlag = !occurredBySortFlag)
                        )
                      "
                    ></i>
                  </span>
                </div>
                <div style="flex: 1" class="alarm_header_spacing">
                  {{ "Recipient" | translate: "Common" }}
                </div>
                <div style="flex: 0.7" class="alarm_header_spacing">
                  {{ "notificationMethod" | translate: "Notification" }}
                </div>
                <div style="flex: 1.5" class="alarm_header_spacing">
                  {{ "Message" | translate: "Common" }}
                </div>
                <div style="flex: 0.7" class="alarm_header_spacing">
                  {{ "messageType" | translate: "Notification" }}
                </div>
                <div style="flex: 2" class="alarm_header_spacing">
                  {{ "messageStatus" | translate: "Notification" }}
                </div>
              </div>
              <div class="last_alarm_row" *ngIf="!last">
                <div class="c1 row_alignment">
                  {{ item.premises_name }}
                </div>
                <div class="c1 row_alignment" style="padding: 5px">
                  {{
                    item.description | delimit: "50" | translate: "Notification"
                  }}
                </div>
                <div class="row_alignment c1" style="padding: 5px">
                  {{ item.occured | moment: "DateTime" }}
                </div>
                <div class="row_alignment" style="flex: 1; padding: 5px">
                  {{ item.first_name }} {{ item.last_name }}
                </div>
                <div
                  [ngClass]="{
                    sms_image: item.medium === 'sms',
                    email_image: item.medium === 'email',
                    push_notification_image: item.medium === 'mobile-push',
                    voice_image: item.medium === 'voice'
                  }"
                  style="flex: 0.7; padding-right: 8px; margin-top: 20px"
                  title="{{ item.medium | translate: 'Notification' }}"
                ></div>
                <div class="row_alignment" style="flex: 1.5; padding: 5px">
                  {{ item.message | translate: "Notification" }}
                </div>
                <div
                  style="flex: 0.7; padding: 5px; margin-top: 20px"
                  [ngClass]="{
                    alarm_image: item.severity <= 50,
                    esclation_image: item.severity > 50
                  }"
                  [title]="item.severity <= 50 ? 'Alarm' : 'Escalation'"
                ></div>
                <div class="row_alignment message_status" style="flex: 2">
                  <div
                    class="message_status_spacing"
                    *ngFor="let ndata of item?.ndatais"
                  >
                    <span>
                      <span
                        [ngClass]="{
                          initiated_css: ndata.status === 'initiated',
                          deliverd_text_css: ndata.status === 'delivered',
                          processed_text_css:
                            ndata.status === 'processed' ||
                            ndata.status === 'sent'
                        }"
                      >
                        {{ ndata.status | translate: "Notification" }}
                      </span>
                      <p class="status_dateTime_css">
                        {{ ndata.duration | moment: "LongDateTime" }}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <tc-pagination
              *ngIf="this.notificationList.length > 0"
              [currentOffset]="currentOffset"
              [size]="pageSize"
              [records]="
                this.notificationList[this.notificationList.length - 1]?.count
              "
              (pageChange)="onPageChange($event)"
            ></tc-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
