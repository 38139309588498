import { Component, Input, SimpleChanges } from "@angular/core";

import { DefaultDeepsObj } from "src/app/common/helperFunction/defaults-deep";
import { TranslateService } from "src/app/common/services/translate.service";
import { PipeChartDefaults } from "./pipe-chart.default";
import moment from "moment";
declare var Highcharts: any;

enum SensorKey {
  Water = "3000",
  CO2 = "4000",
  Pressure = "5000",
  Humidity = "1102",
  Door = "0",
  DtTemperature = "0",
}
@Component({
  selector: "pipe-monitoring-chart",
  templateUrl: "./pipe-monitoring-chart.component.html",
  styleUrls: ["./pipe-monitoring-chart.component.scss"],
  providers: [PipeChartDefaults],
})
export class PipeMonitoringChartComponent {
  @Input() chartdata;
  chart;
  @Input() componentName;
  @Input() seriesData;
  @Input() categoriesData;
  seriesConfig = [];
  chartOptions;
  defaultOptions;
  temperatureGraphcolorsList = [
    "#00C6E2",
    "#E25353",
    "#D6D525",
    "#FA5EE0",
    "#F47721",
  ];
  title = ["Temperature", "Relative Humidity", "CO2", "Pressure"];
  @Input() graphthreshold;
  private defaultDeepObj;

  constructor(
    _defaults: PipeChartDefaults,
    private translate: TranslateService
  ) {
    this.defaultOptions = _defaults.options;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["chartdata"]) {
      if (this.chartdata) {
        this.filterLowThresholdValue(this.chartdata);
        let pipeData;
        pipeData = [
          {
            type: "areaspline",
            showInLegend: false,
            selectionMarkerFill: "none",
            labels: {
              enabled: false,
            },
            legend: {
              layout: "horizontal",
              itemMarginTop: 5,
            },
            exporting: {
              enabled: false,
            },
            tooltip: {
              //valueSuffix: this.getUnitTypeAndName(item)[0],
            },
            //name: this.getUnitTypeAndName(item)[1],
            yAxis: 0,
            data: this.chartdata,
            color: this.temperatureGraphcolorsList[0],
          },
        ];

        if (pipeData) {
          this.renderChart(pipeData);
        }
      }
    }
  }

  filterLowThresholdValue(data) {}

  renderChart(seriesdata) {
    let self = this;
    let categories: any = [];
    this.seriesConfig = [];
    this.defaultDeepObj = new DefaultDeepsObj();
    categories = this.categoriesData;

    Highcharts.setOptions({
      time: {
        useUTC: false,
      },
      labels: {
        style: {
          color: "#3E576F",
          position: "absolute",
        },
      },
      chart: {
        borderColor: "#4572A7",
        borderRadius: 0,
        plotBorderColor: "#C0C0C0",
        reflow: true,
        spacing: [10, 10, 15, 10],
        width: null,
        zoomType: "x",
        type: "areaspline",
        selectionMarkerFill: "none",
        styledMode: false,
      },
      series: [],
      colors: this.temperatureGraphcolorsList,
      tooltip: {
        animation: true,
        xDateFormat: "%Y-%m-%d",
        backgroundColor: "rgba(249, 249, 249, .85)",
        borderColor: "#00539e",
        borderRadius: 3,
        borderWidth: 1,
        crosshairs: true,
        enabled: true,
        formatter: function () {
          return (
            `<span style="color:${this.color}">●</span> Pipe: <b>${this.y}</b><br/>` +
            `             ${moment(new Date(this.x)).format(
              "YYYY-MM-DD HH:mm UTC ZZ"
            )} 
            `
          );
        },
        followTouchMove: true,
        footerFormat: "",
        headerFormat: "", //'<span style="font-size: 10px">{point.key}</span><br/>',
        pointFormat: "",
        shadow: false,
        shared: true,
        snap: 10,
        useHTML: true,
        style: {
          color: "#888",
          cursor: "default",
          fontSize: "9pt",
          fontWeight: "bold",
          padding: "8px",
          pointerEvents: "none",
          whiteSpace: "nowrap",
        },
      },
      plotOptions: {
        animation: true,
        series: {
          connectNulls: true,
          dataGrouping: {
            enabled: false,
          },
          marker: {
            enabled: false,
          },
          zones: [
            {
              value: this.graphthreshold,
              color: "#a8ccec",
            },
            {
              color: "#f0ba1e",
            },
          ],
          //threshold: -12.9,
        },
        line: {
          allowPointSelect: false,
          animation: { duration: 1000 },
          cropThreshold: 300,
          pointRange: 0,
          showCheckbox: false,
          softThreshold: true,
          states: {
            hover: {
              lineWidthPlus: 1,
              halo: {
                opacity: 0.25,
                size: 10,
              },
              marker: {},
            },
            select: { marker: {} },
          },

          turboThreshold: 100000,
        },
      },
      loading: {
        labelStyle: {
          fontWeight: "bold",
          position: "relative",
          top: "45%",
        },
        style: {
          backgroundColor: "white",
          opacity: 0.5,
          position: "absolute",
          textAlign: "center",
        },
      },
      lang: {
        loading: "Loading...",
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      },
    });

    this.chartOptions = {
      time: {
        useUTC: false,
      },

      tooltip: {
        enabled: true,
        crosshairs: true,
      },
      credits: {
        enabled: false,
      },

      title: {
        text: "Pipe",
        useHTML: true,
        style: {
          fontWeight: "bold",
          fontSize: "13px",
          textTransform: "Uppercase",
          color: "#333740",
        },
        align: "center",
      },
      yAxis: {
        plotLines: [
          {
            id: "PlotLinesLevel1",
            color: "red",
            width: 1,
            value: this.graphthreshold,
            zIndex: 1,
            dashStyle: "longdashdot",
          },
        ],
      },
      series: seriesdata,
    };

    this.defaultDeepObj.defaultsDeepObj(this.chartOptions, this.defaultOptions);

    Highcharts.chart("container1", this.chartOptions);
  }
}
