import { Component } from "@angular/core";
import { PennService } from "../common/penn.service";
import { MessageService } from "primeng/api";
import { TranslateService } from "../common/services/translate.service";
import { HttpService } from "../common/services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RestApi } from "../common/constants/RestAPI";
import { SessionVariable } from "../common/class/storageLabel";

@Component({
  selector: "app-sensor-verification",
  templateUrl: "./sensorverification.component.html",
  styleUrls: ["./sensorverification.component.scss"],
})
export class SensorVerificationComponent {
  siteID;
  sensorVerifcationList;
  Title = "Sensor Verification";
  verficationSensorList;
  selectedSiteSensor;
  commisionedDeviceList;
  sensorList = [];
  sitename;
  selectedVerificationSensor;
  searchText;
  verficationSensorInProcessList = [];
  userProfile;
  passedcount = 0;
  failedcount = 0;
  completedcount = 0;
  totalprocesscount = 0;
  filteredList = [];
  summaryList;
  status = {
    InProgress: "in progress",
    Passed: "passed",
    Failed: "failed",
  };
  currentOffset = 0;
  pageSize = 8;
  records = 30;
  currentPage = 1;
  clientID;
  cancelrowData;

  constructor(
    public pennService: PennService,
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    private router: Router,
    public _router: ActivatedRoute
  ) {
    this.siteID = this._router.snapshot.paramMap.get("siteid");
    this.clientID = this._router.snapshot.paramMap.get("clientid");
    this.sitename = this._router.snapshot.paramMap.get("sitename");
  }

  ngOnInit() {
    this.getSensorVerificationStatus();
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.getSensorVerificationList();
    this.getcommisionedSensors();
  }

  getSensorVerificationStatus() {
    this.httpService
      .get(RestApi.GetSiteSensorVerification + "/" + this.siteID)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.verficationSensorInProcessList = res.Data.Results;
          this.summaryList = res.Data;
          this.filteredList = [...this.verficationSensorInProcessList];
          this.updateProcessCount();
        }
      });
  }

  updateProcessCount() {
    if (this.verficationSensorInProcessList.length > 0) {
      this.totalprocesscount = this.verficationSensorInProcessList.length;
      let passedcounter = 0;
      let failedcount = 0;
      this.verficationSensorInProcessList.forEach((item) => {
        if (item.VerificationStatus === "completed") {
          passedcounter++;
        } else if (item.VerificationStatus === "failed") {
          failedcount++;
        }
      });
      this.passedcount = passedcounter;
      this.failedcount = failedcount;
    }
  }

  getSensorVerificationList() {
    this.httpService
      .get(
        RestApi.GetAvailableVerificationSensorList +
          "/" +
          this.userProfile.userID
      )
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.verficationSensorList = res.Data;
        }
      });
  }

  getcommisionedSensors() {
    this.httpService
      .get<any>(RestApi.GetSiteVerificationSensorList + "/" + this.siteID)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.sensorList = res.Data;
        }
      });
  }

  onSelectSiteSensor() {}

  onSelectVerificationSensor() {}

  saveVerification() {
    const saveRequest = {
      SiteID: this.siteID,
      CalibrationSensorID: this.selectedSiteSensor.UnitID,
      VerificationSensorID: this.selectedVerificationSensor
        .VerificationSensorID,
    };

    this.httpService
      .post(RestApi.SaveVerificationSensorProcess, saveRequest)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.getSensorVerificationStatus();
          this.getSensorVerificationList();
        }
      });
  }

  resetverificationsensorlist() {
    this.sensorList = [];
    this.selectedVerificationSensor = [];
  }

  filterSensorList(event) {
    console.log("event", event.target.value);
    let value = event.target.value;
    if (value === "") {
      this.filteredList = [...this.verficationSensorInProcessList];
    } else {
      this.filteredList = [...this.verficationSensorInProcessList];
      this.filteredList = this.filteredList.filter((item) => {
        return item.CabinetName.toUpperCase().includes(value.toUpperCase());
      });
    }
  }

  onPageChange(event) {
    if (this.currentPage === event.currentPage) {
      return;
    }
    this.currentPage = event.currentPage;
    this.currentOffset = event.currentOff;
  }

  cancelRowData(data) {
    this.cancelrowData = data;
    console.log("cancelrowData", this.cancelrowData);
  }

  cancleSensorVerifcation() {
    this.httpService
      .delete(
        RestApi.cancelSensorVerification,
        this.cancelrowData.SensorVerificationID
      )
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.getSensorVerificationStatus();
          this.getSensorVerificationList();
        }
      });
  }
}
