import { Component, ElementRef, Inject, OnInit } from "@angular/core";

import { PennService } from "src/app/common/penn.service";
import { HttpService } from "src/app/common/services/http.service";
import { TranslateService } from "src/app/common/services/translate.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { ModuleService } from "../common/services/module-service/module.service";
import { Module } from "src/app/common/models/module";
import { UserProfile } from "src/app/common/models/user.model";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Constant } from "../common/constants/constant";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { MessageService } from "primeng/api";
import { ToastMsg } from "../common/constants/toastmsg.constant";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  readonly title: String;
  readonly translations: { [key: string]: any };

  userProfile: UserProfile;

  sites: any[] | undefined = undefined;
  modules: Array<Module> = [];
  refrigerationModuleEnabled: boolean = false;
  selectedClient;
  clientList = [];
  refigerationCharts: boolean = false;
  showModule: boolean = false;
  showGraphs: boolean = false;
  showOrHideSubscriptionMenu;
  activeIndex: number = 0;
  viewforClient = false;
  selectedTabName: string;
  refreshDashBoard = false;
  refreshTaskManagement: boolean = false;
  widgetClicked;
  DashboardMenu = {
    MyDashboard: this.translate.data.Dashboard.myDashboard,
    RefrigerationAlarm: this.translate.data.Dashboard.refrigerationAlarms,
    ComplianceModule: this.translate.data.Dashboard.complianceModules,
    RefrigerationInsights: this.translate.data.Dashboard.refrigerationInsights,
    TaskManagement: this.translate.data.TaskManagement.taskManagement,
    Announcement: this.translate.data.Announcements.announcements,
  };
  mydashboardConfiguration = { widgetInorderArr: [] };
  mydashboardCount;
  mydashboardAddedModule;
  // @ViewChild("mydashboard", { static: true }) mydashboard: ElementRef;
  // @ViewChild("refrigeration", { static: true }) refrigeration: ElementRef;

  refrigerationModule = {
    "Open Alarm": { modulename: "refrigerationModule" },
    "Recurring Alarm": { modulename: "refrigerationModule" },
    "Resolved Alarm": { modulename: "refrigerationModule" },
    "Highest Average Completion": { modulename: "refrigerationModule" },
  };

  complianceModule = {};

  refrigerationInsightsModule = {
    "Inefficient Cabinet": { modulename: "refrigerationInsightsModule" },
    "Most Improved Site": { modulename: "refrigerationInsightsModule" },
    "Average Asset Score": { modulename: "refrigerationInsightsModule" },
    "Comparision of Equipment": { modulename: "refrigerationInsightsModule" },
  };

  taskManagementModule = {
    "Compliance Score": { modulename: "taskManagementModule" },
    "Overdue Task By Module": { modulename: "taskManagementModule" },
    "Overdue Task By Sites": { modulename: "taskManagementModule" },
    "Site Task Status": { modulename: "taskManagementModule" },
    "Task Completion": { modulename: "taskManagementModule" },
    "Overdue Task Site": { modulename: "taskManagementModule" },
  };

  announcementModule = {
    "Message Breakdown": { modulename: "announcementModule" },
    "Overall Message Status": { modulename: "announcementModule" },
  };

  dashboardWidgetConfigAll = {};

  constructor(
    public pennService: PennService,
    private translate: TranslateService,
    private httpService: HttpService,
    private moduleService: ModuleService,
    private messageService: MessageService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    this.title = this.translate.data.Dashboard.dashboard;
    this.translations = this.translate.data.Dashboard;
  }

  ngOnInit() {
    this.storage.remove("routename");

    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );

    this.setDashboardMenuFromLocalStorage();

    if (this.userProfile.roleName === "Field Engineer") {
      let clientData = this.pennService.getStoredObj("userClientsMapping");
      this.clientList = this.sortedList(clientData, "ClientName");
      this.selectedClient = this.clientList[0];
      this.userProfile.clientID = this.selectedClient.ClientID;
    }
    if (this.pennService.getStoredData("subscriptionsMenu")) {
      this.showOrHideSubscriptionMenu = JSON.parse(
        this.pennService.getStoredData("subscriptionsMenu")
      );
    }
    if (
      this.userProfile.roleName === "Client Admin" ||
      this.userProfile.roleName === "Regional Manager"
    ) {
      if (this.showOrHideSubscriptionMenu.IsRefrigerationWidgetEnabled) {
        this.refigerationCharts = true;
      }
    }
    this.getSites();
    this.getModules();

    this.activeIndex = 0;
  }

  setDashboardMenuFromLocalStorage() {
    const savedDashboardConfig = JSON.parse(
      localStorage.getItem("dashboardConfig")
    );
    if (savedDashboardConfig) {
      const currentUserRoleID = this.userProfile.userID;
      let currentDashboardSettings = savedDashboardConfig[currentUserRoleID];

      if (currentDashboardSettings) {
        this.dashboardWidgetConfigAll = { ...currentDashboardSettings };
        this.widgetClicked = this.DashboardMenu.MyDashboard;
        this.getMyDashboardWidgetsCount();
      } else {
        this.setdefaultConfig();
      }
    } else {
      this.setdefaultConfig();
    }
  }

  setdefaultConfig() {
    this.dashboardWidgetConfigAll = {
      mydashboardConfiguration: this.mydashboardConfiguration,
      refrigerationModule: this.refrigerationModule,
      complianceModule: this.complianceModule,
      refrigerationInsightsModule: this.refrigerationInsightsModule,
      taskManagementModule: this.taskManagementModule,
      announcementModule: this.announcementModule,
    };
  }

  ngOnDestroy(): void {
    this.sites = null;
    this.modules = null;
  }

  onSelectClient() {
    this.getSites(true);
    this.getModules(true);
  }

  toggleDashboard(event) {
    this.viewforClient = event;
  }

  sortedList(response, key: string) {
    return [...response].sort((a, b) =>
      a[`${key}`].split(" ").join("").toLowerCase() >
      b[`${key}`].split(" ").join("").toLowerCase()
        ? 1
        : -1
    );
  }

  private getModules(clientChangeFlag?: boolean) {
    if (this.modules != null && this.modules?.length > 0 && !clientChangeFlag) {
      return;
    }
    this.modules = [];
    if (this.userProfile.roleName === "Field Engineer") {
      this.pennService.saveDataToStorage(
        "clientID",
        this.selectedClient.ClientID
      );
      this.moduleService
        .getModulesByClientId(this.selectedClient.ClientID)
        .subscribe(
          (response) => {
            this.modules =
              response.IsSuccess && response.Data && response.Data != null
                ? response.Data.map((module) => ({
                    ...module,
                    SiteIds: module.SiteIds.split(","),
                  }))
                : [];
          },
          (err) => {
            this.modules = [];
          }
        );
    } else {
      this.moduleService.getModules().subscribe(
        (response) => {
          this.modules =
            response.IsSuccess && response.Data && response.Data != null
              ? response.Data.map((module) => ({
                  ...module,
                  SiteIds: module.SiteIds.split(","),
                }))
              : [];
          if (this.modules.length > 0) {
            this.showModule = true;
          } else {
            this.showModule = false;
          }
        },
        (err) => {
          this.modules = [];
        }
      );
    }
  }

  private getSites(clientChangeFlag?: boolean) {
    if (this.sites != null && this.sites?.length > 0 && !clientChangeFlag) {
      return;
    }
    this.sites = [];
    let clientID =
      this.userProfile.roleName === "Field Engineer"
        ? this.selectedClient.ClientID
        : this.pennService.getStoredData("clientID");
    this.httpService.get(`${RestApi.client_site_list}/${clientID}`).subscribe(
      (response: any) => {
        this.sites =
          response.IsSuccess && response.Data && response.Data != null
            ? filterSites(response.Data, this.userProfile)
            : [];

        this.refrigerationModuleEnabled = this.sites?.some(
          isSomeRefrigationModuleLink
        );
      },
      (_error: any) => (this.sites = [])
    );
  }

  tabChange(event: any) {
    this.widgetClicked = event.originalEvent.target.textContent;
    const tabVisibility: boolean[] = [
      true,
      this.showModule,
      this.refigerationCharts,
      this.showOrHideSubscriptionMenu?.IsTaskManagementEnabled,
      this.showOrHideSubscriptionMenu?.IsAnnouncementManagementEnabled,
    ];
    const newIndex = event.index;
    this.activeIndex = tabVisibility.indexOf(true, newIndex);
    if (this.widgetClicked === this.DashboardMenu.Announcement) {
      this.refreshDashBoard = true;
    }
    if (this.widgetClicked === this.DashboardMenu.TaskManagement) {
      this.refreshTaskManagement = true;
    }
  }

  addToMyDashboard(data) {
    const widgetname = Object.keys(data)[0];
    const modulename = data[widgetname].modulename;
    if (this.checkIfWidgetCountMorethanSix()) {
      return;
    }
    if (modulename === "complianceModule") {
      this.dashboardWidgetConfigAll["mydashboardConfiguration"][
        `${widgetname}`
      ] = data[widgetname];
      this.dashboardWidgetConfigAll["mydashboardConfiguration"] = {
        ...this.dashboardWidgetConfigAll["mydashboardConfiguration"],
      };
      this.deleteFromModulesArray(widgetname);
    } else {
      this.setMyDashboardConfigGlobalState(modulename, widgetname);
    }
    this.setWidgetArray(widgetname);
    this.setInLocalStorage();
    this.getMyDashboardWidgetsCount();
  }

  setMyDashboardConfigGlobalState(modulename, widgetname) {
    this.dashboardWidgetConfigAll["mydashboardConfiguration"][
      `${widgetname}`
    ] = this.dashboardWidgetConfigAll[modulename][widgetname];
    this.dashboardWidgetConfigAll["mydashboardConfiguration"] = {
      ...this.dashboardWidgetConfigAll["mydashboardConfiguration"],
    };

    delete this.dashboardWidgetConfigAll[modulename][widgetname];
  }

  setWidgetArray(widgetname) {
    let widgetArray = this.dashboardWidgetConfigAll["mydashboardConfiguration"][
      "widgetInorderArr"
    ];
    if (!widgetArray.includes(widgetname)) {
      if (widgetname === "Compliance Score") {
        //added in first due to full width view
        widgetArray.unshift(widgetname);
      } else {
        widgetArray.push(widgetname);
      }
    }
  }

  checkIfWidgetCountMorethanSix(): boolean {
    if (this.mydashboardCount >= 7) {
      this.messageService.add({
        severity: ToastMsg.severity.error,
        summary: this.translate.data.Common.maxWidgetLimit,
      });
      return true;
    }
  }

  deleteFromModulesArray(key) {
    for (let i = 0; i < this.modules.length; i++) {
      if (this.modules[i].ModuleID === key) {
        this.modules.splice(i, 1);
        break;
      }
    }
  }

  setInLocalStorage() {
    localStorage.setItem(
      "dashboardConfig",
      JSON.stringify({
        [this.userProfile.userID]: this.dashboardWidgetConfigAll,
      })
    );
  }

  updateDashboardConfig(data) {
    const widgetName = Object.keys(data)[0];
    const modulename = data[widgetName].modulename;

    if (modulename === "complianceModule") {
      this.modules = [...this.modules, data[widgetName].selectedModuleData];
    } else {
      this.updateDashboardConfigGlobalState(modulename, widgetName);
    }
    this.setInLocalStorage();
    this.getMyDashboardWidgetsCount();
  }

  updateDashboardConfigGlobalState(modulename, widgetName) {
    this.dashboardWidgetConfigAll[modulename][widgetName] = {
      modulename: modulename,
    };
  }

  updateDashboardOrderConfig(data) {
    this.dashboardWidgetConfigAll["mydashboardConfiguration"][
      "widgetInorderArr"
    ] = [...data];
    this.setInLocalStorage();
  }

  getMyDashboardWidgetsCount() {
    this.mydashboardCount = this.dashboardWidgetConfigAll[
      "mydashboardConfiguration"
    ]
      ? Object.keys(this.dashboardWidgetConfigAll["mydashboardConfiguration"])
          .length
      : 0;
  }
}

// helpers
const filterSites = (sites, userProfile: UserProfile) => {
  // only show active site details
  sites = sites.filter((site) => site.Status === "Active");

  if (userProfile.roleName === Constant.Roles.siteManager) {
    return sites.filter((site) => site.SiteID == userProfile.siteID);
  } else {
    return sites;
  }
};

const isSomeRefrigationModuleLink = (site): boolean =>
  site.PremiseID !== undefined && site.PremiseID > 0;
