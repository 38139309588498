import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PennService } from "../common/penn.service";
import { TranslateService } from "../common/services/translate.service";

@Component({
  selector: "app-commissioning-main",
  templateUrl: "./commissioning-main.component.html",
  styleUrls: ["./commissioning-main.component.scss"],
})
export class CommissioningMainComponent implements OnInit {
  title: string;
  siteName: string;
  clientName;
  clientId;

  constructor(
    private pennService: PennService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.title = this.translate.data.Commissioning.commissioning;
    this.siteName = this.pennService.getStoredData("siteName");
    this.clientId = this.pennService.getStoredData("clientID");
    this.clientName = this.pennService.getStoredData("clientName");
  }
}
