import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { DatePipe } from "@angular/common";

import { StepsModule } from "primeng/steps";
import { PanelModule } from "primeng/panel";
import { TableModule } from "primeng/table";
import { DropdownModule } from "primeng/dropdown";
import { TabViewModule } from "primeng/tabview";
import { MenuModule } from "primeng/menu";
import { FileUploadModule } from "primeng/fileupload";
import { DialogModule } from "primeng/dialog";
import { FieldsetModule } from "primeng/fieldset";
import { ToastModule } from "primeng/toast";
import { RadioButtonModule } from "primeng/radiobutton";
import { AddSiteComponent } from "./add-site/add-site.component";
import { MessageService } from "primeng/api";
import { PaginatorModule } from "primeng/paginator";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { MultiSelectModule } from "primeng/multiselect";
import { ListboxModule } from "primeng/listbox";
import { TooltipModule } from "primeng/tooltip";
import { CalendarModule } from "primeng/calendar";

import { ColorPickerModule } from "ngx-color-picker";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from "ngx-pagination";
import { AccordionModule } from "primeng/accordion";
import { ConfirmDialogModule } from "primeng/confirmdialog";

import { Interceptor } from "./common/services/Interceptor.service";
import {
  TranslateService,
  getUserLocale,
} from "./common/services/translate.service";

import { TranslatePipe } from "./common/pipes/translate.pipe";
import { GrdFilterPipe } from "./common/pipes/grd-filter.pipe";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HeaderComponent } from "./header/header.component";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { ClientComponent } from "./client/client.component";
import { SiteComponent } from "./site/site.component";
import { AddClientComponent } from "./add-client/add-client.component";
import { NotificationPanelComponent } from "./notification-panel/notification-panel.component";
import { ModuleBuilderComponent } from "./module-builder/module-builder.component";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { SuppliersComponent } from "./configuration/suppliers/suppliers.component";
import { ProductsComponent } from "./configuration/products/products.component";
import { AreasComponent } from "./configuration/areas/areas.component";
import { DeliveryTypesComponent } from "./configuration/delivery-types/delivery-types.component";
import { ConfigurationDetailsComponent } from "./configuration/configuration-details/configuration-details.component";
import { DndListModule } from "@ryware/ngx-drag-and-drop-lists";
import { TrueContainerComponent } from "./module-builder/conditional/trueContainer.component";
import { FalseContainerComponent } from "./module-builder/conditional/falseContainer.component";
import { LoginComponent } from "./login/login.component";
import { LoginCallbackComponent } from "./login/login-callback/login-callback.component";

import { LoopContainerComponent } from "./module-builder/conditional/loopContainer.component";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { InstructionFormComponent } from "./module-builder/conditional/instruction-form.component";
import { UserDashboardComponent } from "./user-dashboard/user-dashboard.component";
//import { AngularFontAwesomeModule } from "angular-font-awesome";
import { ModuleLogListComponent } from "./module-log/module-log-list/module-log-list.component";
import { ModuleLogDetailsComponent } from "./module-log/module-log-details/module-log-details.component";
import { NoAccessPageComponent } from "./no-access-page/no-access-page.component";
import { RefrigerationListComponent } from "./refrigeration/refrigeration-list/refrigeration-list.component";
import { RefrigerationDataComponent } from "./refrigeration/refrigeration-data/refrigeration-data.component";
import { DelimiterPipe } from "./common/pipes/delimiter.pipe";
import {
  DateFormatePipe,
  DateFormatMonthNamePipe,
  TimeFormat12hrPipe,
  getColorGreenColorOnSensorReading,
  getColorRedColorOnSensorReading,
} from "./common/pipes/dateformat.pipe";

import { OnlyNumber } from "./common/directives/onlynumber.directive";
import { TempNumberOnlyDirective } from "./common/directives/temp-input.directive";
import { CheckPasswordDirective } from "./common/directives/check-password.directive";
import { ReasonsComponent } from "./configuration/reasons/reasons.component";
import { CorrectiveActionsComponent } from "./configuration/corrective-actions/corrective-actions.component";

import { CheckboxModule } from "primeng/checkbox";
import { RemoveStaticStringPipe } from "./remove-static-string.pipe";
import { ManagerSignoffComponent } from "./manager-signoff/manager-signoff.component";
import { ReminderComponent } from "./configuration/reminder/reminder.component";
import { ChangeGuard } from "src/app/common/Guard/pageChangeGuard";
import { ExcelService } from "./common/services/excel.service";
import { NotificationComponent } from "./notifications/notification/notification.component";
import { CustomDatePipe } from "./common/pipes/custom-date-pipe.pipe";
import { ManagerSignOfflistComponent } from "./manager-sign-offlist/manager-sign-offlist.component";
import { MomentPipe } from "./common/pipes/moment.pipe";
import { timebeforePipe } from "./common/pipes/timebefore.pipe";
import { TemplateComponent } from "./template/template.component";
import { TemplateModuleBuilderComponent } from "./template-module-builder/template-module-builder.component";
import { DigitDirective } from "./common/directives/digit.directive";
import { YesNoPipe } from "./common/pipes/yes-no.pipe";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { OpenAlarmComponent } from "./widgets/open-alarm/open-alarm.component";
import { ModuleCompletionComponent } from "./widgets/module-completion/module-completion/module-completion.component";
import { ResolvedAlarmComponent } from "./widgets/resolved-alarm/resolved-alarm.component";
import { RecurringAlarmComponent } from "./widgets/recurring-alarm/recurring-alarm.component";
import { RefrigerationModuleDisableComponent } from "./widgets/refrigeration-module-disable/refrigeration-module-disable.component";
import { ReportsComponent } from "./reports/reports.component";
import { ModuleAverageCompletionComponent } from "./widgets/module-average-completion/module-average-completion.component";
import { EditUserProfileComponent } from "./edit-user-profile/edit-user-profile.component";
import { BarChartComponent } from "./common/components/charts/bar/bar-chart.component";
import { SparkLineChartComponent } from "./common/components/charts/sparkline/sparkline-chart.component";
import { StackedBarChartComponent } from "./common/components/charts/stacked-bar/stacked-bar-chart.component";
import { HighestAverageBarChartComponent } from "./common/components/charts/highest-averge-completion-bar-chart/highest-average-completion-bar-chart.component";
import { RecurringBarChartComponent } from "./common/components/charts/recurring-bar-chart/recurring-bar-chart.component";
import { DynamicTableColumnPipe } from "./common/pipes/dynamic-table-column-pipe";
import { ReminderTooltipFormat } from "./common/pipes/reminderTooltipformat.pipe";
import { PaginationModule } from "./common/components/pagination/pagination.module";
import {
  MenuDisplayOnRolesBasedPipe,
  MenuHideForUnauthorisedUserPipe,
} from "./common/pipes/show-hide-menu-rolebased.pipe";
import { UserModule } from "./user/user.module";
import { UserManagementComponent } from "./user-management/user-management.component";
import { DownloadReportComponent } from "./downloadReport/download-report.component";
import { AllReportsComponent } from "./reports/temperature-reports/all-reports.component";
import { ReportsMainComponent } from "./reports/reports-main/reports-main.component";
import { OverduemodulesComponent } from "./task-management/charts/overduemodules/overduemodules.component";
import { OverduesitesComponent } from "./task-management/charts/overduesites/overduesites.component";
import { SitecompareavgComponent } from "./task-management/charts/sitecompareavg/sitecompareavg.component";
import { SiteoverduemoduleComponent } from "./task-management/charts/siteoverduemodule/siteoverduemodule.component";
import { SitetaskstatusComponent } from "./task-management/charts/sitetaskstatus/sitetaskstatus.component";
import { ComplainceComponent } from "./task-management/complaince/complaince.component";
import { PStepperComponent } from "./task-management/p-stepper/p-stepper.component";
import { SitetasklistComponent } from "./task-management/sitetasklist/sitetasklist.component";
import { TaskdashboardComponent } from "./task-management/taskdashboard/taskdashboard.component";
import { TasklistComponent } from "./task-management/tasklist/tasklist.component";
import { ReactiveFormsModule } from "@angular/forms";
import { WeekdatesService } from "./task-management/services/weekdates.service";
import { TaskmgmtComponent } from "./task-management/taskmgmt/taskmgmt.component";
import { TaskgridviewComponent } from "./task-management/taskgridview/taskgridview.component";
import { WorsecabnetsComponent } from "./refrigeration-dashboard/allcharts/worsecabnets/worsecabnets.component";
import { AvgassetscoreComponent } from "./refrigeration-dashboard/allcharts/avgassetscore/avgassetscore.component";
import { CompareequipmentComponent } from "./refrigeration-dashboard/allcharts/compareequipment/compareequipment.component";
import { ImprovedsitesComponent } from "./refrigeration-dashboard/allcharts/improvedsites/improvedsites.component";
import { DocumentManagementComponent } from "./document-management/document-management/document-management.component";
import { DocumentDownloadComponent } from "./document-management/document-download/document-download.component";
import { CommManagementComponent } from "./communication-management/comm-management/comm-management.component";
import { CommDashboardComponent } from "./communication-management/comm-dashboard/comm-dashboard.component";
import { CommtasklistComponent } from "./communication-management/commtasklist/commtasklist.component";
import { CommstepperComponent } from "./communication-management/commstepper/commstepper.component";
import { CommstatusComponent } from "./communication-management/commstatus/commstatus.component";
import { ComparisionchartComponent } from "./communication-management/comparisionchart/comparisionchart.component";
import { AuthProcessComponent } from "./common/auth-process/auth-process.component";
import { CookieService } from "ngx-cookie-service";
import { CommonErrorComponent } from "./common/components/common-error/common-error.component";
import { ClickOutsideDirective } from "./common/directives/click-outsideElement.directive";
import { SensorTemplateComponent } from "./configuration/sensor-template/sensor-template.component";
import { CommonErrorCustomComponent } from "./common/components/common-error-custom/common-error-custom.component";
import { CommissioningComponent } from "./commissioning/commissioning.component";
import { EnterSerialNumberComponent } from "./enter-serial-number/enter-serial-number.component";
import { CloudConnectorComponent } from "./cloud-connector/cloud-connector.component";
import { CommissioningMainComponent } from "./commissioning-main/commissioning-main.component";
import { AddSensorComponent } from "./add-sensor/add-sensor.component";
import { DeleteGatewayComponent } from "./delete-gateway/delete-gateway.component";
import { DeleteSensorComponent } from "./delete-sensor/delete-sensor.component";
import { MyDashboardComponent } from "./my-dashboard/my-dashboard.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CO2ChartComponent } from "./common/components/charts/CO2Sensor/co2-chart.component";
import { MobileUIGraphModule } from "./common/components/charts/CO2Sensor/mobileui-co2humidity/mobileui-module";
import {
  PaginatePipe,
  SearchFilterPipe,
} from "./common/pipes/search-filter.pipe";
import { PipeMonitoringChartComponent } from "./common/components/charts/pipemonitoring/pipe-monitoring-chart.component";
import { SensorVerificationComponent } from "./sensorverification/sensorverification.component";

export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use(getUserLocale());
}

@NgModule({
  declarations: [
    AppComponent,
    ClientComponent,
    HeaderComponent,
    SideMenuComponent,
    NotificationPanelComponent,
    ModuleBuilderComponent,
    ConfigurationComponent,
    SiteComponent,
    AddClientComponent,
    AddSiteComponent,
    SuppliersComponent,
    ProductsComponent,
    AreasComponent,
    DeliveryTypesComponent,
    ConfigurationDetailsComponent,
    TrueContainerComponent,
    FalseContainerComponent,
    LoginComponent,
    LoginCallbackComponent,
    LoopContainerComponent,
    TranslatePipe,
    GrdFilterPipe,
    DelimiterPipe,
    DateFormatePipe,
    DateFormatMonthNamePipe,
    TimeFormat12hrPipe,
    getColorGreenColorOnSensorReading,
    getColorRedColorOnSensorReading,
    InstructionFormComponent,
    UserDashboardComponent,
    ModuleLogListComponent,
    ModuleLogDetailsComponent,
    NoAccessPageComponent,
    RefrigerationDataComponent,
    RefrigerationListComponent,
    CheckPasswordDirective,
    OnlyNumber,
    TempNumberOnlyDirective,
    ClickOutsideDirective,
    ReasonsComponent,
    CorrectiveActionsComponent,
    RemoveStaticStringPipe,
    ManagerSignoffComponent,
    ReminderComponent,
    NotificationComponent,
    CustomDatePipe,
    ManagerSignOfflistComponent,
    MomentPipe,
    timebeforePipe,
    TemplateComponent,
    TemplateModuleBuilderComponent,
    DigitDirective,
    YesNoPipe,
    DashboardComponent,
    OpenAlarmComponent,
    ModuleCompletionComponent,
    ResolvedAlarmComponent,
    RecurringAlarmComponent,
    RefrigerationModuleDisableComponent,
    ReportsComponent,
    ModuleAverageCompletionComponent,
    EditUserProfileComponent,
    BarChartComponent,
    SparkLineChartComponent,
    StackedBarChartComponent,
    HighestAverageBarChartComponent,
    RecurringBarChartComponent,
    DynamicTableColumnPipe,
    ReminderTooltipFormat,
    MenuDisplayOnRolesBasedPipe,
    PaginatePipe,
    UserManagementComponent,
    DownloadReportComponent,
    AllReportsComponent,
    ReportsMainComponent,
    OverduemodulesComponent,
    OverduesitesComponent,
    SitecompareavgComponent,
    SiteoverduemoduleComponent,
    SitetaskstatusComponent,
    ComplainceComponent,
    PStepperComponent,
    SitetasklistComponent,
    TaskdashboardComponent,
    TasklistComponent,
    TaskmgmtComponent,
    TaskgridviewComponent,
    WorsecabnetsComponent,
    AvgassetscoreComponent,
    CompareequipmentComponent,
    ImprovedsitesComponent,
    DocumentManagementComponent,
    DocumentDownloadComponent,
    CommManagementComponent,
    CommDashboardComponent,
    CommtasklistComponent,
    CommstepperComponent,
    CommstatusComponent,
    ComparisionchartComponent,
    MenuHideForUnauthorisedUserPipe,
    AuthProcessComponent,
    CommonErrorComponent,
    SensorTemplateComponent,
    CommonErrorCustomComponent,
    CommissioningComponent,
    EnterSerialNumberComponent,
    CloudConnectorComponent,
    CommissioningMainComponent,
    AddSensorComponent,
    DeleteGatewayComponent,
    DeleteSensorComponent,
    MyDashboardComponent,
    CO2ChartComponent,
    SearchFilterPipe,
    PipeMonitoringChartComponent,
    SensorVerificationComponent,
  ],
  imports: [
    StepsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PanelModule,
    TableModule,
    DropdownModule,
    TabViewModule,
    MenuModule,
    FormsModule,
    ColorPickerModule,
    FileUploadModule,
    DialogModule,
    FieldsetModule,
    ToastModule,
    RadioButtonModule,
    PaginatorModule,
    OverlayPanelModule,
    MultiSelectModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    DndListModule,
    ScrollPanelModule,
    ListboxModule,
    CalendarModule,
    TooltipModule,
    CheckboxModule,
    AccordionModule,
    PaginationModule,
    UserModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    DragDropModule,
    MobileUIGraphModule,
  ],
  providers: [
    ChangeGuard,
    ExcelService,
    MessageService,
    WeekdatesService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
  ],
})
export class CommonSiteModule {}

@NgModule({
  imports: [AppRoutingModule, HttpClientModule, CommonSiteModule],
  providers: [
    TranslateService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
