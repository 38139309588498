<app-header [pageTitle]="Title"></app-header>

<div class="wrapper">
  <div class="row">
    <div class="col-sm-12 breadcrumbSection">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/site', this.clientID, premiseName]">
              {{ premiseName }}
            </a>
          </li>
          <li class="breadcrumb-item">
            <a
              [routerLink]="[
                '/refrigeration',
                this.clientID,
                this.SiteID,
                premiseName
              ]"
            >
              {{ "Sensors" | translate: "Refrigeration" }}
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ chartTitle }}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 flex flex-space_between m-24">
      <div
        class="cabinet_input_box_div_css"
        *ngIf="chartTitle && chartTitle.length > 0 && !showEditableField"
      >
        <h4 class="cabinet_title">{{ chartTitle }}</h4>
        <span (click)="showEditableField = !showEditableField">
          <span class="cabinet_title_icon">
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                fill="#00539E"
              />
            </svg>
          </span>
        </span>

        <div
          class="font-size12 font-weight600 text-uppercase last_communicationTime"
        >
          <span>{{ "lastSeen" | translate: "Site" }} -</span>
          <span>
            {{ this.sensorlastcommtimestamp }}
          </span>
        </div>
      </div>
      <div class="cabinet_input_box_div_css" *ngIf="showEditableField">
        <input
          class="cabinet_input_box"
          type="text"
          name="cabinetName"
          [(ngModel)]="cabinetName"
          maxlength="200"
        />
        <span>
          <span>
            <i
              class="fa fa-check cabinet_title_icon"
              (click)="saveCabinetName()"
            ></i>
          </span>
          <span>
            <i
              class="fa fa-close cabinet_title_icon"
              (click)="showEditableField = !showEditableField"
            ></i>
          </span>
        </span>
      </div>

      <div class="flex aic">
        <span title="{{ 'refresh' | translate: 'Common' }}">
          <svg (click)="onrefresh()" class="svg-icon-refresh cp">
            <use
              href="../../../assets/icons-svg/sprite-svg-icons.svg#refresh"
              xlink:href="../../../assets/icons-svg/sprite-svg-icons.svg#refresh"
            ></use>
          </svg>
        </span>
        <button
          *ngIf="isCalibrated"
          [disabled]="!isCalibrated"
          class="btn btn-secondary btncust"
          (click)="downloadCalibrationCert()"
        >
          {{ "calibrationCerts" | translate: "Refrigeration" }}
        </button>
        <div
          *ngIf="!isCalibrated"
          [pTooltip]="toolTipMessage"
          tooltipPosition="top"
          class="calibrationToolTipMsg"
        >
          <button [disabled]="!isCalibrated" class="btn btn-secondary btncust">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.72667 8.39333L5.66667 9.33333L9 6L5.66667 2.66667L4.72667 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72667 8.39333ZM10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
                fill="white"
              />
            </svg>
            {{ "calibrationCerts" | translate: "Refrigeration" }}
          </button>
        </div>
        <button class="btn btn-primary btncust" (click)="ExportToExcel()">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.72667 8.39333L5.66667 9.33333L9 6L5.66667 2.66667L4.72667 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72667 8.39333ZM10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
              fill="white"
            />
          </svg>
          {{ "exportTemperature" | translate: "Refrigeration" }}
        </button>
      </div>
    </div>

    <div class="row cabinet_details_section m-0">
      <div class="col-sm-4">
        <div class="row">
          <div
            *ngIf="roleAccess && (networkStrengthValue || batteryStrengthValue)"
            class="col-md-12 col-lg-12 col-sm-12 col-xs-12"
          >
            <div class="networbattery_row">
              <div *ngIf="networkStrengthValue" class="networbattery_div">
                <div class="networkbattery_image_div flex-content-center">
                  <img class="good_signal_image" [src]="networkIcon" />
                </div>
                <div class="networbattery_div_text_width">
                  <p class="networbattery_div_text_width_label">
                    {{ networkStrengthValue }}
                  </p>
                  <p class="networbattery_div_text_width_color">
                    {{ "networkStrength" | translate: "Refrigeration" }}
                  </p>
                </div>
              </div>
              <div *ngIf="batteryStrengthValue" class="networbattery_div">
                <div class="networkbattery_image_div flex-content-center">
                  <img
                    class="good_strength_battery_image"
                    [src]="batteryIcon"
                  />
                </div>
                <div class="networbattery_div_text_width">
                  <p class="networbattery_div_text_width_label">
                    {{ batteryStrengthValue }}
                  </p>
                  <p class="networbattery_div_text_width_color">
                    {{ "batteryLevel" | translate: "Refrigeration" }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="alram_config">
              <h6 class="alram_config_header alarm-header">
                {{ "alarmConfig" | translate: "Client" }}
              </h6>
              <div
                class="text-right asset-score-text"
                *ngIf="assetScore != null"
              >
                <span class="asset-score">
                  {{ assetScore }}
                  {{ "assetScore" | translate: "Refrigeration" }}
                </span>
              </div>
              <div
                style="padding: 15px"
                *ngIf="alarmConfiguration?.length === 0"
              >
                <p>{{ "noAlarmConfig" | translate: "Refrigeration" }}</p>
              </div>
              <div class="alarm_config_div alarm_config_override">
                <div class="flex p-16">
                  <label class="switch">
                    <input
                      #toggleRefrigeration
                      type="checkbox"
                      [checked]="isAlarmMuteEnabled"
                      (click)="showDatePicker($event)"
                    />
                    <span class="slider round"></span>
                  </label>
                  <span style="position: relative; bottom: 4px">
                    <p
                      [ngClass]="{
                        disabled_message: !isAlarmMuteEnabled,
                        success_message: isAlarmMuteEnabled
                      }"
                    >
                      {{
                        isAlarmMuteEnabled
                          ? ("enabled" | translate: "Refrigeration")
                          : ("disabled" | translate: "Refrigeration")
                      }}
                    </p>
                  </span>
                </div>
                <p *ngIf="!isAlarmMuteEnabled" class="pb-2">
                  {{ "alarmAreDisabled" | translate: "Refrigeration" }}
                  {{ alarmMuteEndTime }}.
                </p>
              </div>
              <div class="calendar_in_openmode" *ngIf="viewdatepicker">
                <h6 class="calender_open-title flex flex-space_between">
                  {{ "selectDateTime" | translate: "Refrigeration" }}
                  <span class="cp">
                    <i
                      class="fa fa-close calender_close_Btn"
                      (click)="closeDatePicker()"
                    ></i>
                  </span>
                </h6>
                <p style="color: lightgray">
                  {{ "selectDateMsg" | translate: "Refrigeration" }}
                </p>
                <p-calendar
                  placeholder="{{ 'selectdate' | translate: 'Refrigeration' }}"
                  dateFormat="yy-mm-dd"
                  [(ngModel)]="toDate"
                  [minDate]="toMinDate"
                  [inline]="true"
                  [showTime]="true"
                  (onSelect)="toDatePicker(toDate)"
                ></p-calendar>
                <p
                  *ngIf="this.errorMsg.length > 0"
                  class="error-message pl-2 pb-2"
                >
                  {{ this.errorMsg }}
                </p>
                <button
                  class="calender_ok__btn"
                  type="button"
                  class="btn btn-default"
                  data-toggle="modal"
                  data-target="#confirmationModal"
                  [disabled]="errorMsg.length > 0"
                >
                  {{ "ok" | translate: "Common" }}
                </button>
              </div>
              <div [ngClass]="{ overlay: !isAlarmMuteEnabled }">
                <div *ngFor="let item of alarmConfiguration">
                  <div class="flex aic flex-space_between p-16">
                    <p class="alarm_config_div_title">
                      {{ item.description }}
                    </p>
                    <div style="margin-right: 20px">
                      <span
                        *ngIf="
                          showEditOption &&
                          !flagDoorandWaterSensor &&
                          isAlarmMuteEnabled
                        "
                        class="alram_config_value alarm_config-changeMenu inl-block"
                        data-toggle="modal"
                        data-target="#editAlarmConfigModal"
                        (click)="setEditAlarmModalData(item)"
                      >
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                            fill="#00539E"
                          />
                        </svg>
                      </span>
                      <span
                        *ngIf="
                          showEditOption &&
                          !flagDoorandWaterSensor &&
                          (item.maxSensorValue !== 'NA' ||
                            item.minSensorValue !== 'NA') &&
                          isAlarmMuteEnabled &&
                          !this.currentSensorTemplate?.IsSensorTemplateDeleted
                        "
                        class="alarm_config_delete cp"
                      >
                        <span
                          data-toggle="modal"
                          data-target="#deleteAlarmConfigModal"
                          title="{{ 'delete' | translate: 'Common' }}"
                          (click)="getConfigDetails(item)"
                        >
                          <svg
                            width="14"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                              fill="#00539E"
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="alarm_config_div pb-16">
                    <div
                      *ngIf="item.minSensorValue"
                      class="alarm_config_spacing"
                    >
                      <p class="alram_config_value">
                        {{ item.minSensorValue }}
                      </p>
                      <p class="alarm_config_dataKey">
                        <span *ngIf="item.minSensorValue.indexOf('°C') > 1">
                          {{ "minTemp" | translate: "Refrigeration" }}
                        </span>
                        <span *ngIf="item.minSensorValue.indexOf('°F') > 1">
                          {{ "minTemp" | translate: "Refrigeration" }}
                        </span>
                        <span *ngIf="item.minSensorValue.indexOf('%') > 0">
                          {{ "minHumidityText" | translate: "Common" }}
                        </span>
                        <span *ngIf="item.minSensorValue.indexOf('ppm') > 0">
                          {{ "minCO2Text" | translate: "Common" }}
                        </span>
                        <span *ngIf="item.minSensorValue.indexOf('hPa') > 0">
                          {{ "minPressureText" | translate: "Common" }}
                        </span>
                      </p>
                    </div>
                    <div
                      *ngIf="item.maxSensorValue"
                      class="alarm_config_spacing"
                    >
                      <p class="alram_config_value">
                        {{ item.maxSensorValue }}
                      </p>
                      <p class="alarm_config_dataKey">
                        <span *ngIf="item.maxSensorValue.indexOf('°C') > 1">
                          {{ "maxTemp" | translate: "Refrigeration" }}
                        </span>
                        <span *ngIf="item.maxSensorValue.indexOf('°F') > 1">
                          {{ "maxTemp" | translate: "Refrigeration" }}
                        </span>
                        <span *ngIf="item.maxSensorValue.indexOf('%') > 0">
                          {{ "maxHumidityText" | translate: "Common" }}
                        </span>
                        <span *ngIf="item.maxSensorValue.indexOf('ppm') > 0">
                          {{ "maxCO2Text" | translate: "Common" }}
                        </span>
                        <span *ngIf="item.maxSensorValue.indexOf('hPa') > 0">
                          {{ "maxPressureText" | translate: "Common" }}
                        </span>
                      </p>
                    </div>
                    <div class="alarm_config_spacing">
                      <p class="alram_config_value">{{ item.alarmdelay }}</p>
                      <p class="alarm_config_dataKey">
                        {{ "alarmdelay" | translate: "Refrigeration" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div
              class="last_alarms_div"
              *ngIf="notificationListofCabinet?.length === 0"
            >
              <h6 class="last_alarms_title_header">
                {{ "last5alarms" | translate: "Refrigeration" }}
              </h6>
              <p>{{ "noNotification" | translate: "Refrigeration" }}</p>
            </div>
            <div
              *ngIf="notificationListofCabinet?.length > 0"
              class="last_alarms_div"
            >
              <h6 class="last_alarms_title_header">
                {{ "last5alarms" | translate: "Refrigeration" }}
              </h6>
              <div
                *ngFor="
                  let item of notificationListofCabinet;
                  let i = index;
                  let first = first
                "
              >
                <div class="last_alarm_header" *ngIf="i === 0">
                  <div class="c1">
                    {{ "date" | translate: "Refrigeration" }}
                  </div>
                  <div class="c1">
                    {{ "time" | translate: "Refrigeration" }}
                  </div>
                  <div class="c1">
                    {{ "sensor" | translate: "Refrigeration" }}
                  </div>
                  <div class="c1">
                    {{ "status" | translate: "Common" }}
                  </div>
                </div>
                <div class="last_alarm_row">
                  <div class="c1">
                    {{ item.RaisedOn | date_format_monthName }}
                  </div>
                  <div class="c1">
                    {{ item.RaisedOn | time_format_12hr }}
                  </div>
                  <div class="c1">
                    {{ item.SensorDescription }}
                  </div>
                  <div class="c1">
                    {{
                      item.IsDismissed
                        ? ("resolved" | translate: "Refrigeration")
                        : ("open" | translate: "Refrigeration")
                    }}
                  </div>
                </div>
              </div>
              <div class="last_alarms_footer">
                <p
                  class="last_alarms_text"
                  routerLink="/site/{{ SiteID }}/refrigeration/alarms"
                >
                  {{ "viewAllAlrams" | translate: "Refrigeration" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="custom_calender_position">
          <ul class="options">
            <li
              [ngClass]="{ active: selectedItem == item }"
              (click)="listClick(item)"
              *ngFor="let item of groups"
            >
              {{ item | translate: "Refrigeration" }}
            </li>
          </ul>
          <div class="custom" *ngIf="showCustom">
            <p-calendar
              [(ngModel)]="FromDate"
              [maxDate]="maxDateValue"
              placeholder="{{ 'FromDate' | translate: 'Refrigeration' }}"
            ></p-calendar>
            <p-calendar
              [(ngModel)]="ToDate"
              [minDate]="FromDate"
              [maxDate]="maxDateValue"
              placeholder="{{ 'ToDate' | translate: 'Refrigeration' }}"
            ></p-calendar>
            <button
              type="button"
              class="btn btn-primary btncust"
              (click)="CustomChartData()"
            >
              {{ "Go" | translate: "Refrigeration" }}
            </button>
          </div>
        </div>
        <ng-container *ngIf="deviceList && deviceList.length > 0">
          <ng-container *ngIf="!CO2SensorFlag && !pipeSensorFlag">
            <div *ngFor="let item of deviceList; let i = index" class="row">
              <div class="col-sm-12">
                <div class="chartHeader {{ item.devicetype }}">
                  <div>
                    <p
                      *ngIf="item.devicetype === 'Door'"
                      class="chartHeader_text"
                    >
                      {{ "doorProximity" | translate: "Refrigeration" }}
                    </p>
                    <p
                      *ngIf="item.devicetype === 'Water'"
                      class="chartHeader_text"
                    >
                      {{ "water" | translate: "Commissioning" }}
                    </p>
                    <p class="chartHeader_subtext">
                      {{ item?.description }}
                    </p>
                  </div>
                  <div *ngIf="item.devicetype === 'Air Temperature'">
                    <p class="chartHeader_text">
                      {{ "airTemperature" | translate: "Refrigeration" }}
                    </p>
                    <p class="chartHeader_subtext">
                      {{ cabinetName }}
                    </p>
                  </div>
                  <div class="chartDiv" id="container{{ i }}"></div>
                </div>
              </div>
            </div>
          </ng-container>

          <div *ngIf="CO2SensorFlag">
            <co2-sensor-chart [chartdata]="CO2chartData"></co2-sensor-chart>
          </div>

          <div *ngIf="pipeSensorFlag">
            <pipe-monitoring-chart
              [graphthreshold]="pipethreshold"
              [chartdata]="seriesDataFirstSensor"
            ></pipe-monitoring-chart>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="confirmationModal"
    data-backdrop="true"
    data-keyboard="false"
    style="z-index: 9999999"
  >
    <div class="modal-dialog muteAlarmModal" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ "confirm" | translate: "Refrigeration" }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            #closeBtn
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body date-picker">
          <p>
            {{
              !isAlarmMuteEnabled
                ? ("areYouSureTodisable" | translate: "Refrigeration")
                : ("areYouSureToenable" | translate: "Refrigeration")
            }}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">
            {{ "cancel" | translate: "Common" }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="saveMuteAlarmSettings('ok')"
          >
            {{ "ok" | translate: "Common" }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="editAlarmConfigModal"
    data-backdrop="true"
    data-keyboard="false"
  >
    <div class="modal-dialog muteAlarmModal" role="document">
      <div class="modal-content">
        <form #editConfig="ngForm">
          <div class="modal-header">
            <p>{{ "alarmConfig" | translate: "Client" }}</p>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              #closeBtn
              (click)="ondismissSelectTemplateName()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" *ngIf="!flagDoorandWaterSensor">
            <div class="row">
              <div class="col-md-12">
                <div class="body-section">
                  <div>
                    <label>
                      <div
                        class="config-form-label-header text-uppercase font-weight700"
                      >
                        {{ "sensorTemplate" | translate: "Refrigeration" }}
                      </div>
                      <p-dropdown
                        class="dropdown d-width300"
                        [options]="templates"
                        filter="true"
                        [(ngModel)]="selectedTemplate"
                        optionLabel="Name"
                        name="sensortemplate"
                        (onChange)="onChange(true)"
                        placeholder="{{
                          'select' | translate: 'moduleBuilder'
                        }} {{ 'templateName' | translate: 'Common' }}"
                      ></p-dropdown>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                (!currentSensorTemplate?.IsSensorTemplateDeleted ||
                  this.selectedTemplate) &&
                this.showMinMax
              "
            >
              <div class="col-md-12">
                <div class="form-group">
                  <p class="info-section">
                    {{ "alarmConfigMessage" | translate: "Refrigeration" }}
                  </p>

                  <div class="body-section">
                    <div>
                      <label>
                        <div class="config-form-label-header">
                          {{ "maximum" | translate: "Refrigeration" }}
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          [(ngModel)]="maximumSensorReadingValue"
                          name="maxTemperature"
                          step="1"
                          (keyup)="
                            validateMinMaxValues(
                              maximumSensorReadingValue,
                              'max'
                            )
                          "
                          (keypress)="validateNumber($event)"
                          required
                          [disabled]="disabledConfig"
                        />
                        <div
                          *ngIf="isFieldInvalid('maxTemperature')"
                          class="invalid-field-text"
                        >
                          <i
                            class="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {{ "mandatory" | translate: "Refrigeration" }}
                        </div>
                      </label>
                    </div>
                    <div *ngIf="!this.checkPipeSensorPresent()">
                      <label>
                        <div class="config-form-label-header">
                          {{ "minimum" | translate: "Refrigeration" }}
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          [(ngModel)]="minimumSensorReadingValue"
                          step="1"
                          (keyup)="
                            validateMinMaxValues(
                              minimumSensorReadingValue,
                              'min'
                            )
                          "
                          (keypress)="validateNumber($event)"
                          name="minTemperature"
                          required
                          [disabled]="disabledConfig"
                        />
                        <div
                          *ngIf="isFieldInvalid('minTemperature')"
                          class="invalid-field-text"
                        >
                          <i
                            class="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {{ "mandatory" | translate: "Refrigeration" }}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                !currentSensorTemplate?.IsSensorTemplateDeleted ||
                this.selectedTemplate
              "
              class="row"
            >
              <div class="col-md-12">
                <div class="form-group">
                  <p class="info-section">
                    {{ "alarmConfigMessage2" | translate: "Refrigeration" }}
                  </p>
                  <div class="body-section">
                    <label>
                      <div class="config-form-label-header">
                        {{ "alarmdelay" | translate: "Refrigeration" }} (
                        <span *ngIf="!this.checkPipeSensorPresent()">
                          {{ "minutes" | translate: "Refrigeration" }}
                        </span>
                        <span *ngIf="this.checkPipeSensorPresent()">
                          {{ "indays" | translate: "Refrigeration" }}
                        </span>
                        )
                      </div>
                      <input
                        type="number"
                        class="form-control"
                        [(ngModel)]="delay"
                        (keyup)="checkforDecimal(delay)"
                        name="delay"
                        [disabled]="disabledConfig"
                        required
                      />
                      <div
                        *ngIf="isFieldInvalid('delay')"
                        class="invalid-field-text"
                      >
                        <i
                          class="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {{ "mandatory" | translate: "Refrigeration" }}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <p *ngIf="this.errorMsg.length > 0" class="error-message pl-2 pb-2">
              {{ this.errorMsg }}
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              (click)="ondismissSelectTemplateName()"
              class="btn btn-default"
              data-dismiss="modal"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="saveAlarmConfigSettings()"
            >
              {{ "save" | translate: "Common" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="deleteAlarmConfigModal"
    data-backdrop="true"
    data-keyboard="false"
  >
    <div class="modal-dialog muteAlarmModal" role="document">
      <div class="modal-content">
        <form #editConfig="ngForm">
          <div class="modal-header">
            {{ "deleteConfiguration" | translate: "Refrigeration" }}
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              #closeBtn
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="warningMsg" style="font-weight: 700">
              {{ "deleteConfigWarningMsg" | translate: "Refrigeration" }}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="deleteAlramConfig()"
            >
              {{ "ok" | translate: "Common" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="changetemplateModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "changeTemplate" | translate: "Refrigeration" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "areYouSure" | translate: "Refrigeration" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          data-dismiss="modal"
          (click)="onNoConfirm()"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          data-dismiss="modal"
          (click)="onConfirm()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div [ngClass]="{ 'fs-overlay': viewdatepicker }"></div>
