import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { MessageService } from "primeng/api";
import { TranslateService } from "../../common/services/translate.service";
import { HttpService } from "../../common/services/http.service";
import { PennService } from "../../common/penn.service";
import { ToastMsg } from "../../common/constants/toastmsg.constant";
import { RestApi } from "../../common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import {
  AddReasons,
  ApiListResponse,
} from "src/app/common/models/configuration.model";
import { Constant } from "src/app/common/constants/constant";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-reasons",
  templateUrl: "./reasons.component.html",
  styleUrls: ["./reasons.component.scss"],
})
export class ReasonsComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("deletecloseBtn", { static: true }) deletecloseBtn: ElementRef;
  addResons: AddReasons;
  oldAddReasons: AddReasons = <AddReasons>{};
  reasonList;
  reasonlistcols = [
    { field: "ReasonText", header: this.translate.data.Reasons.reasons },
  ];
  modalTitle;
  defaultselect;
  routeUrl;
  createdDate: string;
  clientID: number;
  clientName: string;
  siteName: string;
  userProfile;
  reasonID;
  siteID: number;
  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private route: ActivatedRoute,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.addResons = <AddReasons>{};
    this.routeUrl = this.storage.get("routename");
    if (this.routeUrl === "client") {
      this.clientID = Number(this.route.snapshot.paramMap.get("id"));
      this.clientName = this.route.snapshot.paramMap.get("name");
    } else if (this.routeUrl === "site") {
      //this.clientID = this.pennService.getStoredData("clientID");
      this.clientID = Number(this.route.snapshot.paramMap.get("clientid"));
      this.siteID = Number(this.route.snapshot.paramMap.get("id"));
      this.siteName = this.route.snapshot.paramMap.get("name");
    }
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.getReasons();
    this.pennService.showSubNav();
  }

  getReasons() {
    this.httpService
      .get(RestApi.get_all_reasons + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddReasons>) => {
        if (res.IsSuccess) {
          this.reasonList = res.Data;
        }
      });
  }

  showReasonModal(val: string, data) {
    if (val == Constant.CommandType.add) {
      this.modalTitle = this.translate.data.Common.add;
      this.addResons = <AddReasons>{};
      this.reasonID = 0;
    } else if (val == Constant.CommandType.edit) {
      this.modalTitle = this.translate.data.Common.edit;
      this.addResons = Object.assign({}, data);
      this.oldAddReasons = Object.assign({}, data);
      this.reasonID = data.ReasonID;
    } else if (val == Constant.CommandType.delete) {
      this.reasonID = data.ReasonID;
    }
  }

  saveReasons() {
    this.addResons.ReasonID = this.reasonID;
    this.addResons.ClientID = this.clientID;
    this.addResons.CreatedOn = this.createdDate;
    this.addResons.CreatedBy = this.userProfile.userName;
    this.addResons.CreatedByID = this.userProfile.userID;
    this.addResons.ModifiedOn = this.createdDate;
    this.addResons.ModifiedBy = this.userProfile.userName;
    this.addResons.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.save_reasons, this.addResons)
      .subscribe((res: any) => {
        this.addcloseBtn.nativeElement.click();
        if (res.IsSuccess) {
          let msg =
            this.reasonID > 0
              ? this.translate.data.Popuop_Msg.reasonupdatedsuccessfully
              : this.translate.data.Popuop_Msg.reasonaddedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });
          this.getReasons();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteReason() {
    this.httpService
      .delete(RestApi.delete_reasons, this.reasonID)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.reasondeletedsuccessfully,
          });
          this.deletecloseBtn.nativeElement.click();
          this.getReasons();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  Validate() {
    if (this.reasonID == 0) {
      return !this.addResons.ReasonText;
    } else if (this.reasonID > 0) {
      return (
        !this.addResons.ReasonText ||
        this.addResons.ReasonText == this.oldAddReasons.ReasonText
      );
    }
  }
  ngOnDestroy() {
    this.pennService.hideSubNav();
  }
}
