<app-header [pageTitle]="Title"></app-header>
<div class="dndPlaceholder col-md-12" #placeholder></div>
<div class="wrapper">
  <div class="row sub-header mtop-32">
    <div class="col-sm-6 breadcrumbSection">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <a
              [routerLink]="[
                '/configuration/details',
                this.clientID,
                this.clientName
              ]"
              (click)="goToConfig()"
            >
              Configuration
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ moduleData?.moduleName }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 m-24">
      <div class="page-title">{{ this.Title }}</div>
    </div>
  </div>
  <div class="padding20 white-bg">
    <div class="flex flex-space_between">
      <div class="sideMenu-width">
        <div class="component-list">
          <h4>{{ "instructionlist" | translate: "moduleInstructionType" }}</h4>
          <ul class="fields">
            <li
              *ngFor="let template of nestedList.templates"
              [dndType]="template.uiType"
              [dndDraggable]="{ effectAllowed: 'copy' }"
              [dndObject]="template"
              class="field pointer shadow-bg flex aic"
            >
              <div class="flex aic">
                <div class="icons_div">
                  <img [src]="getSVGImageUrl(template.icon)" />
                </div>
                <span>{{ template.instructionType }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="mainContentMenu-width">
        <div class="row">
          <div
            *ngFor="let dropzone of nestedList.dropzones; let i = index"
            class="col-md-12"
          >
            <div class="dropzone box box-yellow">
              <div
                [dndList]="{ allowedTypes: ['item', 'container'] }"
                (dndInserted)="checkDroppedItem($event, dropzone)"
                [dndModel]="dropzone"
                [dndPlaceholder]="placeholder"
                class="col-md-12 module-content"
              >
                <div
                  [ngClass]="{ last: isLast }"
                  *ngFor="
                    let item of dropzone;
                    last as isLast;
                    let instructionIndex = index
                  "
                >
                  <app-instruction-form
                    [uiType]="item.uiType"
                    [instructionType]="item.instructionType"
                    [item]="item"
                    [dropzone]="dropzone"
                    [instructionIndex]="instructionIndex"
                    [fullList]="nestedList.dropzones"
                    (setInstructionName)="
                      triggerUpdateforNewInstructionName($event)
                    "
                  ></app-instruction-form>

                  <div *ngIf="!isLast" class="connecting-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                    >
                      <path
                        d="M19.5 15L18.09 13.59L13.5 18.17V2H11.5V18.17L6.91 13.58L5.5 15L12.5 22L19.5 15Z"
                        fill="#868A8F"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-align-center m-24">
          <div class="col-sm-12 module_builder_save">
            <button
              class="btn btn-secondary btncust"
              (click)="clearInstruction()"
            >
              {{ "clear" | translate: "Common" }}
            </button>
            <button
              class="btn btn-primary btncust"
              (click)="saveModule('save')"
            >
              {{ "save" | translate: "Common" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
