import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { DatePipe } from "@angular/common";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { WeekdatesService } from "src/app/task-management/services/weekdates.service";
import * as Highcharts from "highcharts";
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { TranslateService } from "src/app/common/services/translate.service";

@Component({
  selector: "app-overduesites",
  templateUrl: "./overduesites.component.html",
  styleUrls: ["./overduesites.component.scss"],
})
export class OverduesitesComponent implements OnInit {
  @Input() reload: boolean;
  form = {
    startDate: "",
    endDate: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
  };
  chartData: number[] = [];
  siteData: string[] = [];
  chart: any;
  selFromDate;
  minToDate: Date;
  selToDate;
  maxToDate: Date;
  maxFromDate: Date;
  @ViewChild("closemodal") closemodal;
  userProfile: any;
  clientID: number;
  weekDates: any[];
  roleID: any;
  areaID: any;
  dowloadURL: string = "";
  overDueSitesLength: any;
  sytemDate: Date;
  minEndDate: Date;
  noSiteData: boolean = false;
  openMenuFlag = false;
  @Output() moveToMyDashboard = new EventEmitter();
  @Input() pageFlag;

  constructor(
    private httpservice: HttpService,
    private _date: DatePipe,
    private pennservice: PennService,
    public WeekDatesService: WeekdatesService,
    public http: HttpClient,
    private translate: TranslateService
  ) {}

  allTasks: any;
  url: any;
  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleID = this.userProfile.roleID;
    this.areaID = this.userProfile.areaID;
    this.clientID = this.pennservice.getStoredData("clientID");
    this.weekDates = this.WeekDatesService.getDatesToSend();
    this.loadOverdueSitesData();
    this.maxFromDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.sytemDate = new Date();
    this.minEndDate = new Date(
      this.sytemDate.setMonth(this.sytemDate.getMonth() - 6)
    );
  }
  loadOverdueSitesData() {
    if (
      this.roleID == Roles.SuperAdmin ||
      this.roleID == Roles.ClientAdmin ||
      this.roleID == Roles.AdminReseller
    ) {
      this.url =
        RestApi.TaskManagementURL +
        "task-management/client-view/" +
        this.clientID +
        "/overdue-tasks/" +
        this._date.transform(this.weekDates[0], "yyyy-MM-dd") +
        "/" +
        this._date.transform(new Date(), "yyyy-MM-dd");
    } else if (this.roleID == Roles.AreaManager) {
      this.url =
        RestApi.TaskManagementURL +
        "task-management/client-view/" +
        this.clientID +
        "/overdue-tasks/" +
        this._date.transform(this.weekDates[0], "yyyy-MM-dd") +
        "/" +
        this._date.transform(new Date(), "yyyy-MM-dd") +
        "?regionId=" +
        this.areaID;
    }
    this.httpservice.get(this.url).subscribe(
      (data) => {
        this.noSiteData = false;
        this.allTasks = this.getAllTasks(data);
        if (this.allTasks.length == 0) {
          this.noSiteData = true;
        } else {
          this.chartData = this.allTasks.map((x) => Number(x.overDueTasks));
          this.siteData = this.allTasks.map((x) => x.siteName);
          this.CreateBarChart();
        }
      },
      (error) => {
        //Error callback
        console.log(error);
      }
    );
  }

  onFromSelect(date) {
    this.selFromDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
    this.minToDate = this.selFromDate;
    this.selToDate = undefined;
    this.maxToDate = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
  }
  onToSelect(date) {
    this.selToDate = new Date(this._date.transform(date, "yyyy-MM-dd"));
  }
  getAllTasks(data: any) {
    let allTasks = data.overDueSitesList.map((x) => x);
    return allTasks;
  }

  CreateBarChart() {
    let myOptions: any = {
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      xAxis: {
        scrollbar: {
          enabled: false,
        },
        categories: this.siteData,
      },
      yAxis: {
        title: {
          text: "",
        },
        allowDecimals: false,
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      series: [
        {
          enableMouseTracking: false,
          name: this.translate.data.TaskManagement.overdueTasks,
          color: "#D6D525",
          type: "column",
          data: this.chartData,
        },
      ],
    };

    setTimeout(() => {
      this.chart = Highcharts.chart("overduesites", myOptions);
    }, 600);
  }

  exportToExcel() {
    if (this.roleID == Roles.SuperAdmin || this.roleID == Roles.ClientAdmin) {
      this.dowloadURL =
        RestApi.TaskManagementURL +
        "client-excel/export/overdue-site?clientId=" +
        this.clientID +
        "&startDate=" +
        this._date.transform(this.selFromDate, "yyyy-MM-dd") +
        "&endDate=" +
        this._date.transform(this.selToDate, "yyyy-MM-dd");
    } else if (this.roleID == Roles.AreaManager) {
      this.dowloadURL =
        RestApi.TaskManagementURL +
        "client-excel/export/overdue-site?clientId=" +
        this.clientID +
        "&regionId=" +
        this.areaID +
        "&startDate=" +
        this._date.transform(this.selFromDate, "yyyy-MM-dd") +
        "&endDate=" +
        this._date.transform(this.selToDate, "yyyy-MM-dd");
    }
    this.http
      .get<Blob>(this.dowloadURL, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .subscribe(
        (data) => {
          var fileName = "TopOverDueTasksBySites.xlsx";
          FileSaver.saveAs(data.body, fileName);
          this.closemodal.nativeElement.click();
        },
        (err) => {
          console.log(err);
        }
      );
    this.selFromDate = "";
    this.selToDate = "";
  }

  toggleMenu() {
    this.openMenuFlag = !this.openMenuFlag;
  }

  movewidgetToMydashboard() {
    this.moveToMyDashboard.emit({
      "Overdue Task By Sites": { modulename: "taskManagementModule" },
    });
  }

  resetValue() {
    this.openMenuFlag = false;
  }
}
