import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, forkJoin } from "rxjs";

import { HttpService } from "../../common/services/http.service";
import { TranslateService } from "../../common/services/translate.service";
import { MessageService } from "primeng/api";
import { PennService } from "../../common/penn.service";
import {
  categoryType,
  sensorType,
  sensorsDataList,
} from "../../common/constants/constant";

import { RestApi } from "../../common/constants/RestAPI";
import {
  AddModule,
  CustomList,
  MapClientModule,
  Staff,
  ApiListResponse,
  GraphicData,
  ProcessedRowError,
  MapSitesToModule,
} from "../../common/models/configuration.model";
import { ToastMsg } from "../../common/constants/toastmsg.constant";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import {
  ClientConfigTab,
  SiteConfigTab,
} from "src/app/common/constants/enums/instructionEnums";
import { Constant } from "src/app/common/constants/constant";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { ExcelService } from "src/app/common/services/excel.service";
import { Table } from "primeng/table";
import { environment } from "src/environments/environment";
import { AddSite } from "src/app/common/models/client.model";

@Component({
  selector: "app-configuration-details",
  templateUrl: "./configuration-details.component.html",
  styleUrls: ["./configuration-details.component.scss"],
})
export class ConfigurationDetailsComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("assignSitescloseBtn", { static: true })
  assignSitescloseBtn: ElementRef;
  @ViewChild("assignSpecificSitescloseBtn", { static: true })
  assignSpecificSitescloseBtn: ElementRef;
  @ViewChild("addcustcloseBtn", { static: true }) addcustcloseBtn: ElementRef;
  @ViewChild("addmodulecloseBtn", { static: true })
  addmodulecloseBtn: ElementRef;
  @ViewChild("openCustListModal", { static: true })
  openCustListModal: ElementRef;
  @ViewChild("viewDeleteModal", { static: true }) viewDeleteModal: ElementRef;
  @ViewChild("staffaddcloseBtn", { static: true }) staffaddcloseBtn: ElementRef;
  @ViewChild("staffdeletecloseBtn", { static: true })
  staffdeletecloseBtn: ElementRef;
  @ViewChild("pinchangeaddcloseBtn", { static: true })
  pinchangeaddcloseBtn: ElementRef;
  @ViewChild("dataDeleteBtn", { static: true }) dataDeleteBtn: ElementRef;
  @ViewChild("addsitemodulecloseBtn", { static: true })
  addsitemodulecloseBtn: ElementRef;
  @ViewChild("unmapcloseBtn", { static: true }) unmapcloseBtn: ElementRef;
  @ViewChild("viewAddModuleModal", { static: true })
  viewAddModuleModal: ElementRef;
  @ViewChild("viewdeleteMapModuleModal", { static: true })
  viewdeleteMapModuleModal: ElementRef;
  @ViewChild("deletecustlistcloseBtn", { static: true })
  deletecustlistcloseBtn: ElementRef;
  @ViewChild("canceltemplateMapping", { static: true })
  canceltemplateMapping: ElementRef;
  @ViewChild("staffInputFileUpload", { static: false })
  staffInputFileUpload: ElementRef;
  @ViewChild("sensorTable", { static: false }) dataTable: Table;
  @ViewChild("viewAssignSitesModal", { static: true })
  viewAssignSitesModal: ElementRef;
  @ViewChild("viewSpecificSitesModal", { static: true })
  viewSpecificSitesModal: ElementRef;

  iconDivFlag: boolean = false;
  Title: String;
  moduleName: string;
  moduleDescription: string;
  moduleLogo: string;
  display: boolean = false;
  types = [];
  selectedType = { id: 0, name: "Select Type" };
  dataname: string;
  custDataName: string;
  custList = [];
  dataPopupTitle: string;
  routeUrl;
  newworkflowList = [];
  addModule: AddModule;
  ModuleID: number;
  ClientModuleID;
  clientName: string;
  clientID: number;
  siteName: string;
  siteID: number;
  mappedClientModule;
  selectedMapModule;
  staffList;
  stafflistcols;
  Username = [];
  Email = [];
  userSelect;
  modalTitle: string;
  staff: Staff;
  customList: CustomList;
  mapClientModule: MapClientModule;
  createdDate: string;
  staffID: number;
  staffName: string;
  staffImportErrors: ProcessedRowError[] = [];
  showStaffImportErrors: boolean;
  customListID: number;
  custDataList = [];
  allClientModuleList = [];
  siteModuleList = [];
  moduleSitesList = [];
  mapModuleID;
  mapModuleName;
  totalCount;
  deafultList;
  showModule;
  isAdd: boolean = true;
  userProfile;
  addModuleAccess: boolean = true;
  changePinFlag: boolean = false;
  custListData;
  custListIndex;
  oldaddModuleObj: AddModule;
  reasonCount;
  corrActionCount;
  pwdVisibility: Boolean = false;
  pwdTitle: string = "show password";
  SingleReminderCount: number = 0;
  RecurringReminder: number = 0;
  clientRoute: string;
  siteRoute: string;
  moduleIndex: number = 0;
  moduleTemplateID: string = "";
  popupType;
  changePinToolTipTitle = "";
  selectLabel = "";
  sensorTypeList = sensorType;
  selectedSensor;
  categoryTypeList = categoryType;
  selectedCategory;
  defaultSensorFlag = false;
  filteredSensorList = [];
  showTemplateTab = true;
  enabledLabel = this.translate.data.Refrigeration.enabled;
  selectedModuleName: string;
  allOrSpecificMessage: string;
  sites = [];
  allSites = [];
  assignMultipleSitesToModule: MapSitesToModule;
  selectedModule;
  isInsertSpecificSites;
  selectedSitesModule;
  numberedListMenu = "Numbered List";

  workflowList = [
    { icon: "../../assets/images/supplier.jpg", name: "Delivery" },
    { icon: "../../assets/images/supplier.jpg", name: "Cooking" },
    { icon: "../../assets/images/supplier.jpg", name: "Cold Checks" },
    { icon: "../../assets/images/supplier.jpg", name: "Hot Checks" },
    { icon: "../../assets/images/supplier.jpg", name: "Traceability" },
    { icon: "../../assets/images/supplier.jpg", name: "Cooling" },
    { icon: "../../assets/images/supplier.jpg", name: "Calibration" },
    { icon: "../../assets/images/supplier.jpg", name: "Refrigeration" },
  ];

  templateList = [
    { icon: "../../assets/images/supplier.jpg", name: "Delivery" },
    { icon: "../../assets/images/supplier.jpg", name: "Cooking" },
    { icon: "../../assets/images/supplier.jpg", name: "Cold Checks" },
    { icon: "../../assets/images/supplier.jpg", name: "Hot Checks" },
  ];

  iconList = [];

  sensorlistcols = [
    {
      field: sensorsDataList.templateName,
      header: this.translate.data.Common.templateName,
    },
    {
      field: sensorsDataList.sensorType,
      header: this.translate.data.Common.sensorType,
    },
    {
      field: sensorsDataList.category,
      header: this.translate.data.Common.category,
    },
    {
      field: sensorsDataList.escalation,
      header: this.translate.data.Common.escalation,
    },
  ];
  sensorList = [];
  noRecordMsg = "No Sensor Template Found";
  subinfoMsg;
  selectedrowSensorData;
  @ViewChild("closeBtn", { static: true }) closeBtn: ElementRef;

  constructor(
    public messageService: MessageService,
    public pennService: PennService,
    public httpService: HttpService,
    public excelService: ExcelService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _sanitizer: DomSanitizer,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.clientRoute = Constant.Routetype.client;
    this.siteRoute = Constant.Routetype.site;
    this.addModule = <AddModule>{};
    this.customList = <CustomList>{};
    this.staff = <Staff>{};
    this.mapClientModule = <MapClientModule>{};
    this.assignMultipleSitesToModule = <MapSitesToModule>{};
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );

    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.subinfoMsg = `${this.translate.data.Common.hi} ${this.userProfile.userName} ${this.translate.data.Common.addSensorTemplate}`;
    this.addModuleAccess =
      this.userProfile.roleName == Constant.Roles.areaManager ||
      this.userProfile.roleName == Constant.Roles.siteManager
        ? false
        : true;

    this.routeUrl = this.storage.get("routename");
    console.log("routeurl", this.routeUrl);
    //this.clientID = this.pennService.getStoredData("clientID");
    if (this.routeUrl === "client") {
      this.clientID = Number(this.route.snapshot.paramMap.get("id"));
      this.clientName = this.route.snapshot.paramMap.get("name");
    } else if (this.routeUrl === "site") {
      //this.clientID = this.pennService.getStoredData("clientID");
      this.clientID = Number(this.route.snapshot.paramMap.get("clientid"));
      this.siteID = Number(this.route.snapshot.paramMap.get("id"));
      this.siteName = this.route.snapshot.paramMap.get("name");
    }
    //this.clientName = this.pennService.getStoredData("clientName");

    this.changePinToolTipTitle = this.translate.data.Common.changePin;
    this.selectLabel = this.translate.data.moduleBuilder.select;
    this.Title = this.translate.data.Common.configuration;
    this.types = [
      { id: 0, name: "Select Type" },
      { id: 1, name: "Yes/No" },
      { id: 2, name: "Corrective Action" },
      { id: 3, name: "Normal" },
      { id: 4, name: "Email List" },
      { id: 5, name: "Numbered List" },
    ];
    this.storage.remove("editModule");

    //this.getClientSiteMappingCount();
    this.showModule = this.storage.get("showModule");
    if (this.storage.get("routename") == this.siteRoute) {
      this.pennService.showSubNav();
      this.pennService.showSiteSubNav();
      this.pennService.siteConfigActive = true;
      this.pennService.clientConfigActive = false;
    } else {
      this.pennService.showSubNav();
      this.pennService.hideSiteSubNav();
      this.pennService.siteConfigActive = false;
      this.pennService.clientConfigActive = true;
    }

    if (this.showModule) {
      this.getModules();
    }
    this.getSiteData();
    this.getAllSiteModule();
    this.checkProdEnvAndDisableSensorTemplate();

    this.moduleIndex = this.pennService.getModuleIndex();
    this.handleChange({ index: this.moduleIndex });
    this.pennService.hideNotificationPanel();
  }

  getSiteData() {
    this.httpService
      .get<any>(RestApi.client_site_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddSite>) => {
        let siteList = [];
        if (res.IsSuccess) {
          this.sites = res?.Data?.filter(function (x) {
            return x.Status !== "InActive";
          });
          this.allSites = res?.Data;
          this.sites = res?.Data?.filter(function (x) {
            return x.Status !== "InActive";
          })?.map((ele) => {
            return {
              siteID: ele.SiteID,
              siteName: ele.SiteName,
            };
          });
        }
      });
  }

  getSantizeUrl(url: any) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  countData(clientID, siteID): Observable<any[]> {
    let response;
    if (this.routeUrl == this.clientRoute) {
      response = this.httpService.get(
        RestApi.client_mapping_count + "/" + clientID
      );
      this.getAllCustomList();
    } else {
      response = this.httpService.get(
        RestApi.site_mapping_count + "/" + siteID
      );
    }
    return response;
  }

  getDefaultList(supplierCount, productCount, areaCount, deliveryTypeCount) {
    if (this.routeUrl == this.clientRoute) {
      this.deafultList = [
        {
          icon: "../../assets/images/supplier.jpg",
          name: "Suppliers",
          count: supplierCount,
          route:
            "/configuration/suppliers/" +
            `${this.route.snapshot.paramMap.get("id")}` +
            `/${this.route.snapshot.paramMap.get("name")}`,
        },
        {
          icon: "../../assets/images/supplier.jpg",
          name: "Products",
          count: productCount,
          route:
            "/configuration/products/" +
            `${this.route.snapshot.paramMap.get("id")}` +
            `/${this.route.snapshot.paramMap.get("name")}`,
        },
        {
          icon: "../../assets/images/supplier.jpg",
          name: "Regions",
          count: areaCount,
          route:
            "/configuration/areas/" +
            `${this.route.snapshot.paramMap.get("id")}` +
            `/${this.route.snapshot.paramMap.get("name")}`,
        },
        {
          icon: "../../assets/images/supplier.jpg",
          name: "Delivery types",
          count: deliveryTypeCount,
          route:
            "/configuration/delivery-types/" +
            `${this.route.snapshot.paramMap.get("id")}` +
            `/${this.route.snapshot.paramMap.get("name")}`,
        },
      ];
    } else {
      this.deafultList = [
        {
          icon: "../../assets/images/supplier.jpg",
          name: "Suppliers",
          count: supplierCount,
          route:
            "/configuration/suppliers/" +
            `${this.route.snapshot.paramMap.get("clientid")}` +
            `/${this.route.snapshot.paramMap.get("id")}` +
            `/${this.route.snapshot.paramMap.get("name")}`,
        },
        {
          icon: "../../assets/images/supplier.jpg",
          name: "Products",
          count: productCount,
          route:
            "/configuration/products/" +
            `${this.route.snapshot.paramMap.get("clientid")}` +
            `/${this.route.snapshot.paramMap.get("id")}` +
            `/${this.route.snapshot.paramMap.get("name")}`,
        },
      ];
    }
  }

  getClientSiteMappingCount() {
    this.countData(this.clientID, this.siteID).subscribe((res: any) => {
      let supplierCount, productCount, areaCount, deliveryTypeCount;
      if (res.Data) {
        this.totalCount = res.Data;
        this.totalCount.forEach((item) => {
          if (item.itemName == "Suppliers") {
            supplierCount = item.counts;
          } else if (item.itemName == "Products") {
            productCount = item.counts;
          } else if (item.itemName == "Areas") {
            areaCount = item.counts;
          } else if (item.itemName == "DeliveryTypes") {
            deliveryTypeCount = item.counts;
          }
        });
        supplierCount = supplierCount ? supplierCount : 0;
        productCount = productCount ? productCount : 0;
        areaCount = areaCount ? areaCount : 0;
        deliveryTypeCount = deliveryTypeCount ? deliveryTypeCount : 0;
        this.getDefaultList(
          supplierCount,
          productCount,
          areaCount,
          deliveryTypeCount
        );
      } else {
        this.getDefaultList(0, 0, 0, 0);
      }
    });
  }

  getAllClientModule() {
    this.httpService
      .get(RestApi.GetAllModuleTemplatesByClientID + "/" + this.clientID)
      .subscribe((res: any) => {
        this.mappedClientModule = res.Data;
      });
  }

  getAllSiteModule() {
    if (this.siteID) {
      this.httpService
        .get(RestApi.site_module_list + "/" + this.siteID)
        .subscribe((res: any) => {
          this.siteModuleList =
            res.IsSuccess && res.Data && res.Data.moduleList
              ? res.Data.moduleList
              : [];
        });
    }
  }

  showClientModules() {
    this.selectedMapModule = [];
    if (this.newworkflowList) {
      if (this.siteModuleList.length == 0) {
        this.allClientModuleList = this.newworkflowList;
      } else {
        this.allClientModuleList = this.newworkflowList.filter(
          (all) =>
            !this.siteModuleList.find((sel) => all.ModuleID === sel.moduleID)
        );
      }
    }
  }

  mapModuleToSite() {
    let mapModuleList = [];
    this.selectedMapModule.filter((ele: any) => {
      mapModuleList.push({
        moduleID: ele.ModuleID,
        moduleName: ele.ModuleName,
        logo: ele.Logo,
        enablePhotoFromGallery: ele.EnablePhotoFromGallery,
      });
    });

    this.mapClientModule.siteID = this.siteID;
    this.mapClientModule.isActive = true;
    this.mapClientModule.moduleList = mapModuleList;
    this.mapClientModule.CreatedOn = this.createdDate;
    this.mapClientModule.CreatedBy = this.userProfile.userName;
    this.mapClientModule.CreatedByID = this.userProfile.userID;
    this.mapClientModule.ModifiedOn = this.createdDate;
    this.mapClientModule.ModifiedBy = this.userProfile.userName;
    this.mapClientModule.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.map_client_module, this.mapClientModule)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.moduleaddedsuccessfully,
          });
          this.addsitemodulecloseBtn.nativeElement.click();
          this.getModules();
          this.getAllSiteModule();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  removeMappedModule(data, e) {
    e.stopPropagation();
    this.viewdeleteMapModuleModal.nativeElement.click();
    this.mapModuleID = data.moduleID;
    this.mapModuleName = data.moduleName;
  }

  deleteMappedModule() {
    let unmapobj = {
      siteID: this.siteID,
      moduleList: [
        { moduleID: this.mapModuleID, moduleName: this.mapModuleName },
      ],
      isActive: true,
      createdOn: this.createdDate,
      createdBy: this.userProfile.userName,
      createdByID: 0,
      modifiedOn: this.createdDate,
      modifiedBy: this.userProfile.userName,
      modifiedByID: 0,
    };
    this.httpService
      .post(RestApi.unmap_client_module, unmapobj)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.moduledeletedsuccessfully,
          });
          this.unmapcloseBtn.nativeElement.click();
          this.getAllSiteModule();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  getStaffs() {
    this.Username = [];
    this.httpService
      .get(RestApi.staff_list + "/" + this.siteID)
      .subscribe((res: ApiListResponse<Staff>) => {
        this.staffList = res.Data;

        this.stafflistcols = [
          { field: "staffName", header: this.translate.data.Common.userName },
        ];

        if (this.staffList) {
          this.staffList.forEach((res) => {
            this.Username.push({
              label: res.staffName,
              value: res.staffName,
            });
          });
        }
      });
  }

  showStaffModal(ModalOption: string, Modaldata?: Staff) {
    if (ModalOption == Constant.CommandType.add) {
      this.modalTitle = this.translate.data.Common.add;
      this.staffID = 0;
      this.staff = <Staff>{};
      this.changePinFlag = false;
    } else if (ModalOption == Constant.CommandType.edit) {
      this.modalTitle = this.translate.data.Common.edit;
      this.staff = Object.assign({}, Modaldata);
      this.staffID = Modaldata.staffID;
      this.staff.confirmPassword = Modaldata.pin;
      this.changePinFlag = false;
    } else if (ModalOption == Constant.CommandType.delete) {
      this.staffID = Modaldata.staffID;
      this.staffName = Modaldata.staffName;
    } else if (ModalOption == Constant.CommandType.updatePin) {
      this.staff = Modaldata;
      this.staffID = Modaldata.staffID;
      this.staff.pin = null;
      this.staff.confirmPassword = null;
      this.changePinFlag = true;
    }
  }

  checkStaffPin(staffModel: Staff) {
    if (staffModel.pin == null || staffModel.pin == "") return true;

    return staffModel.pin != staffModel.confirmPassword;
  }

  saveStaff() {
    this.staff.siteID = this.siteID;
    this.staff.staffID = this.staffID;
    this.staff.createdOn = this.createdDate;
    this.staff.createdBy = this.userProfile.userName;
    this.staff.createdByID = this.userProfile.userID;
    this.staff.modifiedOn = this.createdDate;
    this.staff.modifiedBy = this.userProfile.userName;
    this.staff.modifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.save_staff, this.staff)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          let msg =
            this.staffID > 0
              ? this.translate.data.Popuop_Msg.staffupdatedsuccessfully
              : this.translate.data.Popuop_Msg.staffaddedsuccessfully;
          if (this.changePinFlag) {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.pinchangedsuccessfully,
            });
            this.pinchangeaddcloseBtn.nativeElement.click();
          } else {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: msg,
            });
            this.staffaddcloseBtn.nativeElement.click();
          }
          this.getStaffs();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  deleteStaff() {
    let obj = {
      staffID: this.staffID,
    };
    this.httpService.post(RestApi.delete_staff, obj).subscribe((res: any) => {
      if (res.IsSuccess) {
        this.messageService.add({
          severity: ToastMsg.severity.success,
          summary: this.translate.data.Popuop_Msg.staffdeletedsuccessfully,
        });
        this.staffdeletecloseBtn.nativeElement.click();
        this.getStaffs();
      } else {
        this.messageService.add({
          severity: ToastMsg.severity.error,
          summary: res.ReturnMessage,
        });
      }
    });
  }

  importStaffExcel() {
    document.getElementById("staffInputFileUpload").click();
  }

  async onStaffImportFileChange(files: Blob[]) {
    return this.excelService
      .sendExcelFile(
        files[0],
        RestApi.import_staff,
        this.translate.data.Popuop_Msg.staffimportedsuccessfully,
        {
          SiteID: this.siteID,
        }
      )
      .then(() => {
        this.getStaffs();
      })
      .catch((result: ApiListResponse<ProcessedRowError> | Error) => {
        if (!(result instanceof Error)) {
          this.staffImportErrors = result.Data;
          this.showStaffImportErrors = true;
        }
      })
      .finally(() => {
        this.staffInputFileUpload.nativeElement.value = null;
      });
  }

  showDialog() {
    this.display = true;
  }

  addDataPopup(ModalOption: string, data?: CustomList) {
    if (ModalOption == Constant.CommandType.add) {
      this.dataPopupTitle = this.translate.data.Common.add;
      this.popupType = "Add";
      this.customList = <CustomList>{};
      this.custList = [];
      this.dataname = "";
      this.customListID = 0;
      this.selectedType = { id: 0, name: "Select Type" };
    } else {
      this.dataPopupTitle = this.translate.data.Common.edit;
      this.popupType = "Edit";
      this.customListID = data.CustomListID;
      this.customList = data;
      this.getCustomListByID(this.customListID);
      let selType = this.types.filter((item) => {
        return item.id == data.CustomListType;
      });
      setTimeout(() => {
        this.selectedType = selType[0];
      }, 0);
    }
  }

  getCustomListByID(id) {
    this.httpService
      .get(RestApi.custom_list + "/" + id)
      .subscribe((res: any) => {
        if (res.Data) {
          this.custList = res.Data.ItemDataList.sort((a, b) => {
            return a.itemID < b.itemID ? -1 : 1;
          });
        } else {
          this.custList = [];
        }
      });
  }

  refreshCustListData() {
    this.getAllCustomList();
  }

  getAllCustomList() {
    this.httpService
      .get(RestApi.all_custom_list + "/" + this.clientID)
      .subscribe((res: any) => {
        this.custDataList = res.Data;
      });
  }

  addCustListItems() {
    let index;
    let custobj = { itemID: 0, itemName: this.custDataName };

    if (this.selectedType.name === this.numberedListMenu) {
      if (this.custList.length > 4) {
        return;
      }
      if (this.custList.length > 0) {
        index = this.custList[this.custList.length - 1].itemName.substring(
          0,
          1
        );
      } else {
        index = 0;
      }
      this.custList.push({
        itemID: 0,
        itemName: `${++index}-${this.custDataName}`,
      });
    } else {
      this.custList.push(custobj);
    }
    this.custDataName = "";
  }

  getCustListItem(data, index) {
    this.custListData = data;
    this.custListIndex = index;
  }

  removeCustListItems() {
    let data = this.custListData;
    let index = this.custListIndex;
    if (data.itemID > 0) {
      this.customList.ItemDataList = data;
      let obj = {
        CustomListID: this.customListID,
        CustomListName: this.customList.CustomListName,
        CustomListType: this.customList.CustomListType,
        ClientID: this.clientID,
        ItemDataList: [{ itemID: data.itemID, itemName: data.itemName }],
        CreatedOn: this.createdDate,
        CreatedBy: this.userProfile.userName,
        CreatedByID: 0,
        ModifiedOn: this.createdDate,
        ModifiedBy: this.userProfile.userName,
        ModifiedByID: 0,
      };

      this.httpService
        .post(RestApi.delete_custom_item_list, obj)
        .subscribe((res: any) => {
          if (res.IsSuccess) {
            // this.messageService.add({ severity: 'success', summary: ToastMsg.data.delete });
            this.deletecustlistcloseBtn.nativeElement.click();
            this.custList.splice(index, 1);
            if (this.selectedType.name === this.numberedListMenu) {
              this.updateSequence();
              this.removeExistingCustomList();
            }
          }
        });
    } else {
      this.custList.splice(index, 1);
      if (this.selectedType.name === this.numberedListMenu) {
        this.updateSequence();
      }
    }
  }

  updateSequence() {
    let count = 0;
    this.custList.forEach((item) => {
      let val = item.itemName.indexOf("-") + 1;
      item.itemName = `${++count}-${item.itemName.substring(val)}`;
    });
  }

  removeExistingCustomList() {
    let allitems = [...this.custList];
    let obj = {
      CustomListID: this.customListID,
      CustomListName: this.customList.CustomListName,
      CustomListType: this.customList.CustomListType,
      ClientID: this.clientID,
      ItemDataList: allitems,
      CreatedOn: this.createdDate,
      CreatedBy: this.userProfile.userName,
      CreatedByID: 0,
      ModifiedOn: this.createdDate,
      ModifiedBy: this.userProfile.userName,
      ModifiedByID: 0,
    };

    this.httpService
      .post(RestApi.delete_custom_item_list, obj)
      .subscribe((res: any) => {
        this.updateExitingList();
      });
  }

  updateExitingList() {
    this.custList.forEach((item) => {
      item.itemID = 0;
    });
    this.saveCustomList();
  }

  saveCustomList() {
    this.customList.CustomListID = this.customListID;
    this.customList.ClientID = this.clientID;
    this.customList.CustomListType = this.selectedType.id;
    this.customList.CreatedOn = this.createdDate;
    this.customList.CreatedBy = this.userProfile.userName;
    this.customList.CreatedByID = this.userProfile.userID;
    this.customList.ModifiedOn = this.createdDate;
    this.customList.ModifiedBy = this.userProfile.userName;
    this.customList.ModifiedByID = this.userProfile.userID;

    if (this.customListID == 0) {
      this.customList.ItemDataList = this.custList;
    } else {
      if (this.custList) {
        let newItem = this.custList.filter((item) => {
          return item.itemID == 0;
        });
        this.customList.ItemDataList = newItem;
      } else {
        this.customList.ItemDataList = [];
      }
    }

    this.httpService
      .post(RestApi.save_customlist, this.customList)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          let msg =
            this.customListID > 0
              ? this.translate.data.Popuop_Msg.dataupdatedsuccessfully
              : this.translate.data.Popuop_Msg.dataaddedsuccessfully;
          this.messageService.add({ severity: "success", summary: msg });
          this.addcloseBtn.nativeElement.click();
          this.getAllCustomList();
        } else {
          this.messageService.add({
            severity: "error",
            summary: res.ReturnMessage,
          });
        }
      });
  }

  requestDataFromMultipleSources(obj1, obj2): Observable<any[]> {
    let response1 = this.httpService.post(
      RestApi.delete_custom_item_list,
      obj1
    );
    let response2 = this.httpService.post(RestApi.delete_customlist, obj2);
    return forkJoin([response1, response2]);
  }

  deleteCustomList() {
    let obj1 = {
      CustomListID: this.customListID,
      CustomListName: this.customList.CustomListName,
      CustomListType: this.customList.CustomListType,
      ClientID: this.clientID,
      ItemDataList: this.custList,
      CreatedOn: this.createdDate,
      CreatedBy: this.userProfile.userName,
      CreatedByID: 0,
      ModifiedOn: this.createdDate,
      ModifiedBy: this.userProfile.userName,
      ModifiedByID: 0,
    };

    let obj2 = {
      CustomListID: this.customListID,
      CustomListName: this.customList.CustomListName,
      ClientID: this.clientID,
      ClientName: this.clientName,
      CreatedOn: this.createdDate,
      CreatedBy: this.userProfile.userName,
      CreatedByID: 0,
      ModifiedOn: this.createdDate,
      ModifiedBy: this.userProfile.userName,
      ModifiedByID: 0,
    };

    this.requestDataFromMultipleSources(obj1, obj2).subscribe((res: any) => {
      if (res[0].IsSuccess && res[1].IsSuccess) {
        this.messageService.add({
          severity: "success",
          summary: this.translate.data.Popuop_Msg.datadeletedsuccessfully,
        });
        this.dataDeleteBtn.nativeElement.click();
        this.getAllCustomList();
      } else {
        this.messageService.add({
          severity: "error",
          summary: res.ReturnMessage,
        });
      }
    });
  }

  getLogoName(item) {
    this.moduleLogo = item.graphicData;
    this.addModule.logo = item.graphicData;
  }

  showSelectedLogo() {
    this.iconDivFlag = !this.iconDivFlag;
  }
  saveModule() {
    let ModuleObj = {
      moduleID: this.addModule.moduleID,
      moduleName: this.addModule.moduleName,
      description: !this.addModule.description
        ? ""
        : this.addModule.description,
      logo: this.addModule.logo,
      IsActive: this.addModule.isActive,
      instruction: null,
      ClientID: this.clientID,
      CreatedOn: this.createdDate,
      CreatedBy: this.userProfile.userName,
      CreatedByID: this.userProfile.userID,
      ModifiedOn: this.createdDate,
      ModifiedBy: this.userProfile.userName,
      ModifiedByID: this.userProfile.userID,
      EnablePhotoFromGallery: this.addModule.enablePhotoFromGallery,
    };
    if (this.isAdd == true) {
      this.httpService
        .post(RestApi.create_module, ModuleObj)
        .subscribe((res: any) => {
          if (res.IsSuccess) {
            let modId = res.Data;
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.moduleaddedsuccessfully,
            });
            this.addmodulecloseBtn.nativeElement.click();
            this.router.navigate([
              "/moduleBuilder",
              this.clientID,
              this.clientName,
              modId,
            ]);
          } else {
            this.messageService.add({
              severity: "error",
              summary: res.ReturnMessage,
            });
          }
        });
    } else {
      this.httpService
        .post(RestApi.update_module, ModuleObj)
        .subscribe((res: any) => {
          if (res.IsSuccess) {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.moduleupdatedsuccessfully,
            });
            this.getModules();
            this.addmodulecloseBtn.nativeElement.click();
          } else {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              summary: res.ReturnMessage,
            });
          }
        });
    }
  }
  getModules() {
    let url = RestApi.get_module_by_clientID + "/" + this.clientID;
    this.httpService
      .get<any>(url)
      .subscribe((res: ApiListResponse<AddModule>) => {
        if (res.IsSuccess) {
          this.newworkflowList = res.Data;
        }
      });
  }

  ValidateModule() {
    if (this.isAdd) return !this.addModule.moduleName || !this.addModule.logo;
    else
      return (
        !this.addModule.moduleName ||
        !this.addModule.logo ||
        (this.addModule.moduleName == this.oldaddModuleObj["ModuleName"] &&
          this.addModule.logo == this.oldaddModuleObj["Logo"] &&
          this.addModule.description == this.oldaddModuleObj["Description"] &&
          this.addModule.enablePhotoFromGallery ==
            this.oldaddModuleObj["EnablePhotoFromGallery"])
      );
  }
  getGraphics() {
    this.httpService
      .get<any>(RestApi.garphic_list)
      .subscribe((res: ApiListResponse<GraphicData>) => {
        if (res.IsSuccess && res.Data) {
          this.iconList = res.Data;
        }
      });
  }
  getModuleID(data, e) {
    e.stopPropagation();
    this.viewDeleteModal.nativeElement.click();
    this.ModuleID = data.ModuleID;
    this.ClientModuleID = data.ClientModuleID;
  }

  showModuleModal(val, data, event) {
    if (this.iconList.length == 0) {
      this.getGraphics();
    }

    if (val == Constant.CommandType.add) {
      this.isAdd = true;
      this.iconDivFlag = false;
      this.moduleName = "";
      this.moduleLogo = "";
      this.moduleDescription = "";
      this.addModule = <AddModule>{};
      this.addModule.enablePhotoFromGallery = true;
    } else if (val == Constant.CommandType.edit) {
      event.stopPropagation();
      this.isAdd = false;
      this.addModule = <AddModule>{};
      this.moduleLogo = "";
      this.oldaddModuleObj = Object.assign({}, data);
      this.viewAddModuleModal.nativeElement.click();
      this.addModule.moduleName = data.ModuleName;
      this.addModule.logo = data.Logo;
      this.addModule.isActive = data.IsActive;
      this.moduleLogo = data.Logo;
      this.addModule.description = data.Description;
      this.addModule.enablePhotoFromGallery = data.EnablePhotoFromGallery;
      this.addModule.moduleID = data.ModuleID;
    }
  }

  deleteModule() {
    let obj = {
      moduleID: this.ModuleID,
    };
    this.httpService.post(RestApi.delete_module, obj).subscribe((res: any) => {
      if (res.IsSuccess) {
        this.messageService.add({
          severity: "success",
          summary: this.translate.data.Popuop_Msg.moduledeletedsuccessfully,
        });
        this.addcloseBtn.nativeElement.click();
        this.getModules();
      } else {
        this.messageService.add({
          severity: "error",
          summary: res.ReturnMessage,
        });
      }
    });
  }
  editModule(data) {
    if (this.routeUrl == "client") {
      this.router.navigate([
        "/moduleBuilder",
        this.clientID,
        this.clientName,
        data.ModuleID,
      ]);
    } else {
      this.router.navigate([
        "/moduleBuilder",
        this.clientID,
        this.clientName,
        data.moduleID,
      ]);
    }
  }

  handleChange(data) {
    this.moduleIndex = data.index;
    this.pennService.setModuleIndex(this.moduleIndex);
    if (data.index == ClientConfigTab.Data) {
      this.getClientSiteMappingCount();
    }
    if (this.routeUrl == this.siteRoute) {
      if (data.index == SiteConfigTab.Module) {
        this.getModules();
        this.getAllSiteModule();
      } else if (data.index == SiteConfigTab.Staff) {
        this.getStaffs();
      } else if (data.index == SiteConfigTab.Scheduler) {
        this.getAllSchedulerCount();
      }
    } else if (this.routeUrl == this.clientRoute) {
      if (data.index == ClientConfigTab.Module) {
        this.getModules();
        this.getAllClientModule();
      } else if (data.index == ClientConfigTab.Refrigeration) {
        this.getRefigerationData();
      } else if (data.index == ClientConfigTab.Scheduler) {
        this.getAllClientSchedulerCount();
      } else if (data.index == ClientConfigTab.SensorTemplate) {
        this.getClientPreferenceID();
        this.getSensorTemplate();
      }
    }
  }

  togglePasswordVisibility() {
    this.pwdVisibility = this.pwdVisibility ? false : true;
    this.pwdTitle = this.pwdVisibility ? "hide password" : "show password";
  }
  ngOnDestroy() {
    this.pennService.hideSubNav();
    this.pennService.siteConfigActive = false;
    this.pennService.clientConfigActive = false;
  }

  getAllSchedulerCount() {
    this.httpService
      .get(RestApi.GetReminderCount + "/" + this.siteID)
      .subscribe(
        (res: ApiListResponse<SchedulerCount>) => {
          if (res.IsSuccess) {
            res.Data.forEach((item) => {
              if (item.ItemName == "Background") {
                this.SingleReminderCount = item.Counts;
              } else if (item.ItemName == "Reminder") {
                this.RecurringReminder = item.Counts;
              }
            });
          }
        },
        (error) => {}
      );
  }

  getAllClientSchedulerCount() {
    this.httpService
      .get(RestApi.GetReminderCountForClient + "/" + this.clientID)
      .subscribe(
        (res: ApiListResponse<SchedulerCount>) => {
          if (res.IsSuccess) {
            res.Data.forEach((item) => {
              if (item.ItemName == "Background") {
                this.SingleReminderCount = item.Counts;
              } else if (item.ItemName == "Reminder") {
                this.RecurringReminder = item.Counts;
              }
            });
          }
        },
        (error) => {}
      );
  }

  getSensorTemplate() {
    this.httpService
      .get<any>(RestApi.getCustomerTemplateDetails + "/" + this.clientID)
      .subscribe((res: any) => {
        this.sensorList = res.Data;
        this.filteredSensorList = res.Data;
      });
  }

  toggleSensorList(value) {
    const checked = value;
    if (checked) {
      this.filteredSensorList = this.sensorList.filter(
        (ele) => ele.IsCustomSensorTemplate === false
      );
    } else {
      this.filteredSensorList = [...this.sensorList];
    }
  }

  //Function To get refrigeration Data
  getRefigerationData(): void {
    this.httpService
      .get(RestApi.get_all_reasons + "/" + this.clientID)
      .subscribe(
        (res: any) => {
          this.reasonCount = res.IsSuccess && res.Data ? res.Data.length : 0;
        },
        (error) => {
          this.reasonCount = 0;
        }
      );

    this.httpService
      .get(RestApi.get_all_corr_actions + "/" + this.clientID)
      .subscribe(
        (res: any) => {
          this.corrActionCount =
            res.IsSuccess && res.Data ? res.Data.length : 0;
        },
        (error) => {
          this.corrActionCount = 0;
        }
      );
  }

  setSensorTemplate(data) {
    this.selectedrowSensorData = data;
  }

  public isEmailInvalid(): boolean {
    if (this.custDataName && this.selectedType && this.selectedType.id === 4) {
      const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

      return regexp.test(this.custDataName) === false;
    }

    return false;
  }

  isValueAlreadyExists(): boolean {
    if (this.selectedType.id === 5) {
      return this.custList.some((item) => {
        if (this.custDataName) {
          const itemName = item.itemName.substring(
            item.itemName.indexOf("-") + 1
          );
          return itemName.toLowerCase() === this.custDataName.toLowerCase();
        }
      });
    } else {
      return this.custList.some((item) => {
        if (this.custDataName) {
          return (
            item.itemName.toLowerCase() === this.custDataName.toLowerCase()
          );
        }
      });
    }
  }

  resetCustomList() {
    this.custList = [];
  }

  applyTemplate() {
    const applySensorPayload = { ...this.selectedrowSensorData };
    applySensorPayload["CreatedBy"] = this.userProfile.userName;
    applySensorPayload["CreatedByID"] = this.userProfile.userID;
    applySensorPayload["ModifiedOn"] = this.createdDate;
    applySensorPayload["ModifiedBy"] = this.userProfile.userName;
    applySensorPayload["ModifiedByID"] = this.userProfile.userID;

    this.httpService
      .post(RestApi.resetSensorTemplate, applySensorPayload)
      .subscribe(
        (res: any) => {
          this.closeBtn.nativeElement.click();
          if (!res.IsSuccess) {
            this.messageService.add({
              severity: ToastMsg.severity.error,
              summary: this.translate.data.Error_Msg.unableToApplyConfig,
            });
          }
        },
        (error) => {
          this.corrActionCount = 0;
        }
      );
  }

  getClientPreferenceID() {
    this.httpService
      .get<any>(RestApi.client_preference + "/" + this.clientID)
      .subscribe((res: any) => {
        if (res.Data) {
          this.pennService.saveDataToStorage(
            "clientPreferenceTempType",
            res.Data.TemperatureType
          );
        }
      });
  }

  showTemplate(ModuleTemplateID: string) {
    this.storage.set("AssignTemplate", "True");
    this.router.navigate(["/templatemodulebuilder", ModuleTemplateID]);
  }

  checkProdEnvAndDisableSensorTemplate() {
    if (environment.production) {
      this.showTemplateTab = false;
    }
  }

  deleteTemplate() {
    this.httpService
      .delete(
        RestApi.deleteSensorTemplate,
        this.selectedrowSensorData.CustomerSensorTemplateID
      )
      .subscribe((res: any) => {
        this.getSensorTemplate();
      });
  }

  mapSpecificSitesToModule() {
    this.allOrSpecificMessage = this.translate.data.Popuop_Msg.assignSiteToModuleConfirmationSpecific;
    this.isInsertSpecificSites = true;
    this.assignSpecificSitescloseBtn.nativeElement.click();
    this.viewAssignSitesModal.nativeElement.click();
  }

  mapSitesToModule() {
    let sitesList = [];
    if (this.isInsertSpecificSites) {
      this.selectedSitesModule.filter((ele: any) => {
        sitesList.push({
          SiteID: ele.siteID,
          SiteName: ele.siteName,
        });
      });
    } else {
      this.sites.filter((ele: any) => {
        sitesList.push({
          SiteID: ele.siteID,
          SiteName: ele.siteName,
        });
      });
    }

    this.assignMultipleSitesToModule.ModuleID = this.selectedModule.ModuleID;
    this.assignMultipleSitesToModule.ClientID = this.clientID;
    this.assignMultipleSitesToModule.ModuleName = this.selectedModule.ModuleName;
    this.assignMultipleSitesToModule.Logo = this.selectedModule.Logo;
    this.assignMultipleSitesToModule.EnablePhotoFromGallery = this.selectedModule.EnablePhotoFromGallery;
    this.assignMultipleSitesToModule.IsActive = true;
    this.assignMultipleSitesToModule.SiteList = sitesList;
    this.assignMultipleSitesToModule.CreatedOn = this.createdDate;
    this.assignMultipleSitesToModule.CreatedBy = this.userProfile.userName;
    this.assignMultipleSitesToModule.CreatedByID = this.userProfile.userID;
    this.assignMultipleSitesToModule.ModifiedOn = this.createdDate;
    this.assignMultipleSitesToModule.ModifiedBy = this.userProfile.userName;
    this.assignMultipleSitesToModule.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.map_sites_to_module, this.assignMultipleSitesToModule)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.moduleaddedsuccessfully,
          });
          this.getAllSitesByModuleId(this.assignMultipleSitesToModule.ModuleID);
          this.assignSitescloseBtn.nativeElement.click();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  assignSitesToModule(data, e, allOrSpecific) {
    e.stopPropagation();
    this.selectedModule = data;
    this.selectedModuleName = data.ModuleName;
    this.ModuleID = data.ModuleID;
    if (allOrSpecific == "All") {
      this.allOrSpecificMessage = this.translate.data.Popuop_Msg.assignSiteToModuleConfirmationAll;
      this.isInsertSpecificSites = false;
      this.viewAssignSitesModal.nativeElement.click();
    } else {
      this.getAllSitesByModuleId(this.ModuleID);
      this.allOrSpecificMessage = this.translate.data.Popuop_Msg.assignSiteToModuleConfirmationSpecific;
      this.isInsertSpecificSites = true;
      this.viewSpecificSitesModal.nativeElement.click();
    }
  }

  getAllSitesByModuleId(moduleId) {
    this.httpService
      .get(RestApi.get_all_sites_by_moduleId + "/" + moduleId)
      .subscribe((res: any) => {
        this.selectedSitesModule =
          res.IsSuccess && res.Data ? res.Data.siteList : [];
        this.selectedSitesModule = this.selectedSitesModule?.filter((item) => {
          return this.allSites?.some((item2) => {
            return item.siteID === item2.SiteID && item2.Status != "InActive";
          });
        });
      });
  }
}

class SchedulerCount {
  Counts: number;
  ItemName: string;
}
