<app-header [pageTitle]="title"></app-header>
<div class="wrapper">
  <div class="row">
    <div class="col-sm-12 breadcrumbSection">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb ml-1">
          <li class="breadcrumb-item">
            <a [routerLink]="['/site/', this.clientId, this.siteName]">
              {{ siteName }}
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "commissioning" | translate: "Commissioning" }}
          </li>
        </ol>
      </nav>
      <div class="flex flex-space_between aic flex_wrap header_position m-24">
        <div class="page-title">{{ siteName }}</div>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
      "
      class="col-sm-12"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
