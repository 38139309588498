import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { MessageService } from "primeng/api";
import { TranslateService } from "../common/services/translate.service";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "../common/services/http.service";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { PennService } from "../common/penn.service";
import { forkJoin, of } from "rxjs";

import { AddSite, SitePreference } from "../common/models/client.model";
import { RestApi } from "../common/constants/RestAPI";
import { DatePipe, Location } from "@angular/common";
import { Constant } from "../common/constants/constant";
import { Roles } from "../common/constants/enums/instructionEnums";
import { SessionVariable } from "../common/class/storageLabel";

@Component({
  selector: "app-add-site",
  templateUrl: "./add-site.component.html",
  styleUrls: ["./add-site.component.scss"],
})
export class AddSiteComponent implements OnInit {
  @ViewChild("inputFile", { static: true }) inputFile: ElementRef;
  Title: string;
  states = [];
  countries = [];
  stateList = [];
  cityList = [];
  selectedCountry;
  selectedState;
  selectedCity;
  addSite: AddSite;
  addSiteCopyObj: AddSite;
  sitePreference: SitePreference;
  createdDate: string;
  siteID: number;
  sitePrefFlag = "false";
  languages;
  selectedLang = { id: 1, name: "English" };
  selectedValue: string;
  timezone = [];
  siteName: string;
  preferenceID: number;
  clientID: number;
  clientName: string;
  premisesList;
  userProfile;
  sitePrefeditAccess;
  siteprefCopyObj: SitePreference;
  siteManager: boolean;
  timezoneList;
  selectedTimezone;

  constructor(
    private messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public pennService: PennService,
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _date: DatePipe,
    private _location: Location
  ) {}

  ngOnInit() {
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.addSite = <AddSite>{};
    this.sitePreference = <SitePreference>{};
    this.siteID = this.activeRoute.snapshot.params["siteId"];

    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );

    this.sitePrefeditAccess = true;
    this.siteManager =
      this.userProfile.roleID == Roles.SiteManager ||
      this.userProfile.roleID == Roles.AreaManager
        ? true
        : false;
    this.sitePrefFlag = this.storage.get("sitePreferenceFlag");
    this.siteName =
      this.siteID != 0
        ? this.pennService.getStoredData("siteName")
        : Constant.Routetype.site;
    this.clientID = this.activeRoute.snapshot.params["clientid"];
    this.clientName = this.pennService.getStoredData("clientName");
    if (this.sitePrefFlag == "true" && this.siteID > 0) {
      this.Title = this.siteName;
      forkJoin({
        timezone: this.httpService.get<any>("../assets/json/timezones.json"),
        sitePrefrence: this.httpService.get<any>(
          RestApi.site_preference + "/" + this.siteID
        ),
      }).subscribe((res: any) => {
        this.siteprefCopyObj = res.sitePrefrence.Data;
        this.timezoneList = res.timezone;
        this.preferenceID = res.sitePrefrence.Data.PreferenceID;
        this.sitePreference.TemperatureType =
          res.sitePrefrence.Data.TemperatureType;
        this.sitePreference.IsManualTemperature =
          res.sitePrefrence.Data.IsManualTemperature;
        this.filterTimezoneList(this.siteprefCopyObj.TimeZone);
      });
    } else if (this.sitePrefFlag == "false" && this.siteID > 0) {
      this.getSiteByID();
      this.Title = this.translate.data.Site.editSite;
    } else {
      this.setDefaultObject();
      this.Title = this.translate.data.Site.addSite;
    }

    if (this.sitePrefFlag == "false") {
      this.getData();
    }

    this.languages = [{ id: 1, name: "English" }];
    this.pennService.showSubNav();
    this.pennService.showSiteSubNav();
  }

  setDefaultObject(): void {
    this.addSite.IsSignedOffEnabled = true;
    this.addSite.SignedOffDaysLimit = 2;
  }

  getData() {
    this.httpService
      .get<any>("../assets/json/countries.json")
      .subscribe((res) => {
        this.countries = res.countries;

        this.httpService
          .get<any>("../assets/json/states.json")
          .subscribe((res) => {
            this.stateList = res.states;
            this.states = this.stateList.filter((ele) => {
              return ele.country_id == this.countries[0].id;
            });
          });
      });
  }

  onSelectCountry(country) {
    this.addSite.Country = country.name;
    this.states = [];
    this.states = this.stateList.filter((item) => {
      return item.country_id == country.id;
    });

    if (this.states.length > 0) {
      this.addSite.State = this.states[0].name;
    } else {
      this.addSite.State = "";
    }
  }

  onSelectState(state) {
    this.addSite.State = state.name;
    this.addSite.City = "";
  }

  onSelectedCity(city) {
    if (city) {
      this.addSite.City = city.name;
    }
  }

  getSelectedCountryStateCity(country, state) {
    let countryID = this.countries.filter((ele) => {
      return ele.name == country;
    });

    this.states = this.stateList.filter((item) => {
      return item.country_id == countryID[0].id && item.name == state;
    });

    setTimeout(() => {
      this.selectedCountry = countryID[0];
      this.selectedState = this.states[0];
      //this.selectedCity = cityID[0];
    }, 0);
  }

  getSiteByID() {
    this.httpService
      .get<any>(RestApi.site_details + "/" + this.siteID)
      .subscribe((res: any) => {
        this.addSite = res.Data;
        this.addSiteCopyObj = Object.assign({}, res.Data);
        setTimeout(() => {
          this.getSelectedCountryStateCity(res.Data.Country, res.Data.State);
        }, 500);
      });
  }

  getSitePreferenceID() {
    this.httpService
      .get<any>(RestApi.site_preference + "/" + this.siteID)
      .subscribe(
        (res: any) => {
          this.siteprefCopyObj = res.Data;
          this.siteprefCopyObj.TemperatureType = res.Data.TemperatureType;
          this.preferenceID = res.Data.PreferenceID;
          this.sitePreference.TemperatureType = res.Data.TemperatureType;
          this.sitePreference.IsManualTemperature =
            res.Data.IsManualTemperature;
          this.filterTimezoneList(this.siteprefCopyObj.TimeZone);
        },
        (e) => this.filterTimezoneList(null)
      );
  }

  saveSite() {
    this.addSite.ClientID = this.clientID;
    this.addSite.ClientName = this.clientName.trim();
    this.addSite.SiteID = this.siteID > 0 ? this.siteID : 0;
    this.addSite.CreatedOn = this.createdDate;
    this.addSite.CreatedBy = this.userProfile.userName;
    this.addSite.CreatedByID = this.userProfile.userID;
    this.addSite.ModifiedOn = this.createdDate;
    this.addSite.ModifiedBy = this.userProfile.userName;
    this.addSite.ModifiedByID = this.userProfile.userID;
    this.addSite.City = this.addSite.City.trim();

    this.httpService
      .post(RestApi.save_site, this.addSite)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          let msg =
            this.siteID > 0
              ? this.translate.data.Popuop_Msg.siteupdatedsuccessfully
              : this.translate.data.Popuop_Msg.siteaddedsuccessfully;
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: msg,
          });
          setTimeout(() => {
            this.router.navigate(["/site", this.clientID, this.siteName]);
          }, 600);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ErrorMessage,
          });
        }
      });
  }

  saveSitePreference() {
    this.sitePreference.CreatedOn = this.createdDate;
    this.sitePreference.CreatedBy = this.userProfile.userName;
    this.sitePreference.CreatedByID = this.userProfile.userID;
    this.sitePreference.ModifiedOn = this.createdDate;
    this.sitePreference.ModifiedBy = this.userProfile.userName;
    this.sitePreference.ModifiedByID = this.userProfile.userID;

    this.sitePreference.SiteID = Number(this.siteID);
    this.sitePreference.PreferenceID = this.preferenceID;
    this.sitePreference.Language = "";
    this.sitePreference.TimeZone = this.selectedTimezone.Id;

    this.httpService
      .post(RestApi.save_site_preference, this.sitePreference)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.siteupdatedsuccessfully,
          });
          setTimeout(() => {
            this.router.navigate(["/site", this.clientID, this.siteName]);
          }, 600);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  ValidateSite() {
    return (
      !this.addSite.SiteName ||
      !this.addSite.Country ||
      !this.addSite.State ||
      !this.addSite.City ||
      !this.addSite.Address
    );
  }

  ValidateSitePref() {
    if (
      this.sitePrefFlag == "true" &&
      this.sitePrefeditAccess &&
      this.siteprefCopyObj != undefined
    )
      //return (this.siteprefCopyObj.TimeZone == this.selectedTimezone.name && this.siteprefCopyObj.Language == this.selectedLang.name && this.siteprefCopyObj.TemperatureType == this.sitePreference.TemperatureType && this.siteprefCopyObj.IsManualTemperature == this.sitePreference.IsManualTemperature)
      return (
        this.siteprefCopyObj.TemperatureType ==
          this.sitePreference.TemperatureType &&
        this.siteprefCopyObj.IsManualTemperature ==
          this.sitePreference.IsManualTemperature &&
        this.siteprefCopyObj.TimeZone == this.selectedTimezone
      );
    else return true;
  }

  inputValidator(event: any) {
    const pattern = /^[a-zA-Z0-9' .-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
    }
  }

  getTimeZones() {
    this.httpService
      .get<any>("../assets/json/timezones.json")
      .subscribe((res) => {
        this.timezoneList = res;
      }),
      () => {};
  }

  filterTimezoneList(data) {
    let userSelectedtimezoneValue;
    if (this.timezoneList && this.timezoneList.length > 0) {
      if (!data) {
        let date = new Date();
        let temp = date.toString().split("(")[1];
        data = temp.substring(0, temp.length - 1);
      }

      userSelectedtimezoneValue = this.timezoneList.filter((ele) => {
        return data === ele.Id;
      });

      setTimeout(() => {
        if (!userSelectedtimezoneValue.length) {
          this.selectedTimezone = {
            Id: "UTC",
            DisplayName: "(UTC) Coordinated Universal Time",
            StandardName: "Coordinated Universal Time",
            DaylightName: "Coordinated Universal Time",
            BaseUtcOffset: "00:00:00",
            AdjustmentRules: null,
            SupportsDaylightSavingTime: false,
          };
        } else {
          this.selectedTimezone = userSelectedtimezoneValue[0];
        }
      }, 0);
    }
  }

  redirectBack() {
    this._location.back();
  }

  ngOnDestroy() {
    this.pennService.hideSubNav();
    this.pennService.hideSiteSubNav();
    //this.storage.set("sitePreferenceFlag", "false");
  }
}
