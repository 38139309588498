<div class="wrapper">
  <div class="config maincontainer">
    <div class="row">
      <div class="col-sm-12 breadcrumbSection">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngIf="routeUrl == 'client'">
              <a routerLink="/client">{{ clientName }}</a>
            </li>
            <li class="breadcrumb-item" *ngIf="routeUrl == 'site'">
              <a [routerLink]="['/site/', this.clientID, this.siteName]">
                {{ siteName }}
              </a>
            </li>
            <li *ngIf="routeUrl == 'client'" class="breadcrumb-item">
              <a
                [routerLink]="[
                  '/configuration/details',
                  this.clientID,
                  this.clientName
                ]"
              >
                {{ "configuration" | translate: "Common" }}
              </a>
            </li>
            <li *ngIf="routeUrl == 'site'" class="breadcrumb-item">
              <a
                [routerLink]="[
                  '/configuration/details',
                  this.clientID,
                  this.siteID,
                  this.siteName
                ]"
              >
                {{ "configuration" | translate: "Common" }}
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "products" | translate: "Product" }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div *ngIf="routeUrl == 'client'" class="row flex-width-full">
      <div class="col-md-12 flex flex-space_between flex_wrap m-24">
        <div>
          <span class="page-title">
            {{ "products" | translate: "Product" }}
          </span>
        </div>
        <div class="flex aic">
          <div class="ml-0">
            <a
              href="javascript:void(0)"
              class="btn btn-secondary btncust"
              (click)="importExcel()"
            >
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.00004 0L0.333374 2.66H2.33337V7.33333H3.66671V2.66H5.66671L3.00004 0ZM7.66671 9.34V4.66667H6.33337V9.34H4.33337L7.00004 12L9.66671 9.34H7.66671Z"
                  fill="#00539e"
                />
              </svg>
              {{ "importExcel" | translate: "Common" }}
            </a>
            <input
              type="file"
              id="inputFileUpload"
              #inputFileUpload
              class="customFileUpload mb-1"
              (change)="onFileChange($event.target.files)"
              accept=".xlsx"
              required
            />
          </div>
          <div class="ml-0">
            <a
              href="javascript:void(0)"
              class="btn btn-secondary btncust"
              (click)="downloadTemplate()"
            >
              <i class="fa fa-download" aria-hidden="true"></i>
              {{ "userTemplate" | translate: "User" }}
            </a>
          </div>
          <div>
            <a
              href=""
              class="btn btn-primary btncust"
              data-toggle="modal"
              data-target="#productModal"
              (click)="showproductModal('Add', '')"
            >
              <span class="mr-2">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                    fill="white"
                  />
                </svg>
              </span>
              {{ "addProduct" | translate: "Product" }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="routeUrl == 'site'" class="row flex-width-full">
      <div class="col-md-12 flex flex-space_between flex_wrap m-24">
        <div>
          <span class="page-title">
            {{ "products" | translate: "Product" }}
          </span>
        </div>
        <div class="flex aic">
          <div class="ml-0">
            <button
              class="btn btn-secondary btncust"
              [disabled]="!selProducts || selProducts.length == 0"
              data-toggle="modal"
              data-target="#unMappedProductModal"
            >
              {{ "delete" | translate: "Common" }}
            </button>
          </div>
          <div>
            <button
              class="btn btn-primary btncust"
              data-toggle="modal"
              data-target="#mapProductsModal"
              (click)="showMapProductModal()"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                  fill="white"
                />
              </svg>
              {{ "addProducts" | translate: "Product" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin: 0px">
      <div class="col-md-12 filter_section">
        <div class="row aic">
          <div class="col-md-1 col-lg-1 col-xl-1 col-sm-12 col-xs-12">
            <div class="text-uppercase font-weight700 font-size12">
              {{ "filterBy" | translate: "Common" }}
            </div>
          </div>
          <div
            class="col-md-4 col-lg-4 col-xl-4 col-xs-12 col-sm-12 tblsearch listsearch"
          >
            <div class="filtertitle">
              {{ "search" | translate: "Common" }}
            </div>
            <input
              type="text"
              pInputText
              size="50"
              (input)="dataTable.filterGlobal($event.target.value, 'contains')"
              placeholder="{{
                'SearchAnythingPlaceholder' | translate: 'Site'
              }}"
            />
          </div>
          <div
            *ngIf="routeUrl == 'client'"
            class="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12"
          >
            <div class="filtertitle">{{ "status" | translate: "Common" }}</div>
            <div>
              <p-dropdown
                class="filterDropdown"
                [options]="statusList"
                appendTo="body"
                [(ngModel)]="selectedStatus"
                optionLabel="name"
                (onChange)="filterStatus()"
              >
                <ng-template pTemplate="item" let-item>
                  {{ item?.name | translate: "Site" }}
                </ng-template>
                <ng-template pTemplate="selectedItem" let-item>
                  {{ item?.name | translate: "Site" }}
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div
            *ngIf="routeUrl == 'client'"
            class="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12"
          >
            <div class="filtertitle pr-2">
              {{ "productName" | translate: "Product" }}
            </div>
            <p-multiSelect
              [options]="ProductName"
              appendTo="body"
              [(ngModel)]="defaultselect"
              [defaultLabel]="selectLabel"
              (onChange)="dataTable.filter($event.value, 'ProductName', 'in')"
            ></p-multiSelect>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="row mt10">
          <div class="col-sm-12">
            <div class="content product_section">
              <div class="content-body configTable">
                <div *ngIf="routeUrl == 'site'">
                  <p-table
                    #producttable
                    [style]="{ overflow: 'auto!important' }"
                    [columns]="allproductlistcols"
                    [value]="siteProductList"
                    [responsive]="true"
                    [rows]="10"
                    [paginator]="true"
                    [rowsPerPageOptions]="[10, 15, 20, 25]"
                    [(selection)]="selProducts"
                  >
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th class="text-center" width="40px">
                          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th
                          *ngFor="let col of columns"
                          [pSortableColumn]="col.field"
                          pResizableColumn
                        >
                          {{ col.header }}
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-item
                      let-i="rowIndex"
                      let-columns="columns"
                    >
                      <tr>
                        <td class="actionBtn text-center">
                          <div>
                            <p-tableCheckbox
                              [value]="rowData"
                            ></p-tableCheckbox>
                          </div>
                        </td>
                        <td>{{ item.productName }}</td>
                      </tr>
                    </ng-template>
                    <!-- since teble is half coded in Template and half in TS  REWORK IS NECESSARY-->
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td [attr.colspan]="9">
                          <span class="noDataText">
                            {{ "noRecord" | translate: "Common" }}
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>

                <div *ngIf="routeUrl == 'client'">
                  <p-table
                    #producttable
                    [style]="{ overflow: 'auto!important' }"
                    [columns]="productlistcols"
                    [value]="productList"
                    [responsive]="true"
                    [rows]="10"
                    [paginator]="true"
                    [rowsPerPageOptions]="[10, 15, 20, 25]"
                  >
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th width="80px">
                          {{ "action" | translate: "Common" }}
                        </th>
                        <th width="80px">
                          {{ "status" | translate: "Common" }}
                        </th>
                        <th
                          *ngFor="let col of columns"
                          [pSortableColumn]="col.field"
                          pResizableColumn
                          width="80px"
                        >
                          {{ col.header }}
                          <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                        <th width="100px">
                          {{ "supplier" | translate: "Supplier" }}
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-item
                      let-i="rowIndex"
                      let-columns="columns"
                    >
                      <tr>
                        <td class="actionBtn text-left">
                          <span
                            class="mr-2 cp"
                            title="{{ 'edit' | translate: 'Common' }}"
                            data-toggle="modal"
                            data-target="#productModal"
                            (click)="showproductModal('Edit', item)"
                          >
                            <svg
                              width="20"
                              height="18"
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                                fill="#00539E"
                              />
                            </svg>
                          </span>
                          <span
                            class="mr-2 cp"
                            data-toggle="modal"
                            data-target="#deleteProductModal"
                            title="{{ 'delete' | translate: 'Common' }}"
                            (click)="showproductModal('Delete', item)"
                          >
                            <svg
                              width="14"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                                fill="#00539E"
                              />
                            </svg>
                          </span>
                        </td>
                        <td>
                          <span
                            class="configActiveFlag"
                            title="{{ 'clickToDisable' | translate: 'Common' }}"
                            *ngIf="item.Status == 'Active'"
                            (click)="changeStatus(item)"
                            data-toggle="modal"
                            data-target="#productStatusModal"
                          >
                            {{ item.Status | translate: "Site" }}
                          </span>
                          <span
                            class="configInactiveFlag"
                            title="{{ 'clickToEnable' | translate: 'Common' }}"
                            *ngIf="item.Status != 'Active'"
                            (click)="changeStatus(item)"
                            data-toggle="modal"
                            data-target="#productStatusModal"
                          >
                            {{ item.Status | translate: "Site" }}
                          </span>
                        </td>
                        <td>{{ item.ProductName | delimit: "50" }}</td>
                        <td>{{ item.Description | delimit: "100" }}</td>
                        <td>{{ item.OpenedDays }}</td>
                        <td>{{ item.FrozenDays }}</td>
                        <td>{{ item.DefrostedDays }}</td>
                        <td>
                          <button
                            class="btn btn-primary smallbtn smallbtn_left-width"
                            data-toggle="modal"
                            data-target="#mapSuplierModal"
                            (click)="showMapSupplierModal(item)"
                            [title]="translateAssignSupplierBtnTooltip"
                          >
                            {{ "assingSupplier" | translate: "Product" }}
                          </button>
                          <button
                            class="btn btn-info smallbtn"
                            data-toggle="modal"
                            data-target="#viewMappedSupplierModal"
                            (click)="viewMappedSupplierModal(item)"
                          >
                            {{ "view" | translate: "Site" }}
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                    <!-- since teble is half coded in Template and half in TS  REWORK IS NECESSARY-->
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td [attr.colspan]="9">
                          <span class="noDataText">
                            {{ "noRecord" | translate: "Common" }}
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add, Update & Delete Modal -->
<div
  class="modal fade"
  id="productModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ modalTitle }} {{ "product" | translate: "Product" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #productForm="ngForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="{{ 'productName' | translate: 'Product' }}">
                  {{ "productName" | translate: "Product" }}
                  <span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="product"
                  [(ngModel)]="clientProduct.ProductName"
                  maxlength="200"
                  autocomplete="off"
                  placeholder="{{ 'placeholderProduct' | translate: 'Common' }}"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="{{ 'description' | translate: 'Common' }}">
                  {{ "description" | translate: "Common" }}
                </label>
                <textarea
                  class="form-control txtarea"
                  cols="10"
                  rows="4"
                  name="description"
                  [(ngModel)]="clientProduct.Description"
                  maxlength="200"
                  autocomplete="off"
                  placeholder="{{
                    'placeholderProductDescription' | translate: 'Common'
                  }}"
                ></textarea>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="{{ 'chilledDays' | translate: 'Common' }}">
                  {{ "chilledDays" | translate: "Common" }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="chilleddays"
                  [(ngModel)]="clientProduct.OpenedDays"
                  autocomplete="off"
                  appDigit
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="{{ 'frozenDays' | translate: 'Common' }}">
                  {{ "frozenDays" | translate: "Common" }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="frozendays"
                  [(ngModel)]="clientProduct.FrozenDays"
                  autocomplete="off"
                  appDigit
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="{{ 'defrostedDays' | translate: 'Common' }}">
                  {{ "defrostedDays" | translate: "Common" }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="defrosteddays"
                  [(ngModel)]="clientProduct.DefrostedDays"
                  autocomplete="off"
                  appDigit
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #addcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="Validate()"
          (click)="saveProduct()"
        >
          <span *ngIf="productID == 0">{{ "add" | translate: "Common" }}</span>
          <span *ngIf="productID > 0">
            {{ "update" | translate: "Common" }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteProductModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "delete" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteProduct" | translate: "Popuop_Msg" }} {{ productName }}?
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #deletecloseBtn
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteProduct()">
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="mapProductsModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "addProducts" | translate: "Product" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mapSupplier">
        <div class="row">
          <div class="col-md-12">
            <div
              class="nodatamsg"
              *ngIf="allProductList && allProductList?.length == 0"
            >
              {{ "nodata" | translate: "Common" }}
            </div>
            <div *ngIf="allProductList?.length > 0">
              <p-listbox
                class="custom-listbox"
                [options]="allProductList"
                [(ngModel)]="selectedProducts"
                multiple="multiple"
                checkbox="checkbox"
                filter="filter"
                optionLabel="ProductName"
              ></p-listbox>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #paddcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!selectedProducts || selectedProducts.length == 0"
          (click)="mapSelProducts()"
        >
          <span>{{ "add" | translate: "Common" }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="unMappedProductModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "delete" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteMappedProduct" | translate: "Popuop_Msg" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #pdeletecloseBtn
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteMappedProducts()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="mapSuplierModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "assingSupplierto" | translate: "Product" }} {{ productName }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mapSupplier">
        <div class="row">
          <div class="col-md-12">
            <div
              class="nodatamsg"
              *ngIf="allSuppliersList && allSuppliersList?.length == 0"
            >
              {{ "nodata" | translate: "Common" }}
            </div>
            <div *ngIf="allSuppliersList?.length > 0">
              <p-listbox
                class="custom-listbox"
                [options]="allSuppliersList"
                [(ngModel)]="selectedSupplier"
                multiple="multiple"
                checkbox="checkbox"
                filter="filter"
                optionLabel="SupplierName"
              ></p-listbox>
              <!-- <p-header>All Products List</p-header> -->
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #mapSupplierModal
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!selectedSupplier || selectedSupplier.length == 0"
          (click)="mapSelSupplier()"
        >
          <span>{{ "add" | translate: "Common" }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="viewMappedSupplierModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ productName }} {{ "mappedSuppliers" | translate: "Supplier" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div
              class="nodatamsg"
              *ngIf="mappedSupplierList && mappedSupplierList.length == 0"
            >
              {{ "nodata" | translate: "Common" }}
            </div>
            <div *ngIf="mappedSupplierList && mappedSupplierList.length > 0">
              <p-listbox
                class="custom-listbox"
                [options]="mappedSupplierList"
                [(ngModel)]="selectedMappedSupplier"
                multiple="multiple"
                checkbox="checkbox"
                filter="filter"
                optionLabel="supplierName"
              ></p-listbox>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #viewmapSupplierModal
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          *ngIf="mappedSupplierList && mappedSupplierList.length != 0"
          [disabled]="
            !selectedMappedSupplier || selectedMappedSupplier.length == 0
          "
          (click)="unmapSelSupplier()"
        >
          <span>{{ "delete" | translate: "Common" }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="productStatusModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "product" | translate: "Product" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "statusChangeMsg" | translate: "Popuop_Msg" }}
          <span style="text-transform: lowercase">
            {{ statusText | translate: "Common" }}
          </span>
          {{ "thisproduct" | translate: "Common" }} ?
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          data-dismiss="modal"
          #statusChangecloseBtn
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="changeProductStatus()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add, Update & Delete Modal -->

<p-toast position="bottom-left"></p-toast>
