import { Component, Inject, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { RestApi } from "../common/constants/RestAPI";
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../common/services/authentication.service";

@Component({
  selector: "app-download",
  templateUrl: "download-report.component.html",
  styleUrls: ["download-report.component.scss"],
})
export class DownloadReportComponent implements OnInit {
  platformname;
  pname;
  constructor(
    private route: ActivatedRoute,
    public auth: AuthenticationService,
    private _http: HttpClient
  ) {}

  ngOnInit() {
    let fileName;
    fileName = this.route.snapshot.params["fName"];

    // if (fileName) {
    //   const token = this.auth.getToken();
    //   let tokenValid;
    //   if (token) {
    //     const tokens: any = JSON.parse(atob(token.split(".")[1]));
    //     tokenValid = tokens.exp * 1000 > new Date().getTime();
    //   }
    //   if (tokenValid) {
    //     this.downloadReport(fileName);
    //   } else {
    //     localStorage.setItem("fName", fileName);
    //     const ssourl =
    //       environment.commonAuthUrl +
    //       "/v1/api/cookie" +
    //       "?userid=null&redirecturl=" +
    //       location.origin +
    //       "/auth-process&appId=penn";
    //     window.location.href = ssourl;
    //   }
    // }
    this.platformname = this.getPlatform();

    if (this.platformname === "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.sensormatic.compliancemanager&pcampaignid=web_share";
    } else if (this.platformname === "iPhone OS") {
      window.location.href =
        "https://apps.apple.com/gb/app/digital-food-safety/id1666452313";
    }
  }

  getPlatform() {
    var platform = ["Windows NT", "Android", "iPhone OS"];

    for (var i = 0; i < platform.length; i++) {
      if (navigator.userAgent.indexOf(platform[i]) > -1) {
        return platform[i];
      }
    }
  }

  downloadReport(reportName) {
    this._http
      .get(RestApi.DownloadTemperatureReport + `/${reportName}`, {
        responseType: "blob",
      })
      .subscribe((response: any) => {
        FileSaver.saveAs(response, `${reportName}`);
      });
  }
}
